/* =================slick css ends here================= */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.slick-slide img {
  display: block;
  width: 100%;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-loading .slick-list {
  /* background: #fff url("../images/ajax-loader.gif") center center no-repeat; */
}
.slick-prev,
.slick-next {
  z-index: 99;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  float: left;
  padding: 0;
  margin-top: -25px\9; /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  background: #26262a;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none; /*background: transparent;*/
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.3;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'font Awesome 5 Free';
  font-weight: 900;
  font-size: 21px;
  line-height: 39px;
  opacity: 0.9;
  color: #fff;
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
  border-radius: 50%;
  display: inline-block;
  padding: 0;
}
.slick-prev {
  left: 15px;
}
.slick-prev:before {
  content: '\f053';
}
.slick-next {
  right: 15px;
}
.slick-next:before {
  content: '\f054';
}
.slick-slider {
  margin-bottom: 0;
  padding-bottom: 45px;
}
.slick-dots {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  vertical-align: top;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 0px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  vertical-align: top;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  opacity: 1;
  border: solid 1px #000;
  border-radius: 50%;
  vertical-align: top;
  margin: 5px 6px;
}
.slick-dots li.slick-active button,
.slick-dots li:hover button {
  opacity: 1;
  background-color: #26262a;
}
.white-dots .slick-dots li button {
  border-color: #fff;
  background: #fff;
  opacity: 0.4;
}
.white-dots .slick-dots li.slick-active button,
.white-dots .slick-dots li:hover button {
  background-color: #fff;
  opacity: 1;
}

/* =================slick css end here=================  */

/* =================global css starts here=================  */
* {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
body,
html,
form,
fieldset {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
}
a {
  text-decoration: none;
  border: 0;
  outline: 0;
}
ul {
  list-style: none;
}
a:focus,
input:focus,
textarea:focus,
*:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
body {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  color: #26262a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  width: 100%;
  max-width: 1200px;
}
.container.container1 {
  max-width: 1670px;
  width: calc(100% - 250px);
}
.container-fluid {
  max-width: calc(100% - 90px);
}
a,
input,
button {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  outline: none;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}
a img {
  border: 0px none;
}
a:hover {
  outline: none;
  color: #621ac0;
  text-decoration: none;
}
a:active {
  outline: none;
  text-decoration: none;
}
a:focus {
  outline: none;
  outline-offset: 0px;
  text-decoration: none;
  color: inherit;
}
::-webkit-input-placeholder {
  color: #8798ad;
  opacity: 1;
}
::-moz-placeholder {
  color: #8798ad;
  opacity: 1;
}
:-moz-placeholder {
  color: #8798ad;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #8798ad;
  opacity: 1;
}
img {
  max-width: 100%;
  border: 0;
  height: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
  margin-bottom: 24px;
  line-height: 1.2;
  font-weight: 500;
  color: inherit;
}
p {
  margin: 0px;
  padding: 0px;
  margin-bottom: 24px;
}
strong {
  font-weight: 700;
}
b {
  font-weight: 400;
}
p:last-child {
  margin-bottom: 0;
}

/* =================smartmenus css start here================= */
.navbar-nav.sm-collapsible .sub-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  margin: -0.7em 0.5em 0 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 0;
  width: 2em;
  height: 1.4em;
  font-size: 1.25rem;
  line-height: 1.2em;
  text-align: center;
}
.navbar-nav.sm-collapsible .sub-arrow::before {
  content: '+';
}
.navbar-nav.sm-collapsible .show > a > .sub-arrow::before {
  content: '-';
}
.navbar-dark .navbar-nav.sm-collapsible .nav-link .sub-arrow {
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-nav.sm-collapsible .has-submenu {
  padding-right: 3em;
}
.navbar-nav.sm-collapsible .nav-link,
.navbar-nav.sm-collapsible .dropdown-item {
  position: relative;
}
.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}
.fixed-bottom .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow,
.fixed-bottom
  .navbar-nav:not(.sm-collapsible):not([data-sm-skip])
  .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}
.navbar-nav:not(.sm-collapsible) .dropdown-item .sub-arrow,
.navbar-nav:not(.sm-collapsible):not([data-sm-skip])
  .dropdown-menu
  .dropdown-toggle::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 0;
  height: 0;
  margin-top: -0.3em;
  margin-right: 1em;
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.navbar-nav:not(.sm-collapsible) .dropdown-item.has-submenu {
  padding-right: 2em;
}
.navbar-nav .scroll-up,
.navbar-nav .scroll-down {
  position: absolute;
  display: none;
  visibility: hidden;
  height: 20px;
  overflow: hidden;
  text-align: center;
}
.navbar-nav .scroll-up-arrow,
.navbar-nav .scroll-down-arrow {
  position: absolute;
  top: -2px;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  overflow: hidden;
  border-top: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid;
  border-left: 7px solid transparent;
}
.navbar-nav .scroll-down-arrow {
  top: 6px;
  border-top: 7px solid;
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid transparent;
}
.navbar-nav.sm-collapsible .dropdown-menu .dropdown-menu {
  margin: 0.5em;
}
.navbar-nav:not([data-sm-skip]) .dropdown-item {
  white-space: normal;
}
.navbar-nav:not(.sm-collapsible) .sm-nowrap > li > .dropdown-item {
  white-space: nowrap;
}

/* =================smartmenus css ends here================= */
#header {
  color: #333;
  background: #f7f7f7;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 0;
  z-index: 9999;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in; /*display: flex;align-items: center;*/
}
.fixed #header {
  background: #f7f7f7;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
}
.header-lower .navbar {
  padding: 0;
  width: 100%;
  height: 100px;
}
.navbar-brand {
  width: 90px;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 999;
}
.navbar-expand-xl .navbar-nav .nav-item {
  margin: 0 15px;
}
.navbar-expand-xl .navbar-nav .nav-link {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #333;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}
.navbar-expand-xl .navbar-nav .nav-item:hover .nav-link,
.navbar-expand-xl .navbar-nav .nav-item.active .nav-link {
  color: #621bc0;
}
.dropdown-toggle::after {
  display: none;
}
.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  display: none;
}
.header-right {
  margin-left: 15px;
}
@media only screen and (min-width: 1200px) {
  .navbar-nav > li > .dropdown-menu {
    display: block !important;
    left: auto;
    right: 0;
    top: 100% !important;
    margin-top: 10px !important;
    opacity: 0;
    visibility: hidden;
    background: #f7f7f7;
    color: #333;
    min-width: 230px;
    padding: 25px 0;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    border: 2px solid #b2b2b2;
    border-radius: 10px 0 10px 10px;
    transition: all 0.1s ease-in;
    -moz-transition: all 0.1s ease-in;
    -webkit-transition: all 0.1s ease-in;
  }
  .navbar-nav > li > .dropdown-menu:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 20px solid #f7f7f7;
    position: absolute;
    top: -19px;
    right: 0px;
    filter: drop-shadow(0px -3px 0 #b2b2b2);
  }
  .dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0px !important;
    transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
  }
  .dropdown .dropdown-item {
    color: #333;
    padding: 12px 41px;
    font-weight: 400;
  }
  .dropdown .dropdown-item:focus,
  .dropdown .dropdown-item:hover,
  .dropdown .active .dropdown-item {
    color: #432a7f;
    font-weight: 700;
    background: transparent;
  }
}
.navbar-toggler {
  width: 45px;
  height: 45px;
  border-radius: 0;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  border: 0;
  background: transparent;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.navbar-default .navbar-toggler:hover,
.navbar-default .navbar-toggler:focus {
  background: transparent;
}
.navbar-toggler:not([class='collapsed']),
.navbar-toggler:not([class='collapsed']):focus,
.navbar-toggler:not([class='collapsed']):hover {
  background: transparent;
}
.navbar-toggler.collapsed,
.navbar-toggler.collapsed:focus,
.navbar-toggler.collapsed:hover {
  background: transparent;
}
.navbar-toggler .icon-bar {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 4px;
  color: #243238;
  text-indent: -55px;
  margin-top: 0;
  background: transparent !important;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  vertical-align: middle;
}
.navbar-toggler .icon-bar:before,
.navbar-toggler .icon-bar:after {
  content: '';
  width: 22px;
  height: 4px;
  background: #005983;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-out;
}
.navbar-toggler.collapsed .icon-bar {
  background: #005983 !important;
}
.navbar-toggler.collapsed .icon-bar:before,
.navbar-toggler.collapsed .icon-bar:after {
  background: #005983;
}
.navbar-toggler.collapsed .icon-bar:before {
  top: -8px;
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
}
.navbar-toggler .icon-bar:before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}
.navbar-toggler.collapsed .icon-bar:after {
  bottom: -8px;
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
}
.navbar-toggler .icon-bar:after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}
.btn {
  border-radius: 10px;
  font-weight: 700;
  padding: 14px 15px;
  min-width: 180px;
  font-size: 14px;
  line-height: 18px;
  border: 2px solid transparent;
  box-shadow: none;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  position: relative;
  min-height: 42px;
}
.btn2 {
  border-radius: 10px;
  font-weight: 700;
  text-align: center;
  padding: 14px 15px;
  min-width: 146px;
  font-size: 16px !important;
  line-height: 18px;
  border: 2px solid transparent;
  box-shadow: none;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  min-height: 50px;
}
.btn + .btn {
  margin-left: 20px;
}
.btn.btn-lg {
  min-width: 250px;
}
.btn.btn-sm {
  padding: 9px 15px;
  min-width: 150px;
  min-height: auto;
}
.btn.btn-xs {
  padding: 4px 12px;
  min-width: 100px;
  font-size: 12px;
  min-height: auto;
}
.btn.btn-default {
  font-size: 18px;
  font-weight: 600;
  border-radius: 11px;
  box-shadow: 6px 9px 6px rgb(0 0 0 / 15%);
}
.btn.btn-outline {
  box-shadow: 6px 9px 6px rgb(0 0 0 / 15%);
}
.btn-default,
.btn-default:focus {
  border-color: #f68e3f;
  color: #fff;
  background-color: #f68e3f;
}
.btn-default:hover,
.btn-default:active {
  border-color: #f68e3f;
  color: #f68e3f;
  background-color: transparent;
}
.btn-outline,
.btn-outline:focus {
  border-color: #f68e3f;
  background-color: transparent;
  font-size: 18px;
  color: #414141;
  font-weight: 600;
}
.btn-outline:hover,
.btn-outline:active {
  background-color: #f68e3f;
  color: #fff;
}
.btn.btn-ghost {
  background: #e6e6e6;
  color: #fff;
}
.btn-primary,
.btn-primary:focus {
  border-color: #ff8f00;
  color: #fff;
  background-color: #ff8f00;
}
.btn-primary:hover,
.btn-primary:active {
  border-color: #ff8f00;
  color: #ff8f00;
  background-color: transparent;
}
.btn-primary:not(:disabled):not(.disabled):active {
  border-color: rgba(255, 143, 0, 0.8);
  color: #fff;
  background-color: rgba(255, 143, 0, 0.8);
}
.btn-primary.disabled,
.btn-primary:disabled {
  border-color: #ff8f00;
  color: #fff;
  background-color: #ff8f00;
  opacity: 0.5;
}
.btn-secondary,
.btn-secondary:focus {
  border-color: #621ac0;
  color: #fff;
  background-color: #621ac0;
}
.btn-secondary:hover,
.btn-secondary:active {
  border-color: #621ac0;
  color: #621ac0;
  background-color: transparent;
}
.btn-secondary:not(:disabled):not(.disabled):active {
  border-color: rgba(98, 26, 192, 0.8);
  color: #fff;
  background-color: rgba(98, 26, 192, 0.8);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  border-color: #621ac0;
  color: #fff;
  background: #621ac0;
  opacity: 0.5;
}
.btn-outline1,
.btn-outline1:focus {
  border-color: #582bb8;
  color: #582bb8;
  background-color: transparent;
}
.btn-outline1:hover,
.btn-outline1:active {
  border-color: #621ac0;
  color: #621ac0;
  background-color: transparent;
}
.btn-grey,
.btn-grey:focus {
  border-color: #828282;
  color: #fff;
  background-color: #828282;
}
.btn-grey:hover,
.btn-grey:active {
  border-color: #828282;
  color: #828282;
  background-color: transparent;
}
.btn-grey:not(:disabled):not(.disabled):active {
  border-color: rgba(130, 130, 130, 0.8);
  color: #fff;
  background-color: rgba(130, 130, 130, 0.8);
}
.btn-grey.disabled,
.btn-grey:disabled {
  border-color: #828282;
  color: #fff;
  background: #828282;
  opacity: 0.5;
}
.btn-blue-outline,
.btn-blue-outline:focus {
  color: #1d0e3d;
  background-color: transparent;
  border: 2px solid #1d0e3d;
}
.btn-blue-outline:hover,
.btn-blue-outline:active {
  border-color: #1d0e3d;
  color: #fff;
  background-color: #1d0e3d;
}
.btn-dark-outline,
.btn-dark-outline:focus {
  color: #582bb8;
  background-color: #fff;
  border: 2px solid #582bb8;
}
.btn-dark-outline:hover,
.btn-dark-outline:active {
  border-color: #582bb8;
  color: #fff;
  background-color: #582bb8;
}
.btn-dark-blue,
.btn-dark-blue:focus {
  color: #fff;
  background-color: #180058;
  border: 2px solid #180058;
}
.btn-dark-blue:hover,
.btn-dark-blue:active {
  border-color: #180058;
  color: #180058;
  background-color: transparent;
}
.btn-blue,
.btn-blue:focus {
  color: #fff;
  background-color: #582bb8;
  border: 2px solid #582bb8;
}
.btn-blue:hover,
.btn-blue:active {
  border-color: #582bb8;
  color: #582bb8;
  background-color: transparent;
}
.btn.btn-xl {
  min-width: 290px;
  font-size: 17px;
}
.btn-white,
.btn-white:focus {
  border-color: #fffefc;
  color: #535353;
  background-color: #fffefc;
}
.btn-white:hover,
.btn-white:active {
  border-color: #fffefc;
  color: #fffefc;
  background-color: transparent;
}
.btn-icon {
  min-width: 50px;
  transition: none;
}
.btn-icon img {
  height: 15px;
  width: auto;
  object-fit: contain;
}
.btn-icon:hover,
.btn-icon:active {
  background-color: currentColor;
}
.btn-icon.disabled,
.btn-icon:disabled {
  opacity: 0.25;
}
.btn-outline-cancel {
  border: 1px solid #c97a77;
  color: #c97a77;
}
.btn-outline-cancel:hover,
.btn-outline-cancel:focus {
  border: 1px solid #c97a77;
  color: #fff;
  background: #c97a77;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.btn-loading {
  transition: none;
}
.btn-loading img {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.btn-loading:hover,
.btn-loading:active {
  background-color: currentColor;
}
.btn-link {
  min-width: auto;
  padding: 0;
  line-height: 26px;
  border-bottom-color: #621ac0;
  border-radius: 0;
  color: #621ac0;
}
.btn-link img {
  height: 11px;
  width: auto;
  object-fit: contain;
  margin-left: 8px;
}
.btn-link:hover,
.btn-link:active {
  text-decoration: none;
  color: #621ac0;
}
.btn-link.btn-link2 img {
  height: 8px;
  margin-left: 6px;
}
.btn.btn-block {
  min-width: 100%;
}
.ui-kit-container {
  width: 100%;
  max-width: 530px;
  margin: 0 auto;
}
.ui-kit-head {
  margin-bottom: 40px;
}
.ui-kit-head h1 {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: -0.8px;
  margin-bottom: 0;
}
.ui-kit-body h6 {
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #969696;
  margin-bottom: 16px;
}
.ui-kit-body h6 + hr {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 32px;
  position: relative;
}
.schedule-box .form-group:last-child {
  margin-bottom: 0;
}
.form-control {
  height: 60px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  padding: 5px 15px;
  color: #333;
  letter-spacing: 0.5px;
}
.form-control:focus {
  box-shadow: none;
  border-color: #621ac0;
}
.mui-date-picker {
  height: 60px !important;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  padding: 5px 15px;
  color: #333;
  letter-spacing: 0.5px;
}
.mui-date-picker label {
  top: 16px !important;
  left: 2px !important;
  font-family: 'Open Sans', sans-serif !important;
}

textarea.form-control {
  height: 278px;
  /* resize: none; */
}
select.form-control {
  background-image: url(../images/dropdown-arrow.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 15px auto;
  background-position-x: calc(100% - 15px);
  -webkit-appearance: none;
  cursor: pointer;
}
.custom-input label:not(.error) {
  position: absolute;
  top: 18px;
  left: 16px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #333;
  cursor: text;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  margin: 0;
  letter-spacing: 0.5px;
}
.custom-input.on label:not(.error),
.custom-input.show label:not(.error),
.custom-input label small {
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  top: 8px;
}
.form-control.error {
  border-color: #eb5757;
}
.custom-input label.error {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #ff0c3e;
  margin-bottom: 0;
}
.custom-input option {
  color: #3d3d3d;
}
.custom-input .watermark {
  color: rgba(0, 0, 0, 0.5);
}
.custom-input.on .form-control,
.custom-input.show .form-control {
  caret-color: #007bff;
  padding-top: 22px;
}
.custom-input.has-select label:not(.error) {
  opacity: 0;
  visibility: hidden;
}
.custom-input.has-select.on label:not(.error),
.custom-input.has-select.show label:not(.error) {
  opacity: 1;
  visibility: visible;
}
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block; /* float:left; */
  font-family: inherit;
  font-size: 16px;
  font-weight: normal;
  height: 60px;
  line-height: 60px;
  outline: none;
  padding: 0;
  padding-left: 15px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
  width: 100%;
  color: #333;
}
.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: #621ac0;
}
.nice-select:after {
  content: '';
  display: block;
  height: 6px;
  width: 12px;
  margin-top: -3px;
  background: url(../images/dropdown-arrow.svg) no-repeat center/contain;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}
.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}
.nice-select.disabled:after {
  opacity: 0.3;
}
.nice-select.wide {
  width: 100%;
}
.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}
.nice-select.right {
  float: right;
}
.nice-select.right .list {
  left: auto;
  right: 0;
}
.nice-select .list {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgb(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  width: 100%;
  padding: 8px 0;
  max-height: 220px;
  overflow-y: auto;
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 14px;
  color: #26262a;
}
.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: rgb(95, 50, 187, 10%);
  color: #621ac0;
}
.nice-select .option.selected {
  font-weight: 600;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}
.nice-select.small .option {
  padding-left: 7px;
}
.no-csspointerevents .nice-select .list {
  display: none;
}
.no-csspointerevents .nice-select.open .list {
  display: block;
}
.datepicker {
  background: url(../images/calendar-icon.svg) no-repeat calc(100% - 20px)
    center / 24px 24px;
}
.form-check {
  padding-left: 35px;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: left;
}
.form-check input[type='checkbox'].form-check-input {
  display: none;
}
.form-check input[type='checkbox'] + .form-check-label {
  cursor: pointer;
}
.form-check input[type='checkbox'] + .form-check-label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in;
}
.form-check
  input[type='checkbox'].form-check-input:checked
  + .form-check-label::before {
  border-color: #621ac0;
  background: #621ac0;
}
.form-check input[type='checkbox'] + .form-check-label::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: url(../images/checkbox-tick-icon.svg) no-repeat center/10px 7px;
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s ease-in;
}
.form-check
  input[type='checkbox'].form-check-input:checked
  + .form-check-label::after {
  opacity: 1;
  transform: scale(1);
}
.form-check
  input[type='checkbox'].form-check-input:disabled
  + .form-check-label::before {
  background: #f6f6f6;
}
.form-check input[type='radio'].form-check-input {
  display: none;
}
.form-check input[type='radio'] + .form-check-label {
  cursor: pointer;
}
.form-check input[type='radio'] + .form-check-label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 50%;
  transition: all 0.2s ease-in;
}
.form-check
  input[type='radio'].form-check-input:checked
  + .form-check-label::before {
  background: #621ac0;
  border-color: #621ac0;
}
.form-check input[type='radio'] + .form-check-label::after {
  content: '';
  position: absolute;
  left: 6px;
  top: 6px;
  width: 8px;
  height: 8px;
  display: block;
  background: #fff;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s ease-in;
}
.form-check
  input[type='radio'].form-check-input:checked
  + .form-check-label::after {
  opacity: 1;
  transform: scale(1);
}
.badge-group {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}
.badge-info {
  color: #621ac0;
  background-color: #f2f2f2;
}
.badge-group .badge {
  margin: 2px 0;
  padding: 5px 25px 5px 8px;
  border-radius: 4px;
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  position: relative;
  text-align: left;
  min-width: 59px;
}
.badge + .badge {
  margin-left: 8px;
}
.badge-group .badge [data-role='remove'] {
  cursor: pointer;
}
.badge-group .badge [data-role='remove']:after {
  content: '';
  background: url(../images/badge-close-icon.svg) no-repeat center/contain;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}
.badge-group .tag {
  display: inline-block;
  vertical-align: baseline;
  background: #fff;
  border: 1px solid #621ac0;
  border-radius: 15px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.3px;
  color: #621ac0;
  min-width: 65px;
  padding: 6px 12px;
  position: relative;
}
.badge-group .tag + .tag {
  margin-left: 12px;
}
.badge-group .tag [data-role='remove'] {
  cursor: pointer;
  background: url(../images/tag-close-icon.svg) no-repeat center/contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: baseline;
  margin-left: 10px;
}
.alert {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  border: 0;
  border-radius: 6px;
  padding: 12px 57px 10px;
  margin-bottom: 10px;
}
.alert-icon {
  position: absolute;
  left: 11px;
  top: 11px;
}
.alert h5 {
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}
.alert p {
  margin-bottom: 0;
}
.alert-right {
  padding-left: 0;
  margin-left: auto;
  position: absolute;
  top: 15px;
  right: 24px;
}
.alert-error {
  background: #feeaea;
  color: #fa3434;
}
.alert-info {
  background: #f1f8ff;
  color: #007bff;
}
.alert-success {
  background: #e5f7e7;
  color: #00b517;
}
.alert-warning {
  background: #fff3e7;
  color: #ff9017;
}
.alert.alert-sm {
  padding: 10px 36px;
}
.alert.alert-sm h5 {
  font-size: 13px;
}
.alert.alert-sm .alert-icon {
  top: 8px;
  left: 10px;
  width: 18px;
}
.alert.alert-sm .alert-right {
  top: 7px;
  right: 13px;
}
.alert-container {
  position: fixed;
  top: 50px;
  right: 20px;
  z-index: 99;
}
.alert.alert-white {
  background: #fff;
  border: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  color: #26262a;
  font-size: 15px;
  line-height: 20px;
  padding: 22px 50px 22px 60px;
  font-weight: 400;
}
.alert.alert-white p {
  margin-bottom: 0;
}
.alert-success.alert-white .alert-icon {
  color: #26ca97;
}
.alert-white .alert-icon {
  margin-right: 0;
  font-size: 20px;
  position: absolute;
  left: 18px;
  top: 23px;
}
.alert-white .alert-right {
  padding-left: 0;
  margin-left: auto;
  position: absolute;
  top: 18px;
  right: 25px;
  font-size: 25px;
}
.alert-white .alert-close {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}
.alert.alert-white.alert-danger .alert-icon {
  color: #ba0303;
}
.modal {
  z-index: 9999999999;
}

.modal-dialog {
  max-width: 525px;
  margin-top: 45px;
}

.pdf-model {
  width: 100vw !important;
  height: 100vh !important;
  padding: 0 !important;
}

.pdf-model .close {
  font-size: 2.5rem !important;
}

.pdf-model .modal-dialog {
  width: 100% !important;
  max-width: 100% !important;
  height: 100vh !important;
  /* height: 100% !important;
  max-height: 100% !important; */
  margin-top: 0;
  margin-left: 0 !important;
}

.pdf-model .modal-open .modal {
  padding: 0 !important;
}

.modal-content {
  border: 0;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 12px 20px;
}
.modal-header {
  border-bottom: none;
  position: relative;
  padding: 0;
}
.modal-body {
  padding: 15px 15px;
  padding-top: 0px;
}
.varification-popup {
  padding: 0 20px 35px;
}
.varification-popup h4 {
  line-height: 24px;
  margin-bottom: 30px;
}
.varification-popup p {
  font-size: 16px;
  line-height: 20px;
}
.input-send.send-icon img {
  margin-left: 5px;
}
.load-msg {
  position: relative;
  color: #8798ad;
  font-style: italic;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding-left: 40px;
}
.load-msg .btn-loader {
  left: 0;
}
.modal-backdrop:before {
  content: '';
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.85;
}
.modal-backdrop:after {
  content: '';
  width: 100%;
  height: 100%;
  background: #2e5bff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.08;
}
.modal-alert-box p {
  margin-bottom: 10px;
  font-weight: 400;
}
.modal-action-btn {
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.btn-delete {
  background: #ba0303;
  color: #fff;
}
.btn-delete:hover,
.btn-delete:focus {
  background: #ba0303;
  color: #fff;
}
.modal-action-btn .btn + .btn {
  margin-left: 10px;
}
.alert-close {
  opacity: 0.75;
}
.font-montserrat {
  font-family: 'Montserrat' !important;
}
.border-radius2 {
  border-radius: 20px !important;
}

/* =================global css ends here================= */

/* ================= Login/Signup css here================= */
.top-logo {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 25px;
}
.top-logo img {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 150px;
}
.member-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.member-container {
  width: 100%;
  min-height: calc(100vh);
  color: #26262a;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.member-container:before {
  content: '';
  width: 100%;
  min-height: 100vh;
  background: rgb(0, 0, 0, 0.75);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.member-box {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.member-left {
  width: calc(100vw - 590px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16%;
  padding-top: 35px;
}
.member-right {
  width: 590px;
}
.member-right-content {
  background: #fff;
  padding: 80px 60px;
  position: relative;
  border-radius: 23px;
  max-width: 470px;
  margin: 50px auto;
  width: 100%;
  min-height: calc(100vh - 100px);
}
.member-left-content {
  color: #fff;
  max-width: 330px;
  margin: 0 auto;
  font-size: 25px;
  line-height: 40px;
  letter-spacing: 0.4px;
}
.member-left-content h4 {
  font-family: 'Playfair Display', serif;
  font-size: 42px;
  text-transform: none;
  line-height: 1.2;
  margin-bottom: 15px;
}
.login-form .note {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  bottom: 40px;
  font-size: 13px;
  left: 0px;
  padding: 0px 100px;
  line-height: 13px;
}
.member-container .btn {
  min-height: 50px;
}
.note p {
  color: #bdbdbd;
}
.mr-head h2 {
  font-size: 26px;
  line-height: 21px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  margin-bottom: 35px;
}
.mr-head .login-text {
  letter-spacing: 0.57px;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 36px;
  color: #828282;
}
.mr-head .login-text a {
  color: #621ac0;
  font-weight: 600;
}
.ml-box {
  color: #fff;
}
.ml-box h1 {
  font-size: 50px;
  font-family: 'Ramaraja', serif;
  font-weight: 400;
  letter-spacing: 2px;
  margin-bottom: 10px;
}
.ml-box p {
  font-size: 24px;
}
.mr-box {
  width: 100%;
}
.mr-step {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;
  color: #26262a;
}
.mr-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 20% 30px;
  font-size: 13px;
  line-height: 20px;
  color: #c5c5c5;
  text-align: center;
}
.mr-bottom a {
  color: #621ac0;
}
.mr-bottom a:hover {
  text-decoration: underline;
}
.login-form .back-link {
  text-align: center;
  margin-top: -5px;
}
.login-form .btn-link {
  color: #621ac0;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-form .btn-link svg {
  width: 15px;
  fill: #621ac0;
  margin-right: 5px;
}
.login-form .forgot-password {
  font-size: 12px;
  color: #621ac0;
  letter-spacing: 0.7px;
  margin-top: -10px;
}
.login-form .forgot-password a {
  color: #621ac0;
}
.login-form .social-media {
  padding: 15px;
  text-align: center;
}
.login-form .social-media p {
  font-size: 15px;
  letter-spacing: 0.6px;
  color: #26262a;
}
.login-form .social-media a {
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 15px;
  margin: 0px 5px;
}
.login-form .social-media a.google {
  background-color: #cf4c3c;
}
.login-form .social-media a.apple {
  background-color: #0d0c22;
}
.login-form .form-group {
  margin-bottom: 22px;
}
.btn-loader {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 1;
}
.mr-head svg {
  width: 30px;
  margin-bottom: 20px;
}
.otp-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.otp-box input {
  width: 60px;
  margin-right: 25px;
  text-align: center;
}
.otp-box input:last-child {
  margin-right: 0;
}
.enter-otp-box > p {
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.6px;
  margin-bottom: 15px;
}
.time-count {
  font-size: 14px;
  text-align: center;
}
.time-count span {
  color: #f00;
}
.enter-otp-box {
  margin-bottom: 20px;
}
.resend-box {
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.6px;
}
.resend-box a {
  color: #621ac0;
}
.resend-box a:hover {
  text-decoration: underline;
}
.mr-head p a:hover {
  text-decoration: underline;
}
.form-check-icon {
  width: 100px;
  margin: 0 auto 40px;
}
.mr-box h3 {
  font-size: 25px;
  letter-spacing: 1.6px;
  margin-bottom: 25px;
  font-weight: 600;
  color: #f58000;
}
.seprator {
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin: 0 0 14px;
}
.seprator:before,
.seprator:after {
  content: '';
  width: calc(50% - 25px);
  height: 1px;
  background: #333;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.seprator:before {
  left: 0;
}
.seprator:after {
  right: 0;
}
.login-as-text-box h5 {
  font-size: 15px;
  margin-bottom: 0;
}

/* ================= Leftbar Start ================= */
.nav-toggler {
  position: absolute;
  top: -15px;
  right: 0;
  background: transparent;
  border: 0;
  font-size: 24px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: none;
}
.leftbar {
  width: 300px;
  background: #f9f9f9;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  padding: 35px 20px 35px 45px;
}
.leftbar h3 {
  font-size: 23px;
  line-height: 1.2;
  padding: 0 25px;
  margin-bottom: 35px;
  font-weight: 400;
}
.brand-logo {
  width: 90px;
  margin-bottom: 39px;
}
.nav-list {
  width: 100%;
}
.nav-list .nav-item {
  margin-bottom: 28px;
}
.nav-list .nav-link {
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0;
  color: #828282;
  position: relative;
  display: flex;
  align-items: center;
}
.nav-list .nav-link em {
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: solid 1px #bdbdbd;
  font-style: normal;
  display: inline-block;
  text-align: center;
  line-height: 24px;
  color: #828282;
  margin-right: 14px;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}
.nav-list .nav-link:hover,
.nav-list .active .nav-link {
  color: #621ac0;
  font-weight: 600;
}
.nav-list .nav-link:hover em,
.nav-list .active .nav-link em {
  color: #621ac0;
  border-color: #621ac0;
}
.nav-list .has-dropdown .nav-link {
  color: #333;
}
.nav-list .completed .nav-link,
.nav-list .completed .nav-link:hover {
  color: #bdbdbd;
  font-weight: 400;
}
.nav-list .completed .nav-link em,
.nav-list .completed .nav-link:hover em {
  background: #ffcc66;
  border-color: #ffcc66;
  font-size: 0;
}
.nav-list .completed .nav-link em:before {
  content: '';
  width: 10px;
  height: 10px;
  background: url(../images/tick-icon-white.svg) no-repeat center/10px 7.5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.nav-list .active ul.sub-menu {
  display: block;
}
ul.sub-menu {
  padding: 15px 40px 1px;
  display: none;
}
ul.sub-menu li a {
  font-size: 14px;
  line-height: 28px;
  color: #bdbdbd;
  padding: 9px 0;
}
ul.sub-menu li.active a {
  color: #621ac0;
}
.main-head {
  position: absolute;
  top: 35px;
  left: 30px;
  display: none;
}
.main-head .nav-toggler {
  position: relative;
  top: auto;
  right: auto;
}

/* ================= Leftbar End ================= */

/* ================= Main Container Start ================= */
.main-container {
  width: calc(100% - 300px);
  padding: 91px 88px;
  margin-left: 300px;
  min-height: 100vh;
}
.main-heading {
  margin-bottom: 24px;
}
.main-heading h1 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0;
  letter-spacing: 0.5px;
}
.main-top {
  position: absolute;
  top: 44px;
  right: 50px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #828282;
}
.main-top a {
  color: #621ac0;
}
.contract-content {
  font-size: 14px;
  line-height: 31px;
  color: #26262a;
  letter-spacing: 0.5px;
}
.add-more-field {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #621ac0;
}
.schedule-box .add-more-field {
  margin-top: 5px;
}
.badge-group .add-more-field {
  font-size: 14px;
  color: #bdbdbd;
  margin-left: 15px !important;
}
.url-input label {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #333;
}
.schedule-box {
  border-bottom: 1px solid #e0e0e0;
  padding: 14px 20px;
  min-height: 72px;
}
.schedule-box label {
  margin-bottom: 0;
}
.schedule-box label + a {
  margin-left: 15px;
}
.schedule-box .nice-select {
  height: 40px;
  line-height: 40px;
}
.schedule-day {
  min-width: 165px;
}
.schedule-day .form-check {
  padding-left: 35px;
  font-size: 16px;
}
.schedule-time label {
  font-size: 14px;
  margin-right: 15px;
}
.schedule-time .form-control {
  border-radius: 0;
  margin-right: 12px;
  padding-right: 40px;
}
.schedule-time .add-more-field {
  font-size: 14px;
  font-weight: 400;
}
.form-control.badge-group {
  height: auto !important;
  min-height: 60px;
}
.schedule-time .form-group + .form-check {
  margin-left: 15px;
}
.schedule-time .form-check label {
  padding-left: 35px;
}
.remove-field {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #f00;
}
.schedule-time + .schedule-time {
  margin-top: 15px;
}

/* ================= Datepicker Start ================= */
.xdsoft_datetimepicker {
  background: #fff;
  border: 0;
  box-shadow: none;
  padding: 0;
  margin-top: 1px;
  font-family: 'Open Sans', sans-serif;
  z-index: 9999;
}
.xdsoft_datetimepicker .xdsoft_datepicker {
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  padding: 15px;
  margin: 0;
  width: 100%;
}
.xdsoft_datetimepicker .xdsoft_monthpicker {
  display: flex;
  align-items: center;
  width: calc(100% - 13px);
  height: 34px;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}
.xdsoft_datetimepicker .xdsoft_today_button {
  display: none;
}
.xdsoft_datetimepicker .xdsoft_datepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_datepicker .xdsoft_prev {
  background-image: none;
  text-indent: inherit;
  margin-right: auto;
}
.xdsoft_datetimepicker .xdsoft_datepicker .xdsoft_next {
  margin-right: 0;
  margin-left: auto;
}
.xdsoft_datetimepicker .xdsoft_datepicker .xdsoft_next:before,
.xdsoft_datetimepicker .xdsoft_datepicker .xdsoft_prev:before {
  position: absolute;
  top: 5px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
.xdsoft_datetimepicker .xdsoft_datepicker .xdsoft_next:before {
  content: '\f105';
  right: 0;
}
.xdsoft_datetimepicker .xdsoft_datepicker .xdsoft_prev:before {
  content: '\f104';
  left: 0;
}
.xdsoft_datetimepicker .xdsoft_label {
  font-size: 16px;
  font-weight: 400;
  padding: 0;
}
.xdsoft_datetimepicker .xdsoft_label i {
  display: none;
}
.xdsoft_datetimepicker .xdsoft_month {
  width: auto;
}
.xdsoft_datetimepicker .xdsoft_year {
  width: auto;
  text-align: left;
}
.xdsoft_datetimepicker .xdsoft_calendar th {
  font-size: 18px;
  font-weight: 500;
}
.xdsoft_datetimepicker .xdsoft_calendar td,
.xdsoft_datetimepicker .xdsoft_calendar th {
  background: transparent !important;
  color: #26262a;
  border: 0;
  font-weight: 400;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.2s ease;
  width: 49px;
  height: 47px;
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  background: transparent !important;
}
.xdsoft_datetimepicker .xdsoft_calendar td div {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  font-size: 18px;
  line-height: 42px;
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover div {
  background: #621ac0;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today,
.xdsoft_datetimepicker
  .xdsoft_timepicker
  .xdsoft_time_box
  > div
  > div.xdsoft_today,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker
  .xdsoft_timepicker
  .xdsoft_time_box
  > div
  > div.xdsoft_current {
  box-shadow: none;
  color: #fff;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today div,
.xdsoft_datetimepicker
  .xdsoft_timepicker
  .xdsoft_time_box
  > div
  > div.xdsoft_today
  div {
  background: rgb(95, 50, 187, 10%);
  color: #621ac0;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default div,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current div,
.xdsoft_datetimepicker
  .xdsoft_timepicker
  .xdsoft_time_box
  > div
  > div.xdsoft_current
  div {
  background: #621ac0;
  color: #fff;
}
.xdsoft_datetimepicker .xdsoft_calendar td > div {
  padding: 0;
  text-align: center;
}
.xdsoft_datetimepicker .xdsoft_timepicker {
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  margin: 0;
}
.xdsoft_datetimepicker .xdsoft_datepicker.active + .xdsoft_timepicker {
  margin: 0;
  margin-left: 10px;
}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev {
  background-image: none;
  text-indent: inherit;
}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next:before,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev:before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next:before {
  content: '\f107';
  bottom: 0;
}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev:before {
  content: '\f106';
  top: 0;
}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box {
  height: 173px;
  border: 0;
}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div {
  background: transparent;
  border: 0;
  color: #26262a;
  font-size: 10px;
  transition: all 0.2s ease;
}
.xdsoft_datetimepicker
  .xdsoft_timepicker
  .xdsoft_time_box
  > div
  > div.xdsoft_current {
  background: #621ac0 !important;
  color: #fff !important;
}
.xdsoft_datetimepicker
  .xdsoft_label
  > .xdsoft_select
  > div
  > .xdsoft_option.xdsoft_current {
  background: #621ac0;
  color: #fff;
  box-shadow: none !important;
}
.xdsoft_datetimepicker
  .xdsoft_label
  > .xdsoft_select
  > div
  > .xdsoft_option:hover {
  background: rgb(95, 50, 187, 10%);
  color: #621ac0;
}

/* ================= Datepicker End ================= */

/* ================= Upload CSS Start ================= */
.custom-upload label:not([class]) {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
}
.custom-upload label:not([class]) span {
  margin-left: 5px;
  color: #bdbdbd;
}
.btn-upload,
.btn-upload:hover {
  border: 1px solid #e0e0e0;
  font-weight: 400;
  flex-flow: row;
  color: #d9d9d9;
  line-height: 30px;
  margin-bottom: 0;
}
.form-group .note {
  display: block;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.6px;
  color: #bdbdbd;
  margin-top: 7px;
}
.form-group .note strong {
  color: #26262a;
}
.btn-upload > span {
  color: #621ac0;
  margin-right: 6px;
}
.btn-upload > span > img {
  margin-right: 10px;
}
.photo-upload-modal .modal-dialog {
  max-width: 853px;
}
.photo-upload-modal .modal-content {
  padding: 58px 26px;
}
.photo-upload-modal .close {
  position: absolute;
  top: 0;
  right: -40px;
  opacity: 1;
}
.photo-upload-left {
  width: 30%;
}
.photo-upload-right {
  width: 70%;
  padding: 12px 0 12px 40px;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.6px;
}
.photo-upload-right h6 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 17px;
}
.photo-upload-right ol {
  list-style-position: inside;
}
.photo-upload-right ol li {
  margin-bottom: 12px;
}

/* ================= Upload CSS End ================= */

/* ================= Book an appointment Start ================= */
.member-coontent-box {
  width: 100%;
}
.member-content h2 {
  font-size: 26px;
  line-height: 21px;
  letter-spacing: 0.6px;
  color: #0d0c22;
  margin-bottom: 50px;
}
.member-content {
  background: #fff;
  padding: 52px 140px;
  position: relative;
  border-radius: 23px;
  max-width: 1115px;
  width: 100%;
  margin: 15px; /*min-height:550px;*/
}
ul.appointment-type-list .at-item {
  width: 33.33%;
  padding: 15px;
  text-align: center;
  position: relative;
}
ul.appointment-type-list .at-item:not(:last-child):before {
  content: '';
  width: 1px;
  height: 100%;
  background: #f2f2f2;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
ul.appointment-type-list .at-item:after {
  content: '';
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  border-radius: 10px;
}
ul.appointment-type-list .at-item:hover:after {
  opacity: 1;
}
ul.appointment-type-list .at-item:hover:before {
  content: none;
}
.at-box {
  width: 100%;
  height: 100%;
  padding: 35px 15px 35px;
  text-align: center;
  position: relative;
  z-index: 2;
  border-radius: 10px;
}
.at-box h4 {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  color: #26262a;
}
.at-box h4 > span {
  display: block;
  font-size: 11px;
  line-height: 21px;
  color: #bdbdbd;
}
.at-img {
  width: 133px;
  margin: 0 auto 35px;
}
.at-box .btn {
  font-size: 16px;
  padding: 18px 15px;
  opacity: 0;
  visibility: hidden;
}
.at-box:hover .btn {
  opacity: 1;
  visibility: visible;
}
.search-box {
  background: #fff;
  padding-left: 10px;
  padding-right: 40px;
  margin-bottom: 65px;
  position: relative;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #bdbdbd;
}
.search-box:before {
  content: '';
  width: 18px;
  height: 18px;
  position: absolute;
  right: 12px;
  top: 8px;
  cursor: pointer;
  background: url(../images/search-icon.svg) no-repeat center/cover;
}
.search-input {
  background: transparent;
  border: 0;
  height: 36px;
  width: 100%;
  color: #26262a;
  font-size: 14px;
}
.search-input::placeholder {
  color: #9b9b9b;
}
.dropdown-toggle::after {
  content: none;
}
.dropdown-toggle i {
  font-size: 12px;
  color: #8798ad;
}
ul.city-list li {
  width: auto;
  padding: 15px;
  flex: 1 0 0;
  position: relative;
}
.city-thumbnail {
  width: 70px;
  margin: 0 auto 15px;
}
.city-box h4 {
  font-size: 14px;
  color: #bdbdbd;
  margin-bottom: 0;
}
.city-list li:not(:last-child):before {
  content: '';
  width: 1px;
  height: calc(100% - 30px);
  background: #e0e0e0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* ================= Book an appointment End ================= */

/* ================= dashboard page Start ================ */
body.dashboard {
  padding-left: 250px;
  padding-top: 70px;
}
.patient-class{
padding-top: 100px !important; 
}
.dashboard-top-bar {
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  box-shadow: 0px 2px 3px #5a5a5a1a;
  height: 70px;
  /* z-index: 999999; */
  display: flex;
  align-items: center;
}
.dashboard-left-bar {
  width: 250px;
  background: #fff;
  top: 70px;
  left: 0;
  bottom: 0;
  position: fixed;
  box-shadow: 3px 0px 16px #5a5a5a1a;
  z-index: 999999;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  overflow: auto;
}
.dashboard-main-container {
  padding: 40px;
  min-height: calc(100vh - 70px);
  position: relative;
  background: #f0f0f0;
}
.dashboard-top-bar .navbar-brand {
  width: 109px;
}
.nav {
  align-items: center;
}
.nav li {
  padding: 0 30px;
  position: relative;
}
.nav li:last-child {
  padding-right: 0;
}
.dashboard-top-bar .nav li:not(:first-child):before {
  content: '';
  width: 1px;
  height: 100%;
  background: #d6d6d6;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.profile-menu-box {
  width: auto;
  display: flex;
  align-items: center;
}
.profile-img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  background: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a8a8a8;
}
.dashboard-menu-list li.dm-item {
  width: 100%;
  position: relative;
}
.dashboard-menu-list li.dm-item:not(:last-child) {
  margin-bottom: 6px;
}
.dashboard-left-bar {
  padding: 30px 0;
}
.profile-text-box {
  color: #77708b;
  font-weight: 600;
  font-size: 13px;
}
.profile-text-box > img {
  margin-left: 8px;
}
.profile-img {
  margin-left: 12px;
}
.notification-box {
  color: #77708b;
  font-size: 13px;
  font-weight: 600;
  display: flex;
}
.notification-box > span {
  margin-left: 8px;
}
ul.dashboard-menu-list li.dm-item h4 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
ul.dashboard-menu-list li.dm-item a > img {
  margin-right: 12px;
}
ul.dashboard-menu-list li.dm-item.active h4 {
  font-weight: 700;
  color: #1d0e3d;
  white-space: nowrap;
}
.hasmenu > a:before {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  display: inline-block;
  position: absolute;
  font-size: 14px;
  right: 18px;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  color: #1d0e3d;
}
.active.hasmenu > a::before {
  transform: rotate(180deg);
}
ul.new-submenu {
  display: none;
}
ul.new-submenu li > a {
  width: 100%;
  padding: 14px 30px;
  font-size: 15px;
  color: #43425d;
}
ul.new-submenu li > a > img {
  width: 12px;
}
ul.dashboard-menu-list li.dm-item.active a,
ul.dashboard-menu-list li.dm-item a:hover {
  background: rgb(88 43 184 / 15%);
}
ul.dashboard-menu-list li.dm-item > a {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 18px 30px;
}
.dc-head {
  margin-bottom: 35px;
}
.dc-head-left {
  width: 218px;
}
.dc-head-left h4 > span {
  font-size: 24px;
  color: #1d0e3d;
  margin-bottom: 0;
  font-weight: 600;
  display: block;
  font-family: 'Montserrat', sans-serif;
}
.dc-head-left h4 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}
.dc-head-right {
  width: calc(100% - 218px);
}
ul.dashboard-link-list {
  list-style-type: none;
  counter-reset: css-counters 0;
}
ul.dashboard-link-list li > a {
  position: relative;
  padding-left: 57px;
  line-height: 40px;
  font-size: 18px;
  color: #1d0e3d;
  font-weight: 400;
}
ul.dashboard-link-list li.active > a {
  font-weight: 700;
  color: #582bb8;
}
ul.dashboard-link-list li:not(:last-child) {
  margin-right: 40px;
}
ul.dashboard-link-list li.active > a:before {
  color: #fff;
  background: #582bb8;
}
ul.dashboard-link-list li > a:before {
  counter-increment: css-counters;
  content: counters(css-counters, '.') ' ';
  width: 40px;
  height: 40px;
  background: #fff;
  position: absolute;
  left: 0;
  text-align: center;
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
}
.dc-body {
  padding: 40px;
  background: #fff;
  border-radius: 20px;
}
.dc-left {
  width: 60%;
}
.dc-right {
  width: 40%;
  padding: 80px 0;
}
.dc-right-content h1 {
  font-size: 18px;
  color: #180058;
  margin-bottom: 0;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}
.dc-right-content h1 > span {
  color: #f1964f;
}
.dc-right-content {
  max-width: 210px;
  text-align: center;
}
.dc-img-box {
  margin: 0 auto 15px;
}
.dc-right-content p {
  padding: 0 20px;
  color: #77708b;
  font-size: 15px;
}
.dc-profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
.dc-profile-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dc-profile-intro > span {
  margin: 0 15px 0 20px;
  font-size: 18px;
  color: #1d0e3d;
}
.dc-right-content {
  margin: 0 auto;
  position: -webkit-sticky;
  position: sticky;
  top: 101px;
  z-index: 0;
}
.dc-profile-intro .btn.btn-xs {
  min-width: 90px;
}
.dc-profile-box {
  margin-bottom: 30px;
}
.dc-footer {
  margin-top: 40px;
  text-align: right;
}
.dc-left .agree-box {
  margin-top: 100px;
  font-size: 12px;
  font-weight: 400;
}
.dc-left .agree-box p > a {
  font-weight: 700;
  text-decoration: underline;
}
.dc-footer .btn {
  min-width: 240px;
}
.add-file-upload.file-upload {
  background: #e6dff5;
  width: 310px;
  margin: 35px 0;
}
.file-upload-btn {
  width: 100%;
  margin: 0;
  color: #fff;
  background: #1fb264;
  border: none;
  padding: 10px;
  border-radius: 7px;
  border-bottom: 4px solid #15824b;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}
.file-upload-btn:hover {
  background: #1aa059;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}
.file-upload-btn:active {
  border: 0;
  transition: all 0.2s ease;
}
.file-upload-content {
  display: none;
  text-align: center;
}
.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.image-upload-wrap {
  border: 2px dashed #1d0e3d;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
}
.image-dropping,
.image-upload-wrap:hover {
  background-color: #1d0e3d;
  border: 2px dashed #ffffff;
}
.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}
.drag-text {
  text-align: center;
}
.drag-text h3 {
  font-weight: 400; /*text-transform:uppercase;*/
  color: #1d0e3d;
  padding: 85px 0;
  margin-bottom: 0;
  font-size: 17px;
}
.image-upload-wrap:hover .drag-text h3 {
  color: #fff;
}
.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}
.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #b02818;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}
.remove-image:hover {
  background: #1fb264;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}
.remove-image:active {
  border: 0;
  transition: all 0.2s ease;
}
.dashboard-top-bar .navbar {
  padding: 0;
  width: 100%;
}
.on .dashboard-left-bar {
  left: 0;
}
.on .overlay {
  opacity: 1;
  visibility: visible;
}
.dc-left h4 {
  font-size: 28px;
  font-weight: 600;
}
.dc-left h4 + p {
  font-size: 14px;
  color: #1d0e3d;
}
.dc-left p {
  font-size: 12px;
  color: #1d0e3d;
}
.dc-block-box {
  padding: 40px 0;
}
.dc-block {
  padding: 0 0 0 40px;
}
.dc-block-head h6 {
  font-size: 14px;
  color: #1d0e3d;
  margin-bottom: 16px;
  font-weight: 400;
}
.block-seprator {
  width: 100%;
  height: 1px;
  background: #707070;
  position: relative;
  margin: 40px 0;
  position: relative;
}
.form-group.custom-input textarea.form-control {
  height: 110px !important;
}
.custom-radio .form-check-input:checked ~ .form-check-label {
  background: rgb(88 43 184 / 20%);
  border-color: transparent;
}
.custom-radio .form-check-label {
  padding: 12px 40px;
  border-radius: 12px;
  border: 1px solid #582bb8;
}
.custom-radio .form-check {
  padding-left: 0;
}
.custom-radio .form-check input[type='radio'] + .form-check-label::before {
  top: 13px;
  left: 10px;
}
.custom-radio .form-check input[type='radio'] + .form-check-label::after {
  top: 19px;
  left: 16px;
}
.submenu {
  display: none;
}
.submenu ul {
  list-style: none;
  margin: 0;
  padding: 0px;
}
.submenu li {
  background-color: #eeeeee;
  margin: 0px 0px 1px 4px;
  line-height: 30px;
}
.submenu li a {
  color: #000000;
}
.dc-check-icon {
  margin-left: 20px;
}
.dc-check-icon > img {
  margin-right: 4px;
}
.dc-bottom-box {
  text-align: right;
  margin-top: 50px;
}
.dc-box .dc-left {
  width: 66%;
}
.dc-block-body .form-group {
  margin-bottom: 0;
}
.dc-block + .dc-block {
  margin-top: 32px;
}
.dc-block .form-check {
  margin-right: 15px;
  font-size: 17px;
  color: #1d0e3d;
}
.form-check.form-check-inline {
  margin-bottom: 19px;
}
.history .dc-left {
  width: 70%;
}
.history .dc-right {
  width: 30%;
}
.ftu-heading h6 {
  font-size: 24px;
  font-weight: 600;
  color: #1d0e3d;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0;
}
.ftu-heading {
  font-size: 18px;
  color: #1d0e3d;
  margin-bottom: 20px;
}
ul.specialist-list {
  width: calc(100% + 40px);
  margin-left: -20px;
  max-width: 767px;
}
ul.specialist-list li.specialist-item {
  width: 50%;
  padding: 20px;
}
.specialist-box {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 20px;
  font-size: 18px;
  color: #1d0e3d;
  border-radius: 20px;
  overflow: hidden;
}
.specialist-img {
  width: 102px;
  height: 102px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 20px;
}
.specialist-btn .btn-outline {
  box-shadow: none !important;
}
.specialist-box h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}
.specialist-box h4 + p {
  font-size: 13px;
  color: #77708b;
  margin-bottom: 24px;
  font-weight: 400;
}
.add-dot-border {
  border: 2px dashed #582bb880 !important;
}
.add-solid-border {
  border: 1px solid #18005833 !important;
}
.box-shadow {
  box-shadow: 0px 8px 16px #18005829 !important;
}
.specialist-btn {
  margin-top: auto;
}
.min-width-80 {
  min-width: 80% !important;
}
.dc-block-body .badge-group .tag {
  background: #e6dff5;
  border-color: #e6dff5;
  color: #1d0e3d;
  font-weight: 400;
}
.dc-block-body .badge-group .tag [data-role='remove'] {
  background: url(../images/tag-close-icon-1.svg) no-repeat center/contain;
}
.dc-block .course .form-check {
  margin-bottom: 15px;
}
.no-shadow {
  box-shadow: none !important;
}
.custom-menu {
  position: relative;
}
.custom-menu .custom-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: -22px;
  width: 220px;
  background: #fff;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0px 1px 28px #582bb833;
  border: 1px solid #1800581f;
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in;
}
.custom-menu .custom-dropdown.show {
  opacity: 1;
  visibility: visible;
}
.custom-dropdown ul li a:hover {
  color: #33448a;
}
.custom-dropdown ul li > a {
  padding: 13px 15px 13px 20px !important;
  font-size: 16px;
  color: #180058;
  font-weight: 400;
  width: 100%;
  position: relative;
  z-index: 1;
}
.custom-dropdown ul li {
  align-items: flex-start !important;
  justify-content: flex-start;
  flex-flow: column;
  padding: 0 !important;
  position: relative;
}
.custom-dropdown ul li:not(:last-child) {
  border-bottom: 1px solid #1800581f;
}
.custom-dropdown ul li:before {
  content: none !important;
}
.custom-dropdown ul li:not(:last-child):after {
  content: '';
  background-image: url(../images/chevron-right.svg);
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  height: 11px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-dropdown ul li b {
  font-size: 16px;
  font-weight: 700;
  color: #d57575;
  text-align: center;
}
.custom-dropdown ul li b > img {
  margin-right: 8px;
}
.custom-dropdown ul li:last-child {
  text-align: center;
  text-transform: uppercase;
}
.custom-menu .custom-dropdown:before {
  content: '';
  border-bottom: solid 8px #fff;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
  position: absolute;
  top: -8px;
  right: 32px;
  filter: drop-shadow(0px 1px 28px #582bb833);
}
.custom-dropdown ul li b > img {
  width: 22px;
}
.chatbot-pannel {
  width: calc(100% + 30px);
  margin-left: -15px;
  height: calc(100vh - 150px);
}
.chatbot-pannel-left {
  width: 392px;
  padding: 0 15px;
  position: relative;
  z-index: 9;
  height: 100%;
}
.cpl-wrapper-outer {
  width: 100%;
  height: calc(100% - 48px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 0 50px;
}
.cpl-wrapper-box {
  padding: 20px;
  background: #fff;
  border: 1px solid #18005833;
  border-radius: 20px;
  font-size: 15px;
  line-height: 25px;
  color: rgb(29 14 61 / 60%);
}
.cpl-wrapper-box + .cpl-wrapper-box {
  margin-top: 24px;
}
.chatbot-pannel-right {
  width: calc(100% - 392px);
  padding: 0 15px;
  display: flex;
  flex-flow: column;
  height: 100%;
}
.chatbot-box {
  background: #fff;
  border-radius: 20px;
  height: 100%;
}
.dropdown-toggle i {
  font-size: 12px;
  color: #bfc5d2;
}
.dropdown-toggle:hover,
.dropdown-toggle:focus {
  color: #4a4a4a;
}
.cpl-img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.cpl-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.cpl-box {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 0;
  position: relative;
  z-index: 99;
}
.cpl-box:last-child {
  border-bottom: 0;
  margin-top: auto;
}
.cpl-box:last-child p {
  margin-bottom: 15px;
}
.add-orange-bg {
  position: relative;
}
.add-orange-bg:before {
  content: '';
  width: calc(100% + 40px);
  border: 1px solid #e5e5e5;
  margin-left: -20px;
  background: rgb(241 150 79 / 10%) !important;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.add-orange-bg p {
  color: #1d0e3d;
}
.add-orange-bg .cpl-icon-box {
  background: transparent;
  border: 1px solid #f1964f;
}
.cpl-head h3,
.cpl-head h2 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  color: rgb(29 14 61 / 60%);
}
.cpl-text-box h5 {
  font-size: 18px;
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 700;
}
.cpl-text-box {
  width: calc(100% - 65px);
  padding-left: 15px;
  font-size: 15px;
}
.cpl-head h3 {
  font-weight: 700;
}
.cpl-intro-desc p strong {
  font-size: 18px;
}
.cpl-intro-desc p {
  font-size: 14px;
  margin-bottom: 10px;
}
.cpl-text-box h5,
.cpl-intro-desc p strong {
  color: #1d0e3d;
}
.cpl-intro-desc {
  width: calc(100% - 91px);
}
.cpl-icon-box {
  background: #f1964f;
  border-radius: 10px;
  overflow: hidden;
  width: 91px;
  padding: 5px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.has-active,
.has-inactive {
  position: relative;
}
.has-active:before,
.has-inactive:before {
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #7fb440;
  position: absolute;
  right: 0;
  top: 0;
  border: 2px solid #fff;
}
.has-inactive:before {
  background: #c4c4c4 !important;
}
.no-border {
  border: 0 !important;
}
.cpl-head {
  position: relative; /*z-index: 99;*/
}
.add-shape {
  position: relative;
}
.add-shape:before {
  content: '';
  width: 10px;
  height: calc(100% - 60px);
  top: 50%;
  transform: translateY(-50%);
  background-color: #dddbe2;
  right: -10px;
  position: absolute;
  z-index: 0;
  border-radius: 0px 20px 20px 0px;
}
.cpl-box p small {
  color: #1d0e3d;
  font-size: 15px;
}
.chatbot-pannel-left h4,
.chatbot-pannel-right h4,
.timeline-box h4,
.treatment > h4 {
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  color: #1d0e3d;
  font-weight: 600;
}
.nav-tabs.nav-tabs2 {
  border-color: #e5e5e5; /*margin-bottom:-1px;*/
}
.nav-tabs.nav-tabs2 .nav-link {
  line-height: 59px;
  border: 0;
  text-align: center;
  border-radius: 20px;
  font-size: 20px;
  color: #1d0e3d;
  padding: 0;
}
.nav.nav-tabs2 li:not(:last-child):before {
  content: none;
}
.nav-tabs.nav-tabs2 .nav-item.show .nav-link,
.nav-tabs.nav-tabs2 .nav-link.active {
  border: 0;
  background: rgb(88 43 184 / 15%);
  border-radius: 20px;
  color: #130928;
}
.nav-tabs.nav-tabs2 li:first-child .nav-link {
  border-radius: 20px 20px 20px 0px;
}
.nav-tabs.nav-tabs2 li:last-child .nav-link {
  border-radius: 20px 20px 0px 20px;
}
.nav-tabs.nav-tabs2 li {
  padding: 0;
}
.nav-tabs2 .nav-link > b {
  font-weight: 700;
  display: inline-block;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.nav-tabs2 .nav-link.active > b {
  opacity: 1;
  visibility: visible;
}
.nav-tabs2 .nav-link.active > b + span {
  opacity: 0;
  visibility: hidden;
}
.chatbot-body {
  padding: 20px;
}
.chatbot-body .card,
.tab-content-box .card {
  border: 0;
  border-radius: 0;
  padding: 0;
}
.chatbot-body .card-header,
.tab-content-box .card-header {
  border: 0;
  border-radius: 20px !important;
  background: #f0f0f0;
  color: #1d0e3d;
  font-size: 18px;
  line-height: 24px;
  padding: 15px;
  position: relative;
}
.chatbot-body .active .card-header:after,
.tab-content-box .active .card-header:after {
  content: '\f068';
}
.chatbot-body .active .card-header,
.tab-content-box .active .card-header {
  background: #f68e3f;
  color: #fff;
}
.chatbot-body .card-header:after,
.tab-content-box .card-header:after,
.active .card-header:after {
  content: '\f067';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 12px;
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -8px;
  line-height: 16px;
}
.pane-content-box label.form-check-label a {
  color: #621ac0;
}
.pane-content-box label.form-check-label a:hover {
  text-decoration: underline;
}
.chatbot-body .card:not(:last-child),
.active .card:not(:last-child),
.tab-content-box .card:not(:last-child) {
  padding-top: 0;
  margin-bottom: 15px;
}
.add-bg {
  position: relative;
  overflow: hidden;
}
.add-bg:before {
  content: '';
  width: 100%;
  height: 100%;
  background-image: url(../images/shape-img.png);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.chatbot-body .tab-content .card:not(:last-child) {
  padding-top: 0;
  margin-bottom: 0;
}
.dropright .dropdown-toggle::after {
  content: none;
}
.dropright .dropdown-menu {
  margin-left: 10px;
  margin-top: -5px;
  filter: drop-shadow(0px 3px 6px #18005829);
  border: 0;
  border-radius: 10px;
}
.dropdown-custom .dropdown-item:focus,
.dropdown-custom .dropdown-item:hover {
  background: transparent;
}
.dropdown-custom .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}
.dropdown-custom .dropdown-item {
  color: #1d0e3d;
  font-size: 12px;
}
.dropdown-custom:before {
}
.dropright [x-placement='right-start']:before {
  content: '';
  border-right: solid 8px #fff;
  border-bottom: solid 8px transparent;
  border-top: solid 8px transparent;
  position: absolute;
  left: -8px;
  top: 11px;
  filter: drop-shadow(0px 1px 28px #582bb833);
}
.dropright [x-placement='left-start']:before {
  content: '';
  border-left: solid 8px #fff;
  border-bottom: solid 8px transparent;
  border-top: solid 8px transparent;
  position: absolute;
  right: -8px;
  top: 11px;
  filter: drop-shadow(0px 1px 28px #582bb833);
}
.dropright .dropdown-menu[x-placement='left-start'] {
  margin-left: 0;
}
.add-dot {
  position: relative;
}
.add-dot:before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #397ff4;
  position: absolute;
  right: -12px;
  top: 3px;
}
.tpc-upper {
  padding: 40px 80px 25px;
}
.tpc-box {
  background: #f7f7f7;
  padding: 28px 100px;
  border: 1px dashed #18005833;
  border-radius: 10px;
}
.tpc-box p {
  margin: 0 30px 0;
}
.tpc-month-box {
  position: relative;
  overflow: hidden;
  margin: 25px 0;
}
.tpc-month-box h6 {
  display: inline-block;
  font-size: 18px;
  color: #43425d;
  font-weight: 700;
  margin-bottom: 0;
  position: relative;
  padding: 0 30px 0 80px;
}
.tpc-month-box h6:before {
  content: '';
  width: 100vw;
  height: 1px;
  background: #e5e5e5;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
}
.tpc-month-box h6:after {
  content: '';
  width: 50px;
  height: 1px;
  background: #e5e5e5;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
span.document-intro {
  background: #e6dff5;
  border-radius: 4px;
  color: #1d0e3d;
  font-size: 9px;
  line-height: 1;
  padding: 9px 10px;
  position: absolute;
  left: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.document-img-box {
  padding: 48px 8px;
  border: 1px solid rgb(24 0 88 / 20%);
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.document-box h4 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: rgb(67 66 93 / 70%);
}
ul.documents-list {
  width: calc(100% + 30px);
  margin-left: -15px;
}
ul.documents-list li {
  width: 25%;
  padding: 20px;
}
.document-box:hover .document-img-box {
  box-shadow: 0px 3px 6px #18005829;
  border-color: transparent;
}
.document-box:hover h4 {
  color: #180058;
}
.document-content-box + .document-content-box {
  margin-top: 25px;
}
.document-dropdown {
  position: absolute;
  right: 15px;
  top: 15px;
}
.document-upper {
  position: relative;
  padding: 8px;
}
.background-image {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.notes-box {
  cursor: pointer;
  width: 100%;
  background: #fff;
  position: relative;
  padding: 17px 15px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  font-size: 20px;
  color: #180058;
}
.notes-box p {
  margin-bottom: 0;
}
.fillup-btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 181px;
  height: 100%;
  padding: 5px 50px;
  display: flex;
  align-items: center;
  background: #582bb8;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
}
.notes-box > i {
  font-size: 28px;
  margin-right: 15px;
  color: #70d09d;
}
.notes-box + .notes-box {
  margin-top: 25px;
}
#chooseFile {
  cursor: pointer;
}
.notes-pane-content {
  font-size: 21px;
  color: rgb(24 0 88 / 50%);
}
.notes-pane-content {
  font-size: 19px;
}
.sp-body {
  height: calc(100% - 64px);
  overflow: auto;
}
.dc-box {
  padding: 40px;
}
.my-profile-box .dc-right {
  padding: 0;
}
.my-profile-box .dc-right h3 {
  font-size: 19px;
  color: #180058;
  font-weight: 700;
  margin-bottom: 0;
  text-align: right;
}
.my-profile-box .dc-right h3 span {
  font-weight: 400;
}
.dc-block .wrap .form-check {
  margin-bottom: 15px;
}

/*Accordion start*/
.ih-box.collapsed i > img {
  transform: rotate(0deg);
}
.icon i > img {
  transform: rotate(180deg);
  transition: 0.5s;
}
.ih-box {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 13px 27px 13px 15px;
  position: relative;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  border: 1px solid transparent;
  border-bottom: 0;
}
.ih-box:focus {
  box-shadow: 0px 3px 6px #00000029 !important;
}
.slb-icons-group {
  opacity: 0;
  visibility: visible;
  width: 0%;
  z-index: 1000;
  position: relative;
}
.ih-box[aria-expanded='true'] .slb-icons-group {
  opacity: 1;
  visibility: visible;
  width: 100%;
}
.slb-icons-outer {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.slb-icons-group .slb-icon,
.slb-icons-group {
  margin-right: 25px;
}
.ih-box h3 {
  font-size: 20px;
  margin-bottom: 0;
  color: #180058;
  display: flex;
  align-items: center;
}
.ih-box h3 > svg {
  margin-right: 15px;
}
.item:not(:first-child) {
  margin-top: 20px;
}
.item .t-p {
  padding: 35px 37px;
}
.ih-box[aria-expanded*='true'] {
  background: #582bb826;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #18005833;
}
.item .collapse {
  border: 1px solid #18005833;
  border-top: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ih-box[aria-expanded*='true'] svg .a {
  fill: #621ac0;
}
svg .a {
  fill: #70d09d;
}
svg .b {
  fill: #fff;
}
.check-box-group .form-control::placeholder {
  color: #180058;
}
.check-box-group .form-control {
  font-weight: 700;
}
.check-box-text {
  font-weight: 700;
  border-radius: 8px;
  letter-spacing: 0.5px;
  padding: 5px 15px;
  color: #180058;
  border: 1px solid #18005833;
}
.accor-seprator {
  width: 100%;
  height: 1px;
  background: #707070;
}

/*Accordion end*/

/*Modal Popups start*/
.custom-modal .modal-dialog {
  max-width: 448px;
}
.custom-modal.custom-modal2 .modal-dialog {
  max-width: 754px;
}
.custom-modal.custom-modal3 .modal-dialog {
  max-width: 806px;
}
.custom-modal {
  padding-left: 0;
}
.custom-modal .modal-dialog {
  max-width: 448px;
}
.custom-modal h6 {
  font-family: 'Montserrat', sans-serif;
  font-size: 21px;
  color: #180058;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 32px;
  margin-bottom: 20px;
}
.custom-modal p {
  font-size: 18px;
  color: #180058;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 40px;
}
.custom-modal .modal-body {
  box-shadow: 3px 0px 16px #5a5a5a1a;
  padding: 60px 86px;
  background: #fbfbfb;
  border-radius: 16px;
  text-align: center;
}
.btn-block + .btn-block {
  margin-top: 0;
}
.custom-modal .modal-content {
  padding: 0;
}
.custom-modal-btn .btn-outline {
  color: #f1964f;
}
.custom-modal-btn .btn-outline:hover {
  color: #fff;
}
.custom-modal-btn .btn + .btn {
  margin-left: 0;
}
.custom-modal-btn .btn-text {
  font-size: 18px;
  color: #180058;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: #180058;
  padding: 0;
}
.custom-modal-btn .btn {
  font-size: 18px;
  margin-bottom: 16px;
}
.custom-modal-btn .btn-text:hover {
  text-decoration: none;
}
.custom-modal h5 {
  font-size: 18px;
  color: #180058;
  font-weight: 400;
}
.custom-modal h4 {
  font-size: 18px;
  color: #180058;
  font-weight: 700;
}
.custom-modal.custom-modal2 p {
  font-size: 14px;
}
.custom-modal2 .modal-body {
  padding: 60px;
}
ul.rating-list li.rating-item {
  width: 100%;
}
ul.rating-list {
  max-width: 315px;
  margin: 28px auto;
}
.rating-icon-box i {
  cursor: pointer;
  color: #ccc;
}
.rating-icon-box i + i {
  margin-left: 5px;
}
.rating-box .rating-text-box {
  font-size: 15px;
  color: #1d0e3d;
  margin-right: 15px;
  font-weight: 400;
}
.rating-icon-box {
  color: #ccc;
  margin-left: auto;
}
ul.rating-list li.rating-item:not(:last-child) {
  margin-bottom: 15px;
}
.fb-content {
  width: 250px;
  font-size: 15px;
  color: #1d0e3d;
}
.fb-form {
  width: calc(100% - 250px);
  padding-left: 25px;
}
.fb-form .form-check + .form-check {
  margin-left: 15px;
}
.fb-form .custom-radio .form-check-label {
  padding: 13px 15px 13px 40px;
  color: #1d0e3d;
}
.fb-form .form-check input[type='radio'] + .form-check-label::before {
  border: 1px solid #1d0e3d;
}
.feedback-box:not(:last-child) {
  margin-bottom: 18px;
}
.feedback-box-outer {
  margin-bottom: 25px;
}

/*Modal Popups End*/
.sidebar-head-right a + a {
  margin-left: 30px;
}
.sidebar-head-right > div {
  margin-right: 30px;
}

/* ================= dashboard page End ================= */
.sidebar-box {
  width: 865px;
  height: calc(100vh - 70px);
  background: #fff;
  position: fixed;
  top: 70px;
  right: -100%;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  overflow: auto;
  z-index: 999999999;
}
.sidebar-head {
  padding: 21px 35px 21px 35px;
  background: #1d0e3d;
  color: #fff;
  height: 66px;
}
.sidebar-head h5 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
}
.sidebar-body {
  overflow-y: auto;
  padding: 35px;
  height: calc(100% - 166px);
}
.sidebar-footer .btn-outline {
  color: #f1964f;
}
.sidebar-footer .btn.btn-outline:hover,
.sidebar-footer .btn.btn-outline:focus {
  color: #fff;
}
.radiobox-group .form-check:not(:last-child) {
  margin-right: 99px;
}
.check-box-group + .check-box-group {
  margin-top: 49px;
}
.check-box-group .radiobox-group + input {
  margin-top: 25px;
}
.check-box-group > label {
  font-size: 18px;
  color: #180058;
  margin-bottom: 22px;
}
.sidebar-footer {
  padding: 25px 35px;
  height: 100px;
}
.overlay4.show {
  opacity: 1;
  visibility: visible;
}
.close-img {
  cursor: pointer;
}
.sidebar-right .close-img {
  margin-left: 47px;
}
.overlay4 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgb(51 51 51 / 56%);
  z-index: 9999999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease;
}
.with-icon {
  background: rgb(88 43 184 / 15%) !important;
}
.chat-message-box > span {
  flex-grow: 1;
}
.send-message .chat-message {
  background: #e5e5e5;
  border-radius: 20px;
}
.send-message .chat-message:last-child {
  border-radius: 20px 20px 20px 0px;
}
.send-message {
  width: auto;
  border-radius: 20px 20px 20px 0px;
}
.received-message {
  width: auto;
  display: flex;
  color: #fff;
}
.received-message .chat-message {
  background: #582bb8;
  border-radius: 20px;
}
.received-message .chat-message:last-child {
  border-radius: 20px 20px 0px 20px;
}
.chat-seprator {
  overflow: hidden;
  text-align: center;
  margin: 15px 0;
}
.chat-seprator > span {
  width: auto;
  padding: 0 25px;
  position: relative;
  font-size: 13px;
}
.chat-seprator > span:before {
  content: '';
  width: 100vw;
  height: 1px;
  background: #e5e5e5;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.chat-seprator > span:after {
  content: '';
  width: 100vw;
  height: 1px;
  background: #e5e5e5;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.pannel-tab-box .nav li {
  padding: 0;
}
.chat .chat-message {
  padding: 7px 12px;
  font-size: 13px;
  max-width: 203px;
}
.chat .chat-message-box .time {
  font-size: 11px;
  color: rgb(67 66 93 / 50%);
}
.send {
  display: flex;
  padding: 35px 0 17px;
}
.input-message {
  padding: 8px 24px;
  flex-grow: 1;
  margin: 0px 8px 0px 0px;
  border-radius: 24px;
  border: none;
}
.input-send {
  background: #f1964f;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 24px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-send.paperclip {
  color: #1d0e3d;
  background: #dddbe2;
}
.input-send + .input-send {
  margin-left: 15px;
}
.height-auto.sidebar-body {
  /*height:calc(100vh - 136px)!important;padding-bottom:0!important;*/
  height: calc(100% - 50px) !important;
}
.height-auto .chats-box {
  height: 100%;
}
.height-auto .chats-box .chats-head {
  height: 49px;
}
.height-auto .chat-area {
  height: calc(100% - 125px);
}
.height-auto .chat {
  height: calc(100% - 92px);
  overflow-y: auto;
}
.send {
  height: 92px;
}
.chat {
  border-bottom: 1px solid #f4f2f7;
  padding-bottom: 15px;
  height: calc(100vh - 395px);
  overflow-y: auto;
}
#documents .tab-pane-content-box {
  height: calc(100vh - 302px);
  overflow-y: auto;
  overflow-x: hidden;
}

ul.new-profile-list li.np-item {
  background: #ffff;
  box-shadow: 0px 3px 6px #18005829;
  border-radius: 10px;
  padding: 12px 30px;
}
ul.new-profile-list li.np-item:not(:last-child) {
  margin-bottom: 35px;
}
.np-box {
  color: rgb(29 14 61 / 60%);
  font-size: 15px;
}
.np-box .np-left {
  width: calc(50% - 50px);
}
.np-box .np-mid {
  width: calc(50% - 200px);
}
.np-box .np-right {
  width: 250px;
  justify-content: flex-end;
}

.np-img-box {
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-right: 20px;
}
.np-img-box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.np-text-box h5 {
  font-size: 18px;
  color: #1d0e3d;
  line-height: 1;
  margin-bottom: 5px;
  font-weight: 700;
}
.np-right a {
  padding: 10px 16px;
  border: 1px solid #582bb8;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.np-right a + a {
  margin-left: 24px;
}
.np-mid span + span {
  margin-left: 141px;
}
.chats-box .chats-head {
  margin-bottom: 76px;
}
.doc-thumbnails {
  width: 52px;
  margin-right: 16px;
}
.doc-text-box {
  /* width: calc(100% - 52px); */
  font-size: 15px;
  color: rgb(29 14 61 / 62%);
}
.doc-icon {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  background: #dddbe2;
  text-align: center;
  line-height: 35px;
  margin-left: auto;
}
.doc-text-box h4 {
  font-size: 18px;
  color: #1d0e3d;
  margin-bottom: 0;
  font-weight: 700;
}
.latter-body h4 {
  font-size: 18px;
  font-weight: 700;
  color: #1d0e3d;
  margin-bottom: 0;
}
.lt-box {
  width: 50%;
  max-width: 145px;
  font-size: 13px;
  color: #1d0e3d;
}
.ltb-left p,
.ltb-right p {
  font-size: 15px;
  color: #1d0e3d;
  margin-bottom: 0;
}
.ltb-left,
.ltb-right {
  width: 50%;
}
.latter-text .lt-left,
.latter-text .lt-right {
  width: 50%;
}
.latter-text p,
.latter-body h3 {
  font-size: 14px;
  color: rgb(29 14 61 / 60%);
  margin-bottom: 15px;
}
.latter-body b {
  font-size: 17px;
  color: #1d0e3d;
  margin-bottom: 30px;
  display: inline-block;
}
.latter-body ul:not([class]) {
  list-style: none;
  margin-bottom: 30px;
  text-align: left;
  line-height: 22px;
}
.latter-body ul:not([class]) > li {
  padding-left: 16px;
  position: relative;
  margin-bottom: 14px;
}
.latter-body ul:not([class]) > li:last-child {
  margin-bottom: 0;
}
.latter-body ul:not([class]) > li:before {
  content: '';
  position: absolute;
  left: 2px;
  top: 10px;
  background: #180058;
  height: 5px;
  width: 5px;
  border-radius: 50%;
}
ul.lt-list li {
  width: 50%;
  margin-bottom: 20px;
}
.latter-text-one {
  margin-bottom: 30px;
}
.latter-text {
  margin-bottom: 30px;
}
.latter-logo-box {
  margin-bottom: 24px;
}
ul.lt-desc {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-bottom: 25px;
}
ul.lt-desc li {
  position: relative;
  padding: 0 15px;
}
ul.lt-desc li:before {
  content: '';
  width: 1px;
  height: calc(100% - 10px);
  background: rgb(29 14 61 / 60%);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
ul.lt-desc li:last-child:before {
  content: none;
}
.sep {
  width: 100%;
  height: 1px;
  background: #707070;
  margin: 25px 0;
}
.chat-message + .chat-message {
  margin-top: 5px;
}

/* ================= dashboard siderbar Start =============== */
.mdb-intro-box .form-check {
  transition: 0.3s all ease-in;
}
.mdb-intro-box .form-check {
  padding: 0;
  opacity: 0;
  visibility: hidden;
}
.mdb-intro-box .form-check input[type='checkbox'] + .form-check-label::before {
  border-radius: 50%;
}
.mdb-intro-group:hover .mdb-intro-box .form-check,
.mdb-intro-box .checked.form-check {
  padding-left: 35px;
  opacity: 1;
  visibility: visible;
}

/* ================= dashboard video Start =============== */
.dashboard-video {
  padding-top: 70px !important;
}
.video-box .main-content-box {
  width: calc(100% - 560px);
  height: 100%;
  padding: 0 15px;
  color: #180058;
}
.video-box .mcb-head {
  background: #fff;
  border-radius: 20px;
  padding: 12px 30px;
  height: 90px;
}
.mcb-head .di-img {
  width: 65px;
  height: 65px;
  overflow: hidden;
}
.mcb-head .di-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mcb-head .di-text-box {
  width: calc(100% - 65px);
  padding-left: 16px;
}
.di-text-box h6 {
  font-size: 18px;
  color: #180058;
  margin-bottom: 8px;
  line-height: 1;
}
.di-text-box,
ul.doc-intro-list li h3 {
  font-size: 15px;
  color: rgb(24 0 88 / 60%);
}
ul.doc-intro-list li h3 {
  line-height: 1;
  margin-bottom: 8px;
}
ul.doc-intro-list {
  width: calc(100% + 60px);
  margin-left: -30px;
}
ul.doc-intro-list li.di-item:first-child {
  width: 39%;
}
ul.doc-intro-list li.di-item {
  width: 30.5%;
  padding: 0 30px;
  position: relative;
}
ul.doc-intro-list li.di-item:not(:last-child):before {
  content: '';
  width: 1px;
  height: calc(100% - 8px);
  background: #707070;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.video-box {
  overflow: hidden;
  height: auto;
}
.video-box .mcb-body {
  /*height: calc(100% - 223px);*/
  margin: 16px 0;
  background: #000;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  min-height: 400px;
  padding-bottom: 54.6%;
}
.fullscreen-button {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: rgb(29 14 61 / 30%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 35px;
  left: 35px;
}
.fullscreen-button > img {
  width: 30px;
  margin-top: 5px;
}
.front-thumbnail-box {
  width: 175px;
  height: 124px;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #fff;
  position: absolute;
  right: 35px;
  bottom: 35px;
}
.active-share {
  position: absolute;
  right: 35px;
  bottom: 135px;
}
.doc-outer {
  width: 100%;
  position: absolute;
  bottom: 25px;
  padding: 0 25px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.front-thumbnail-box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-box .mcb-footer {
  height: 96px;
  background: #fff;
  border-radius: 20px;
  padding: 19px 25px;
  font-size: 20px;
  color: #180058;
}
.mcb-link {
  width: auto;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}
.mcb-link .img {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mcb-link + .mcb-link {
  margin-left: 50px;
}
.videochat-tabs {
  border-color: #eeeef3;
}
.videochat-tabs .nav-link b {
  font-size: 20px;
  font-weight: 700;
  color: #582bb8;
  display: none;
  margin-left: 12px;
}
.videochat-tabs .nav-link.active b {
  display: inline-block;
}
.videochat-tabs .nav-link {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 15px 15px;
}
.videochat-tabs .nav-item:first-child .nav-link {
  border-bottom-left-radius: 0;
}
.videochat-tabs .nav-item:last-child .nav-link {
  border-bottom-right-radius: 0;
}
.nav.videochat-tabs li {
  padding: 0;
  margin-bottom: -1px;
}
.videochat-tabs.nav li:not(:last-child):before {
  content: none;
}
.videochat-tabs.nav-tabs .nav-item.show .nav-link,
.videochat-tabs.nav-tabs .nav-link.active {
  background: rgb(88 43 184 / 15%);
}
.video-box .vs-box {
  width: 100%;
  height: calc(100vh - 130px);
  background: #fff;
  border-radius: 20px;
}
.vs-box .tab-content {
  padding: 20px 20px 0 20px;
  height: calc(100% - 56px);
}
.vs-box .tab-pane,
.vs-box .chat-area {
  height: 100%;
}
.vs-box .chat {
  height: calc(100% - 92px);
  overflow-y: auto;
}
.video-box .doc-image {
  width: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.video-box .doc-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-box .video-sidebar {
  width: 560px;
  height: 100%;
  padding: 0 15px;
}
.vs-box .filter-check-box .custom-radio-group .custom-radio {
  padding: 5px;
}
.vs-box .custom-radio-group .custom-control-label {
  font-size: 14px;
  padding: 3px 10px;
  height: 35px;
}
.vs-box ul.documents-list {
  overflow-x: auto;
}
.vs-box ul.documents-list li {
  width: 33.33%;
  white-space: nowrap;
}
.vs-box .tab-pane-inner {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 25px;
}
.vs-box ul.filter-check-list {
  padding-left: 8px;
}
.vs-box .fillup-btn {
  background: #f1964f;
}
.vs-box .notes-box {
  background: #f1964f27;
  margin-bottom: 20px;
}
.mcb-link.leave {
  color: #d57575 !important;
}
.mcb-link.leave .img {
  background: rgb(213 117 117 / 30%) !important;
}
.mcb-link .img {
  background: #dddbe2;
}
.fullscreen .main-content-box {
  width: 100% !important;
}
.fullscreen .mcb-body {
  margin-top: 0;
  height: calc(100% - 116px) !important;
}
.video-box.fullscreen {
  height: calc(100vh - 130px);
}
.video-box .call-end .doc-image img {
  filter: blur(44px) !important;
}
.call-end h4 {
  color: #dddbe2;
  font-size: 36px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
}
.mobile-fullscreen-call {
  height: auto;
}
.vs-box .chatbot-body .content-body,
.vs-box .chatbot-body {
  height: 100%;
}
.yark-pink-color {
  color: #d57575 !important;
}
.dodger-blue-color {
  color: #0075ff !important;
}
.light-green-color {
  color: #61cb93 !important;
}
.video-box2 .main-content-box.main-content-box2 {
  width: 50%;
}
.video-box2 .video-sidebar.video-sidebar2 {
  width: 50%;
}
.video-box2 .vs-box .tab-content {
  padding: 10px;
}
.video-box2 .mcb-body {
  height: calc(100% - 318px);
}
.video-box2 .call-end h4 {
  font-size: 29px;
  line-height: 21px;
  margin-bottom: 0;
}
.video-box2 .btn {
  min-width: 172px;
}
.fcb-img {
  width: 66px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #582bb8;
  cursor: pointer;
}
.fcb-img + .fcb-img {
  margin-left: 12px;
}
.fcb-img img {
  width: 22px;
  height: 20px;
}
.video-box2 .filter-content-box {
  margin-bottom: 0;
  padding: 17px 15px;
}
.tab-summary-content-box + .tab-summary-content-box {
  margin-top: 15px;
}
.tab-summary-content-box {
  box-shadow: 0px 3px 6px #18005829;
  border-radius: 10px;
  padding: 34px 24px 25px 24px;
}
.tab-summary-content-box .filter-content-box {
  margin-bottom: 53px !important;
}
.summary-details-box {
  font-size: 15px;
  line-height: 25px;
  color: rgba(29, 14, 61, 0.6);
  margin-bottom: 15px;
}
.sdb-left {
  width: 136px;
}
.sdb-right {
  width: calc(100% - 136px);
}
.sdb-img-box {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.sdb-desc {
  width: calc(100% - 60px);
  margin-left: 24px;
}
.sdb-img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sdb-right .sdb-desc h4 {
  font-size: 18px;
  line-height: 30px;
  color: #1d0e3d;
  margin-bottom: 0;
}
.sbd-text-box {
  font-size: 15px;
  line-height: 25px;
  color: rgba(29, 14, 61, 0.6);
  margin-bottom: 15px;
}
.sbd-text-box p {
  margin-bottom: 0;
}
.sbd-text-box h4 {
  font-size: 16px;
  line-height: 24px;
  color: #1d0e3d;
  margin-bottom: 0;
}
.sbd-text-box.advise-left {
  width: 70%;
}
.sbd-text-box.advise-right {
  width: 30%;
}
.sbd-side-advise .sbd-text-box {
  margin-bottom: 0;
}
.sbd-imro-content {
  margin-bottom: 15px;
}
.sbd-imro-content .sbd-text-box {
  margin-bottom: 0;
}
.tab-summary-content-box .block-seprator {
  background: #e6dff5;
  margin: 25px 0;
}
.scb-lower .sbd-text-box:last-child {
  margin-bottom: 0;
}
.mcbp-left {
  width: 40%;
}
.mcbp-img {
  width: 65px;
}
.mcbp-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mcbp-desc {
  width: calc(100% - 65px);
  padding-left: 16px;
}
.mcbp-desc h4 {
  font-size: 18px;
  line-height: 26px;
  color: #180058;
  margin-bottom: 0;
}
.mcbp-text span img {
  padding-left: 10px;
}
.mcbp-desc .pd-right-content strong {
  font-size: 15px;
  line-height: 22px;
}
.mcbp-right {
  position: relative;
  width: 60%;
  padding-left: 20px;
}
.mcbp-right .pdm-inner-box span,
.pc-outer .card-body p {
  font-size: 15px;
}
.mcbp-right:before {
  content: '';
  width: 1px;
  height: 100%;
  background: #1800582f;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.mcb-profile-head .patients-details-mid,
.patients-details-upper {
  margin-bottom: 0;
}
.mcb-profile-head .btn {
  border-radius: 10px;
  padding: 11px 15px;
  min-width: 172px;
  font-size: 16px;
  line-height: 25px;
}
.btn-pink,
.btn-pink:focus {
  border-color: #d57575;
  color: #fff;
  background-color: #d57575;
}
.btn-pink:hover,
.btn-pink:active {
  border-color: #d57575;
  color: #d57575;
  background-color: transparent;
}
.mcb-footer-bottom {
  background: #ffffff;
  border-radius: 20px;
  height: 84px;
  font-size: 20px;
  line-height: 26px;
  color: #180058;
}
.mcb-footer + .mcb-footer-bottom {
  margin-top: 16px;
}

/* ================= dashboard video End =============== */

/* ================= dashboard treatment Start =============== */
.placeholder-img {
  width: 100%;
  height: 100%;
}
.placeholder-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.custom-accordion {
  width: 100%;
  background-color: #fff;
  margin: 0px !important;
  color: #484848;
}
.custom-accordion img {
  width: 100%;
  height: 100%;
}
.custom-accordion .ac-label {
  padding: 17px 130px 17px 30px;
  position: relative;
  display: block;
  height: auto;
  cursor: pointer;
  box-shadow: 0px 3px 6px #18005829;
  border-radius: 10px;
  color: #1d0e3d;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 0;
  border: 1px solid transparent;
  border-bottom: 0;
}
.custom-accordion input:checked ~ label.ac-label {
  border-color: #18005833;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #582bb826;
}
.custom-accordion input:checked ~ label.ac-label .ac-label-img {
  background: #fafbfb;
}
.ca-pannel:not(:last-child) {
  margin-bottom: 25px;
}
.custom-accordion .ac-label:hover {
}
.custom-accordion input:checked + .ac-label,
.custom-accordion input:checked + .ac-label:active {
}
.custom-accordion input.ac-input {
  display: none;
}
.custom-accordion .article {
  padding: 15px 30px;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  font-size: 20px;
  display: none;
  color: #1d0e3d;
}
.custom-accordion .article p {
  line-height: 23px;
  padding-left: 5px;
}
.custom-accordion .article p strong {
  color: #f1964f;
}
.custom-accordion .article h6 {
  font-size: 18px;
  color: #1d0e3d;
  margin-bottom: 10px;
  font-weight: 700;
}
.custom-accordion input:checked ~ .article i {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.custom-accordion input:checked ~ .article.ac-content {
  height: auto;
  border: 1px solid #18005833;
  display: block;
  border-top: 0;
}
.custom-accordion i {
  position: absolute;
  transform: translate(-30px, 0);
  right: 0;
  top: 50%;
}
.custom-accordion input:checked ~ .ac-label i:before {
  transform: translate(2px, 0) rotate(-45deg);
}
.custom-accordion input:checked ~ .ac-label i:after {
  transform: translate(-2px, 0) rotate(45deg);
}
.custom-accordion i:before,
.custom-accordion i:after {
  content: '';
  position: absolute;
  background-color: #716b7d;
  width: 3px;
  height: 9px;
}
.custom-accordion i:before {
  transform: translate(-2px, 0) rotate(-45deg);
}
.custom-accordion i:after {
  transform: translate(2px, 0) rotate(45deg);
}
.custom-accordion ul.ac-list {
  padding-left: 40px;
  list-style-type: disc;
}
.ac-label-img {
  width: 55px;
  height: 55px;
  margin-right: 25px;
  background: #e6dff5;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ac-label-img img {
  width: 28px;
  height: auto;
}
.ac-label-text {
  font-size: 15px;
  color: #1d0e3d;
}
.ac-label-text h3 {
  font-size: 18px;
  color: #1d0e3d;
  font-weight: 700;
  margin-bottom: 3px;
}
.ac-label-right p {
  color: rgb(29 14 61 / 60%);
  margin-bottom: 0;
}
.ac-label-right h4 {
  font-size: 15px;
  margin-bottom: 0;
}
ul.number-list {
  margin-bottom: 24px;
}
ul.number-list li:not(:last-child) {
  margin-bottom: 15px;
}
.article p b {
  font-weight: 700;
  color: #1d0e3d;
}
ul.doc-info-list li:not(:last-child) {
  margin-bottom: 25px;
}
.doc-info-box {
  width: 100%;
  padding: 45px 60px;
  box-shadow: 0px 3px 6px #18005829;
  border-radius: 10px;
  overflow: hidden;
}
.custom-btn {
  width: 66px;
  height: 48px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #582bb8;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-btn + .custom-btn {
  margin-left: 12px;
}
.doc-info-upper {
  border-bottom: 1px solid #1800582f;
  padding-bottom: 25px;
}
.mid .block,
.block h5 {
  font-size: 18px;
  color: #1d0e3d;
  margin-bottom: 0;
}
.block .img-block {
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-right: 25px;
}
.block .img-block > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.block .text-block h3 {
  font-size: 18px;
  color: #1d0e3d;
  margin-bottom: 3px;
  font-weight: 700;
}
.block .text-block {
  font-size: 15px;
  color: rgb(29 14 61 / 60%);
  width: calc(100% - 72px);
}
.block h6 {
  font-size: 15px;
  font-weight: 400;
  color: rgb(29 14 61 / 60%);
  margin-bottom: 3px;
}
.block.side-effects h6 {
  color: #d57575;
}
.block.green strong {
  color: #61cb93;
}
.block.blue strong {
  color: #0075ff;
}
.doc-info-lower {
  padding-top: 25px;
}
.doc-info-upper .left > span {
  display: block;
}
.doc-info-upper .left {
  font-size: 15px;
  color: rgb(29 14 61 / 60%);
}
.mid .block + .block {
  margin-left: 25px;
}
.doc-info-lower .mid .block {
  padding-left: 80px;
}
.doc-info-lower .left,
.doc-info-upper .left {
  width: 11%;
}
.doc-info-lower .mid,
.doc-info-upper .mid {
  width: 51%;
}
.doc-info-lower .right,
.doc-info-upper .right {
  width: 38%;
}
.doc-info-upper .mid .block {
  width: 50%;
}
.doc-info-upper .right .block {
  width: 65%;
}
.doc-info-upper .right .block + .block {
  margin-left: 25px;
}
.doc-info-upper .right .block.side-effects {
  width: auto;
}
.doc-info-upper .right,
.doc-info-lower .right {
  padding-left: 25px;
}
.treatment {
  height: calc(100vh - 130px);
}
.treatment .chatbot-box {
  height: calc(100% - 52px);
}
.treatment .chatbot-body {
  height: calc(100% - 59px);
  overflow-y: auto;
}

/* ================= dashboard treatment End =============== */

/* ================= dashboard siderbar Start =============== */
.sidebar-pannel {
  width: 511px;
  height: calc(100vh - 70px);
  background: #fff;
  position: fixed;
  top: 70px;
  right: -511px;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  overflow: auto;
  z-index: 999999999;
}
body.dashboard.show-siderbar .sidebar-pannel {
  right: 0;
}
body.dashboard.show-siderbar .overlay3 {
  opacity: 1;
  visibility: visible;
}
body.dashboard-video.show-siderbar .sidebar-pannel {
  right: 0;
}
body.dashboard-video.show-siderbar .overlay3 {
  opacity: 1;
  visibility: visible;
}
.sp-head {
  padding: 22px 40px 22px 30px;
  background: #1d0e3d;
  color: #fff;
}
.sp-head h4 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
}
.close-icon {
  font-size: 18px;
  cursor: pointer;
}
.sp-body {
  padding: 40px 40px 40px 30px;
}
.sp-doc-box {
  padding: 18px 28px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  position: relative;
  font-size: 20px;
  color: #180058;
  margin-bottom: 24px;
}
.sp-doc-box:before {
  content: '';
  width: calc(100% - 135px);
  height: 5px;
  background: #70d09d;
  border-radius: 12px;
  position: absolute;
  left: 28px;
  bottom: 0;
}
.sp-doc-box > img {
  margin-right: 25px;
}
.sp-doc-box + h4 {
  margin-bottom: 16px;
  font-size: 18px;
  color: #180058;
  font-weight: 400;
}
ul.tags li {
  width: 25%;
  padding: 7px;
}
ul.tags .tag {
  width: 100%;
  background: #e6dff5;
  border-radius: 4px;
  font-size: 14px;
  color: #1d0e3d;
  padding: 8px;
}
ul.tags .active .tag {
  background: #582bb8;
  color: #fff;
}
.sb-upload-btn {
  margin-top: 37px;
}
.overlay3 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgb(51 51 51 / 56%);
  z-index: 9999999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease;
}
.tpc-report ul.documents-list li {
  width: 20%;
  padding: 15px;
}
.tpc-report .document-box h4 {
  font-size: 14px;
}
.tpc-report .tpc-month-box h6 {
  display: inline-block;
  font-size: 18px;
}
.filter-text-box h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 700;
  text-align: center;
  color: #43425d;
}
.filter-check-box .custom-control-label {
  position: relative;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1d0e3d;
  vertical-align: top;
  width: 100%;
  height: 100%;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  padding: 6px 12px;
  cursor: pointer;
}
.filter-content-box {
  margin-bottom: 32px;
}
ul.filter-check-list .form-group {
  margin-bottom: 0px;
  position: relative;
}
.filter-check-box .custom-radio-group {
  border: 0;
  border-radius: 4px;
  margin: 0 10px;
}
.filter-check-box .custom-control {
  padding: 0;
  min-width: 59px;
  text-align: center;
  min-height: 35px;
}
ul.filter-check-list {
  padding-left: 19px;
}
.filter-check-box .custom-radio-group .custom-radio {
  padding: 10px;
}
.t-p .slb-icons-group {
  display: none;
}
.slb-icons-group .slb-icon {
  float: left;
}
.slb-icons-group .dropright {
  float: left;
}

/* ================= dashboard siderbar End ================= */
.welcome-box {
  width: 100%;
  min-height: 100vh;
}
.wb-content-box {
  width: 100%;
  max-width: 366px;
  height: 100%;
  max-height: 358px;
  background: #582bb8;
  padding: 61px;
  border-radius: 16px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
}
.wb-icon {
  width: 108px;
  margin: 0 auto 30px;
}
.wb-content-box h3 {
  font-size: 21px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 21px;
}
.menu-toggle {
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
.one,
.two,
.three {
  width: 100%;
  height: 3px;
  background: #582bb8;
  margin: 6px auto;
  backface-visibility: hidden;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.menu-toggle.on .one {
  -moz-transform: rotate(45deg) translate(10px, 8px);
  -ms-transform: rotate(45deg) translate(10px, 8px);
  -webkit-transform: rotate(45deg) translate(10px, 8px);
  transform: rotate(45deg) translate(10px, 8px);
  margin-top: 0;
}
.menu-toggle.on .two {
  opacity: 0;
}
.menu-toggle.on .three {
  -moz-transform: rotate(-45deg) translate(5px, -4px);
  -ms-transform: rotate(-45deg) translate(5px, -4px);
  -webkit-transform: rotate(-45deg) translate(5px, -4px);
  transform: rotate(-45deg) translate(5px, -4px);
  margin-bottom: 0;
}

/* ================= Header Start ================= */
.navbar-brand {
  margin: 0;
  width: 135px;
}
.header-home-btn > a {
  font-size: 13px;
  line-height: 21px;
}
.header-home-btn a > svg {
  margin-right: 5px;
  fill: #333;
}
.header-home-btn a:hover > svg {
  fill: #621ac0;
}
.header-right {
  font-size: 14px;
  text-transform: capitalize;
}
.header-right .search-box {
  width: auto;
  margin: 0 40px 0 30px;
  min-width: 343px;
  border-color: #fff;
  padding-left: 15px;
  padding-right: 30px;
}
.header-right .search-box .search-input {
  height: 43px;
  letter-spacing: 0.3px;
}
.header-right .search-box .search-input::placeholder {
  color: #26262a;
}
.header-right .search-box:before {
  width: 24px;
  height: 24px;
  right: 15px;
}
.filter-menu.dropdown {
  position: static;
}
.filter-menu .dropdown-menu {
  padding: 0;
  min-width: 100%;
  border: 0;
  border-radius: 0;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
}
.filter-menu {
  margin: 0;
}
.filter-menu > svg {
  margin-right: 10px;
}
.filter-box {
  position: relative;
  cursor: pointer;
}
.filter-box:before {
  content: '';
  width: 0%;
  height: 2px;
  background: #621ac0;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  opacity: 0;
  transition: 0.3s all ease-in;
}
.filter-menu-icon svg {
  fill: #000;
  margin-right: 10px;
}
.show .filter-menu-icon svg,
.filter-box:hover .filter-menu-icon svg,
.filter-box:hover .close-menu-icon svg {
  fill: #621ac0;
}
.close-menu-icon svg {
  fill: #333;
}
.show .filter-box,
.filter-box:hover {
  color: #621ac0;
}
.show .filter-box:before {
  width: 100%;
  opacity: 1;
}
.fdm-upper {
  padding: 25px 0 50px;
}
.fdm-upper-content {
  position: relative;
}
.fdm-upper-content h3 {
  font-size: 20px;
  font-weight: 400;
  color: #333;
  margin-bottom: 28px;
}
.fdm-upper h4 {
  font-size: 20px;
  color: #333;
  letter-spacing: 0.54px;
  margin-bottom: 30px;
  text-transform: capitalize;
}
.filter-dropdown-menu .filter-menu-item {
  width: 16.66%;
  padding: 0 15px;
}
.filter-menu-box + .filter-menu-box {
  margin-top: 55px;
}
.filter-menu-box h6 {
  font-size: 16px;
  margin-bottom: 18px;
}
.filter-menu-box .form-check + .form-check {
  margin-top: 18px;
}
.filter-menu-box .form-check {
  padding-left: 30px;
}
.fdm-lower {
  border-top: 1px solid #e0e0e0;
  padding: 35px 0 38px;
}
.location-menu {
  cursor: pointer;
  position: relative;
  line-height: 110px;
}
.location-menu .dropdown-item {
  line-height: 1.5;
}
.location-menu .dropdown-menu {
  border: 0;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
}
.location-menu > svg {
  fill: #000;
  margin-right: 6px;
}
.location-menu span + svg {
  fill: #808080;
  margin: 0 0 0 8px;
}
.fdm-lower .btn {
  min-width: 150px; /*border-radius:15px;*/
  padding: 11px 15px;
}
.fdm-lower .btn.btn-cancel {
  min-width: 100px;
}
.header-logo-area {
  position: relative;
  padding-left: 0;
}
.menu-toggler {
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.avtar-admin-icon {
  margin-left: 30px;
}
.btn-cancel {
  color: #621ac0;
  background: transparent;
}
.btn-cancel:hover,
.btn-cancel:focus,
.btn-cancel:active {
  color: #621ac0;
  text-decoration: underline;
}

/* ================= Header End ================= */
.dairy-sidebar::placeholder {
  color: #a9a8c1 !important;
  font-size: 17px;
  font-weight: 400;
}
.dairy-sidebar h5 {
  font-size: 22px;
  font-weight: 700;
  color: #180058;
  margin: 24px 0 12px;
}
.dairy-sidebar p {
  font-size: 18px;
  font-weight: 400;
  color: #180058;
}
.dairy-sidebar .check-box-group > label {
  margin-bottom: 12px !important;
}
.border-box {
  border: 1px solid #cccccc;
  border-radius: 8px;
}
.border-box p {
  font-size: 17px;
  font-weight: 700;
  color: #180058;
  padding: 15px;
  letter-spacing: 0px;
}
.my-diary-box .mdb-head {
  margin-bottom: 25px;
}
.my-diary-box .mdb-head h4 {
  font-size: 24px;
  font-weight: 600;
  color: #1d0e3d;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
}
.my-profile-box h4 {
  font-size: 24px;
  font-weight: 600;
  color: #1d0e3d;
  margin-bottom: 15px;
  font-family: 'Montserrat', sans-serif;
}
.my-diary-box .mdb-icon-group .mdb-icon {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #582bb8;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-diary-box .mdb-icon-group .mdb-icon + .mdb-icon {
  margin-left: 30px;
}
.my-diary-box .mdb-right-content .mdb-btn {
  margin-left: 30px;
}
.my-diary-box .mdb-icon.trash {
  border-color: #d57575;
}
.my-diary-box .mdb-body {
  width: 100%;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}
.mdb-sidebar-head .form-group {
  margin-right: 8px;
  margin-bottom: 0;
  width: calc(50% - 8px);
}
.mdb-sidebar-head .form-group:last-child {
  margin-right: 0;
}
.my-diary-box .mdb-sidebar {
  padding: 20px;
  width: 38%;
}
.my-diary-box .mdb-content {
  padding: 0;
  width: 62%;
  border-left: 1px solid #e5e5e5;
}
.mdb-sidebar-body h6 {
  font-size: 15px;
  color: #180058;
  margin-bottom: 0;
  font-weight: 400;
  margin: 11px 0 10px;
}
.mdb-intro-group {
  border: 1px solid #70707033;
  border-radius: 15px;
}
.mdb-intro-box {
  padding: 15px 13px;
  font-size: 14px;
  color: #180058;
  position: relative;
  border-radius: 15px;
}
.mdb-intro-box h5 {
  font-size: 19px;
  color: #180058;
  font-weight: 500;
  margin-bottom: 6px;
  line-height: 1;
}
.mdb-sidebar-body {
  padding: 30px 0;
}
.mdb-content-head {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.mdb-content-head h2 {
  font-size: 28px;
  margin-bottom: 0;
  font-weight: 700;
  margin-right: 19px;
}
.mdb-content-body {
  padding: 20px;
}
.mdb-content-right b {
  font-size: 16px;
  color: rgb(67 66 93 / 40%);
  font-weight: 700;
  display: inline-block;
  margin-right: 35px;
}
.mdb-content-body {
  font-size: 18px;
  color: #180058;
}
.mdb-content-body h5 {
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
}
.mdb-content-body p {
  margin-bottom: 12px;
}
.mdb-text-box {
  background: #e6dff5;
  border-radius: 7.5px;
  padding: 15px;
  font-size: 17px;
  margin-bottom: 20px;
}
.md-head {
  margin-bottom: 20px;
}
.md-head h4 {
  font-size: 24px;
  line-height: 53px;
  letter-spacing: 0px;
  color: #1d0e3d;
  margin-bottom: 0;
}
.md-upper .custom-input label:not(.error) {
  font-size: 14px;
  line-height: 30px;
  color: #333;
}
.mdh-right a + a {
  margin-left: 30px;
}
.mdh-btn a.btn.btn-lg {
  min-width: 267px;
  height: 50px;
}
.mdh-icon {
  width: auto;
  margin-right: 30px;
}
.mdh-share {
  width: 50px;
  height: 50px;
  border: 1px solid #582bb8;
  border-radius: 10px;
  padding: 10px;
}
.mdh-delete {
  border: 1px solid #d57575;
  border-radius: 10px;
  padding: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.md-body {
  padding: 27px;
  background: #ffffff;
  border-radius: 20px;
}
.md-left {
  width: 301px;
  border-right: 1px solid #e5e5e5;
  padding-right: 20px;
}
.md-right {
  width: calc(100% - 301px);
}
ul.md-list .custom-select {
  height: 35px;
  border-radius: 8px;
  font-size: 14px;
  color: #180058;
}
ul.md-list .form-control {
  height: 35px;
  font-size: 14px;
}
ul.md-list .custom-input label:not(.error) {
  position: absolute;
  top: 6px;
  left: 16px;
}
ul.md-list li.md-item {
  width: 50%;
  padding-right: 15px;
}
ul.md-list li.md-item:last-child {
  padding-right: 0px;
}
.md-lower {
  letter-spacing: 0px;
  color: #180058;
}
ul.diary-list p {
  font-size: 15px;
  line-height: 13px;
  margin-bottom: 15px;
}
ul.diary-list li.diary-item {
  position: relative;
  padding: 15px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #70707033;
  border-radius: 15px;
  cursor: pointer;
}
ul.diary-list li.diary-item .diary-desc p {
  font-size: 19px;
  line-height: 13px;
  margin-bottom: 5px;
}
ul.diary-list li.diary-item .diary-desc small {
  font-size: 14px;
  line-height: 25px;
  opacity: 0.5;
  margin-bottom: 5px;
}
ul.diary-list li.diary-item.active {
  background: #582bb827;
}
ul.diary-list li.diary-item:hover {
  display: flex;
  align-items: center;
}
ul.diary-list li.diary-item .form-check {
  display: none;
}
ul.diary-list li.diary-item:hover .form-check {
  display: block;
}
li.diary-item .form-check input[type='checkbox'] + .form-check-label::before {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}
li.diary-item .form-check input[type='checkbox'] + .form-check-label::after {
  left: -2px;
  top: -2px;
}
.mdr-head {
  border-bottom: 1px solid #e5e5e5;
  padding: 0 0 20px 20px;
}
.mdr-head .mdr-heading h4 {
  font-size: 28px;
  line-height: 13px;
  letter-spacing: 0px;
  color: #1d0e3d;
  margin-bottom: 0;
}
.mdr-date .dropright {
  padding-left: 35px;
}
.mdr-heading a {
  padding-left: 15px;
}
.mdr-body {
  padding: 20px 0 20px 20px;
}
.mdr-content + .mdr-content {
  margin-top: 25px;
}
.mdr-content h5 {
  font-size: 22px;
  line-height: 13px;
  letter-spacing: 0;
  color: #1d0e3d;
  font-weight: 600;
}
.mdr-content p {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  color: #180058;
  margin-bottom: 5px;
}
.mdr-content-box {
  background: #e6dff5;
  padding: 17px 17px 18px 15px;
  border-radius: 8px;
}
.mdr-content-box small {
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0;
  color: #1d0e3d;
}
.mdr-date .dropdown-menu {
  min-width: 120px !important;
}
.mdr-date .dropdown-custom .dropdown-item {
  text-align: center;
  padding: 5px 0;
}
.service-container {
  padding: 30px 0;
}
.service-head {
  margin-bottom: 40px;
}
.service-head h2 {
  font-size: 26px;
  color: #333;
  font-weight: 400;
  margin-bottom: 0;
  letter-spacing: 0.57px;
}
.sb-left {
  width: 350px;
}
.sb-right {
  width: calc(100% - 350px);
  padding-left: 40px;
}
.sb-right .card {
  border-radius: 0;
  border: 0;
}
.card-box {
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 8px 20px rgb(98 27 192 / 20%);
}
.card-box + .card-box {
  margin-top: 40px;
}
.cb-upper {
  padding: 30px 30px 30px 30px;
  width: 100%;
  position: relative;
}
.cb-lower {
  padding: 10px 55px 10px 20px;
  border-top: 1px solid #f2f2f2;
}
.btn-text {
  font-size: 12px;
  color: #621ac0;
  font-weight: 500;
  padding: 4px 15px;
}
.cb-upper-content {
  width: calc(100% - 150px);
}
.cb-img-box {
  width: 120px;
  height: 120px;
  border-radius: 5px;
  overflow: hidden;
}
.cb-text-box {
  width: calc(100% - 120px);
  padding: 10px 15px 15px 15px;
}
.cb-text-box h4 {
  font-size: 16px;
  font-weight: 500;
  color: #621ac0;
  margin-bottom: 5px;
  letter-spacing: -0.2px;
}
.cb-experience-box {
  font-size: 13px;
  color: #828282;
  margin-bottom: 5px;
}
.cb-location-box {
  font-size: 12px;
  font-weight: 300;
}
.cb-location-box span + span {
  margin-left: 3px;
}
.cb-btn {
  width: 150px;
  padding-bottom: 5px;
}
.cb-btn .btn {
  min-width: 100%;
  padding: 12px 15px;
}
.cb-btn .btn + .btn {
  margin: 12px 0 0 0;
}
.card-box .doc-intro-desc h5 {
  font-size: 20px;
  margin-bottom: 10px;
}
.card-box .doc-intro-desc h6 {
  font-size: 13px;
  margin-bottom: 7px;
  line-height: 16px;
}
.card-box .doc-intro-desc .doc-loc-name {
  font-size: 14px;
  color: #26262a;
}
.card-box .doc-intro-desc .location-pin-icon {
  width: 12px;
  margin-right: 5px;
}
.card-box .doc-intro-desc .doc-fee-desc {
  font-size: 14px;
  line-height: 20px;
  margin-top: 15px;
  white-space: nowrap;
}
.card-box .doc-intro-desc .doc-fee-desc > div {
  margin-top: 0;
  margin-bottom: 5px;
}
.card-box .cb-text-box {
  padding: 0 15px 0 25px;
}
ul.dot-list li {
  position: relative;
  padding: 0 16px;
  font-weight: 300;
  color: #828282;
  font-size: 12px;
}
ul.dot-list li:before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #c4c4c4;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
ul.status-list {
  width: calc(100% + 16px);
  margin-left: -8px;
}
ul.status-list li {
  position: relative;
  padding: 0 8px;
  font-weight: 300;
  color: #c5c5c5;
  font-size: 14px;
  letter-spacing: -0.3px;
}
ul.status-list li:not(:last-child):before {
  content: '';
  width: 1px;
  height: calc(100% - 10px);
  background: #c5c5c5;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
ul.status-list li b {
  font-weight: 700;
  color: #26262a;
}
.vertical-nav.nav-pills .nav-link.active {
  background: transparent;
  color: inherit;
}
.vertical-nav.nav-pills .nav-link {
  border-radius: 0;
  padding: 0;
}
.vertical-nav.nav-pills .nav-link + .nav-link {
  margin-top: 35px;
}
.cb-experience-box img {
  width: 15px;
  margin-right: 5px;
}
.pills-content-box {
  width: 100%;
  padding-right: 15px;
  position: relative;
  color: #828282;
}
.pills-content-box:before {
  content: '';
  width: 15px;
  height: 10px;
  background: url(../images/arrow-right.svg) no-repeat center/contain;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease-in;
}
.pc-img-box {
  width: 120px;
  height: 120px;
  margin-right: 20px;
  border-radius: 12px;
  background: #f9f9f9;
  text-align: center;
  line-height: 120px;
}
.pc-text-box {
  width: calc(100% - 140px);
  font-size: 14px;
  letter-spacing: 0.3px;
}
.pc-text-box h3 {
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}
.active .pc-text-box h3,
.pills-content-box:hover .pc-text-box h3 {
  color: #621ac0;
}
.active .pills-content-box:before,
.pills-content-box:hover:before {
  right: 0;
  opacity: 1;
  visibility: visible;
}
.sorting-box {
  width: 155px;
}
.sorting-box .nice-select {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  letter-spacing: 0.54px;
  padding-left: 10px;
  padding-right: 25px;
}
.sorting-box .nice-select:after {
  right: 10px;
}
.side-pannel-container,
.online-visit-sidebar,
.tab-sidebar {
  width: 100%;
  max-width: 730px;
  position: fixed;
  right: -730px;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 99999;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
}
.toggled-tab .tab-sidebar,
.toggled-ov .online-visit-sidebar,
.toggled .side-pannel-container {
  right: 0;
}
.toggled-tab .overlay .tab-sidebar,
.toggled-tab .overlay .side-pannel-container,
.toggled-ov .overlay .tab-sidebar {
  right: 0;
  transition: 1s all ease;
}
.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgb(51 51 51 / 80%);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease;
}
.toggled .overlay,
.toggled-ov .overlay,
.toggled-tab .overlay {
  opacity: 1;
  visibility: visible;
}
.side-pannel-box {
  width: 100%;
  height: 100%;
  padding: 30px 30px 30px 30px;
  overflow: auto;
  position: relative;
}
.pi-img-box {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  overflow: hidden;
}
.pi-text-box {
  width: calc(100% - 100px);
  padding: 15px 15px 15px 15px;
  position: relative;
  color: #828282;
  padding-left: 15px;
}
.pi-text-box h4 {
  font-size: 16px;
  font-weight: 500;
  color: #621ac0;
  margin-bottom: 15px;
}
.pi-exp {
  font-size: 13px;
  letter-spacing: 0.54px;
}
.pi-location {
  font-size: 12px;
  font-weight: 300;
}
.pi-fee-box {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
ul.fee-list li > b {
  font-weight: 700;
  color: #26262a;
}
.separator {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #e0e0e0;
  margin: 29px 0;
}
.pi-body {
  color: #828282;
  font-size: 13px;
  padding: 0 0 30px 0;
}
.pi-body a {
  font-weight: 500;
  color: #621ac0;
}
.copy-link-text {
  color: #bdbdbd !important;
}
.copy-content {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 0;
}
.pannel-tab-box .nav-tabs {
  border: 1px solid #e0e0e0;
  border-bottom: 0 !important;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  max-width: 370px;
}
.pannel-tab-box .nav-tabs .nav-link {
  font-size: 16px;
  line-height: 21px;
  color: #621ac0;
  letter-spacing: 0.54px;
  border-radius: 0;
  border: 0;
  padding: 19px 15px;
  text-align: center;
}
.nav-tabs .nav-item {
  margin-bottom: 0;
  flex-grow: 1;
  width: auto;
}
.pannel-tab-box .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #621ac0;
}
.pannel-tab-box .tab-content {
  border: 1px solid #e0e0e0;
  border-radius: 0 20px 20px 20px;
  padding: 30px 15px;
}
.pane-content-box:not(:last-child) {
  margin-bottom: 25px;
}
.pane-content-box h4 {
  font-size: 13px;
  letter-spacing: 0.54px;
  font-weight: 500;
  color: #333;
  margin-bottom: 15px;
}
.today .data-card-box,
.today .ts-box {
  background: #621ac0;
  color: #fff;
}
.today .data-card-box h5 {
  color: #fff;
}
.data-card-box h5 {
  font-size: 13px;
  margin-bottom: 0;
  font-weight: 500;
  color: #bdbdbd;
}
.data-card-box {
  font-size: 11px;
  line-height: 1.2;
  color: #bdbdbd;
  border: 1px solid #621ac0;
  border-radius: 5px;
  overflow: hidden;
  padding: 5px 15px;
  text-align: center;
}
.data-card-box p {
  margin-bottom: 5px;
}
ul.data-card-list {
  width: calc(100% + 10px);
  margin-left: -5px;
  padding-right: 40px;
}
ul.data-card-list .data-card-item {
  padding: 0 5px;
}
ul.data-card-list.slick-slider .slick-track,
ul.data-card-list.slick-slider .slick-list {
  padding-right: 65px;
}
ul.data-card-list.slick-slider {
  padding-bottom: 0;
}
ul.tile-slot-list {
  width: calc(100% + 10px);
  margin-left: -5px;
}
ul.tile-slot-list .ts-item {
  padding: 5px;
  width: 25%;
}
.ts-box {
  padding: 10px 15px;
  font-size: 11px;
  line-height: 1.2;
  text-align: center;
  font-weight: 400;
  color: #621ac0;
  border: 1px solid #621ac0;
  border-radius: 5px;
  overflow: hidden;
}
.ts-box span {
  white-space: nowrap;
}
.overlay2 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgb(51 51 51 / 80%);
  z-index: 9999;
  transition: 0.3s all ease;
}
.final-sidebar-container {
  width: 100%;
  max-width: 550px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 99999;
  transition: 1s all ease;
}
.final-sidebar-container h6 {
  font-size: 20px;
  color: #26262a;
  margin-bottom: 0;
}
.pi-body .pi-left {
  flex-grow: 1;
}
.pi-body .pi-right {
  flex-grow: 1;
}
.pi-left h3,
.pi-right h3 {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.54px;
  color: #828282;
}
.pi-left p,
.pi-right p {
  font-size: 16px;
  color: #333333;
  letter-spacing: 0.54px;
}
.side-tab .nav {
  flex-flow: nowrap;
}
ul.fee-list li b span {
  font-size: 13px;
  padding-right: 3px;
}
.pannel-top {
  padding-bottom: 5px;
  text-align: right;
}
.pannel-top img {
  width: 18px;
}
.pane-btn .btn {
  min-width: 220px;
}
ul.fee-list li {
  text-align: left;
}
.tab-sidebar-top {
  margin-bottom: 30px;
}
.tab-sidebar-top h4 {
  margin-bottom: 10px;
  font-size: 26px;
}
.tab-sidebar-top .icon-img {
  width: 28px;
  margin-right: 20px;
}
.tab-sidebar-top .icon-text {
  width: calc(100% - 30px);
}
.pannel-top .close {
  width: 24px;
  float: none;
}
.tab-sidebar-top .pi-location {
  font-size: 13px;
  font-weight: 600;
}
.tab-sidebar-top .pi-location span + span {
  margin-left: 5px;
}

/* ================= tab-sidebar css end ================= */
.gtd-icon {
  width: 24px;
  margin-right: 10px;
}
.gtd-text-box {
  font-size: 13px;
  color: #621ac0;
  line-height: 21px;
  letter-spacing: 0.54px;
}
.card-top {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 18px;
}
.card-top h3 {
  font-size: 18px;
  letter-spacing: 0.57px;
  color: #333;
  margin-bottom: 0;
  margin-right: 15px;
}
.ct-desc-box {
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.54px;
  color: #828282;
}
.custom-radio-group .custom-radio input[type='radio'] {
  display: none;
}
.custom-radio-group
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label {
  background: #621ac0;
  color: #fff;
}
.custom-radio-group
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label
  h4 {
  color: #fff;
}
.custom-radio-group .custom-radio .custom-control-label::before {
  content: none;
}
.custom-radio-group .custom-radio .custom-control-label::after {
  content: none;
}
.custom-radio-group .custom-control {
  padding: 0;
  text-align: center;
}
.custom-radio-group .custom-control-label {
  position: relative;
  margin-bottom: 0;
  text-transform: capitalize;
  width: 100%;
  font-size: 11px;
  font-weight: 400;
  padding: 3px 16px;
  cursor: pointer;
  height: 45px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  color: #26262a;
  border: 1px solid #621ac0;
  border-radius: 5px;
}
.custom-control-label p {
  margin-bottom: 0;
  line-height: 1.4;
}
.custom-radio-group .custom-control-label h4 {
  font-size: 13px;
  font-weight: 500;
  display: block;
  margin-bottom: 0;
  color: #26262a;
}
.custom-radio-group .custom-radio {
  padding: 5px;
}
.slot-radio-group.custom-radio-group .custom-radio {
  width: 25%;
}
.date-slot-parent.custom-radio-group {
  flex-wrap: wrap;
}
.date-slot-parent.custom-radio-group .custom-radio {
  width: 92.5px;
  flex-grow: unset;
}
.tab-content .custom-radio-group {
  width: calc(100% + 10px);
  margin-left: -5px !important;
}
.content-block {
  padding: 60px 0;
}
.cb-left h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 56px;
  font-weight: 900;
  color: #621bc0;
  margin-bottom: 0;
}
.cb-left span {
  font-size: 126px;
  font-weight: 900;
}
.cb-item {
  width: 33.33%;
  padding: 0 15px;
}
.cb-box {
  position: relative;
}
.cb-box:before {
  content: '';
  width: 100%;
  height: calc(100% - 50px);
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: auto;
  background: #f68e3f;
  border-radius: 10px;
}
.cb-img {
  position: relative;
  z-index: 1;
}
.cb-box img {
  height: auto;
  max-width: 100%;
}
ul.cb-list {
  max-width: 922px;
  margin-left: auto;
}
.content-container {
  padding: 60px 0;
}
.ic-left,
.ic-right {
  width: 50%;
  font-family: 'Montserrat', sans-serif;
}
.ic-left {
  padding-right: 12%;
}
.ic-left h4 {
  font-size: 64px;
  font-weight: 700;
  color: #621bc0;
}
.ic-left h4 span {
  color: #26262a;
}
.ic-left p {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
}
.ic-btn {
  padding-top: 20px;
}

/* ================= Footer start ================= */
footer {
  font-family: 'Montserrat';
}
footer a:hover {
  color: #f68e3f;
}
.footer-upper {
  padding: 45px 0 27px;
  background: #f7f7f7;
}
.footer-logo-area {
  padding-left: 35px;
}
.footer-upper-content {
  margin-left: 15%;
  padding-top: 15px;
}
ul.footer-nav {
  margin-bottom: 63px;
}
ul.footer-nav li {
  padding: 0 15px;
}
.footer-nav-link {
  font-size: 18px;
  font-weight: 700;
  color: #621bc0;
  padding: 8px 30px;
  min-width: 159px;
  background: rgb(255 255 255 / 39%);
  box-shadow: 6px 9px 6px rgb(0 0 0 / 15%);
  border-radius: 10px;
  text-align: center;
}
.footer-nav-link:focus {
  color: #621bc0;
  box-shadow: none;
}
.footer-nav-link:hover {
  color: #f68e3f;
}
.fuc-box:first-child,
ul.footer-nav li:first-child {
  width: 40%;
}
.fuc-box:nth-child(2),
ul.footer-nav li:nth-child(2) {
  width: 36.5%;
}
.fuc-box:last-child,
ul.footer-nav li:last-child {
  width: 23.5%;
}
.fuc-box {
  font-size: 20px;
  color: #26262a;
  padding: 0 15px;
}
.fuc-icon {
  margin-bottom: 27px;
  height: 32px;
  display: flex;
  align-items: flex-end;
}
.fuc-box h4 {
  font-size: 20px;
  color: #26262a;
  font-weight: 600;
  margin-bottom: 18px;
}
.fuc-box a {
  word-break: break-all;
}
ul.social-link {
  margin-top: -10px;
}
ul.social-link li:not(:last-child) {
  margin-right: 15px;
}
.social-link li:hover svg {
  fill: #f68e3f;
}
.footer-lower {
  background: #432a7f;
  padding: 17px 0; /* line-height:35px; */
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}
.footer-lower p > img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.footer-lower p.copyright {
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
ul.fl-nav li {
  position: relative;
  padding: 0 15px;
}
ul.fl-nav li:not(:last-child):before {
  content: '';
  width: 1px;
  height: calc(100% - 8px);
  background: #fff;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
/* ================= footer end ================= */

/* ================= New Header Start ================= */
.fixed .header {
  box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
}
.header {
  color: #26262a;
  background: #f7f7f7;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 0;
  font-family: 'Montserrat';
  z-index: 999;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}
.header-upper {
  font-size: 14px;
  border-bottom: 1px solid #fff;
  height: 55px;
  background: #432a7f;
}
ul.query-list li {
  float: left;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  position: relative;
  padding: 0 7px;
}
ul.query-list li > img {
  width: 16px;
  margin-right: 8px;
}
ul.query-list li a:hover {
  color: #f68e3f;
}
ul.query-list li:not(:last-child):before {
  content: '';
  width: 1px;
  height: calc(100% - 6px);
  background: #939393;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
ul.query-list li:first-child {
  padding-left: 0;
}
.header-upper-right {
  color: #fff;
  padding-right: 5px;
}
.header-upper-right b {
  font-weight: 700;
}
.header-lower .navbar {
  height: 103px;
}
.header-lower .navbar-brand {
  width: 157px;
}
.header-lower .navbar-expand-xl .navbar-nav .nav-item {
  margin: 0 26px;
}
.header-lower .navbar-expand-xl .navbar-nav .nav-link {
  font-size: 16px;
  line-height: 70px;
  color: #26262a;
  padding: 0;
  margin: 0;
}
.header-lower .navbar-expand-xl .navbar-nav .nav-item:hover .nav-link,
.header-lower .navbar-expand-xl .navbar-nav .nav-item.active .nav-link {
  color: #f68e3f;
}
.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  display: none;
}
.nav-inside {
  width: 100%; /*padding:0 16px;*/
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header-lower .header-right {
  margin-left: 97px;
}
.nav-inside-content {
  padding: 15px 0;
}
.header-btn {
  background: #f68e3f;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  min-width: 250px;
  padding: 19px 15px;
  border-radius: 11px;
  font-family: 'Open Sans', sans-serif;
}
.header-btn:hover,
.header-btn:focus {
  color: #f68e3f;
  border-color: #f68e3f;
  background: transparent;
}

/*.navbar-toggler{width:45px;height:45px;border-radius:0;cursor:pointer;text-decoration:none;padding:0;border:0;background:transparent;margin:0;position:absolute;top:0;right:0;}*/
.navbar-default .navbar-toggler:hover,
.navbar-default .navbar-toggler:focus {
  background: transparent;
}
.navbar-toggler:not([class='collapsed']),
.navbar-toggler:not([class='collapsed']):focus,
.navbar-toggler:not([class='collapsed']):hover {
  background: transparent;
}
.navbar-toggler.collapsed,
.navbar-toggler.collapsed:focus,
.navbar-toggler.collapsed:hover {
  background: transparent;
}
.navbar-toggler .icon-bar {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 4px;
  color: #243238;
  text-indent: -55px;
  margin-top: 0;
  background: transparent !important;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  vertical-align: middle;
}
.navbar-toggler .icon-bar:before,
.navbar-toggler .icon-bar:after {
  content: '';
  width: 22px;
  height: 4px;
  background: #005983;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-out;
}
.navbar-toggler.collapsed .icon-bar {
  background: #005983 !important;
}
.navbar-toggler.collapsed .icon-bar:before,
.navbar-toggler.collapsed .icon-bar:after {
  background: #005983;
}
.navbar-toggler.collapsed .icon-bar:before {
  top: -8px;
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
}
.navbar-toggler .icon-bar:before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}
.navbar-toggler.collapsed .icon-bar:after {
  bottom: -8px;
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
}
.navbar-toggler .icon-bar:after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}
.outer-container {
  padding-top: 158px;
}

/* ================= New Header End ================= */

/* ================= Home Start ================= */
.intro-text-box {
  padding: 20px 10% 15px 0;
}
.intro-text-box p {
  font-size: 24px;
  line-height: 32px;
  color: #26262a;
  padding-right: 35px;
}
.intro-text-box h3 {
  font-size: 64px;
  line-height: 1.3;
  color: #26262a;
  margin-bottom: 30px;
  font-weight: 700;
  font-family: 'Montserrat';
}
.intro-text-box h3 > span {
  display: block;
  color: #621bc0;
}
.intro-img-box {
  max-width: 688px;
  margin-left: auto;
}
.intro-img {
  margin-bottom: 32px;
}
.intro-btn-group {
  padding-right: 50px;
}
.intro-btn-group h6 {
  font-size: 20px;
  line-height: 27px;
  color: #26262a;
  margin-bottom: 0;
  margin-left: 43px;
}
.heading {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 45px;
}
.heading h4 {
  font-size: 64px;
  font-weight: 700;
  color: #26262a;
  margin-bottom: 0;
  font-family: 'Montserrat';
  text-transform: capitalize;
}
.heading h5 {
  font-size: 46px;
  font-weight: 700;
  color: #26262a;
  margin-bottom: 0;
  font-family: 'Montserrat';
}
.heading h4 > span,
.heading h5 > span {
  color: #621bc0;
}
ul.experts-list {
  width: calc(100% + 60px);
  margin-left: -30px;
}
ul.experts-list li.expert-item {
  width: 20%;
  padding: 29px 15px 0;
}
.expert-box {
  font-family: 'Montserrat';
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 19px;
  color: #26262a;
}
.expert-img {
  width: 100%;
  height: 230px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 25px;
}
.expert-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.expert-box h4 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #26262a;
  font-family: 'Open Sans', sans-serif;
}
.viewmore-btn .btn {
  padding: 9px 15px;
}
.viewmore-btn {
  margin-top: 30px;
}
ul.grid-list {
  min-height: 417px;
  width: calc(100% + 60px);
  margin-left: -30px;
}
ul.grid-list li.grid-item {
  width: 33.33%;
  padding: 15px;
}
ul.grid-list .grid-box {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
}
ul.grid-list .grid-img {
  height: 100%;
  width: 100%;
}
ul.grid-list .grid-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.grid-disc {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  background: -moz-linear-gradient(
    top,
    rgba(38, 38, 42, 0.67) 0%,
    rgba(38, 38, 42, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(38, 38, 42, 0.67) 0%,
    rgba(38, 38, 42, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(38, 38, 42, 0.67) 0%,
    rgba(38, 38, 42, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ab26262a',endColorstr='#26262a',GradientType=0);
  padding: 70px 34px;
  min-width: 165px;
}
.grid-disc h6 {
  margin-bottom: 0;
  font-size: 14px;
  font-family: 'Montserrat';
  position: relative;
  padding-left: 15px;
  color: #fff;
  line-height: 1.4;
}
.grid-disc h6:before {
  content: '';
  height: 40px;
  width: 4px;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
}
.content-container.home-block-container {
  padding: 125px 0 75px;
}
ul.block-list {
  width: calc(100% + 60px);
  margin-left: -30px;
  min-height: 575px;
}
ul.block-list li.block-item {
  width: 50%;
  padding: 25px 30px;
}
.block-bg {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  padding: 100px 58px 90px;
  position: relative;
}
.block-text-box h4 {
  font-size: 44px;
  line-height: 1.3;
  font-family: 'Montserrat';
  font-weight: 700;
  margin-bottom: 0;
}
.block-text-box {
  width: 100%;
  max-width: 425px;
  margin-bottom: 30px;
}
.block-box p {
  font-size: 24px;
  line-height: 37px;
}
.block-box {
  height: 100%;
  width: 100%;
  box-shadow: 5px 5px 10px rgb(0, 0, 0, 16%);
  border-radius: 10px;
  overflow: hidden;
  color: #fff;
}
.block-bg:before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: -moz-linear-gradient(
    top,
    rgba(56, 56, 56, 0.03) 0%,
    rgba(0, 0, 0, 0.87) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(56, 56, 56, 0.03) 0%,
    rgba(0, 0, 0, 0.87) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(56, 56, 56, 0.03) 0%,
    rgba(0, 0, 0, 0.87) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08383838',endColorstr='#de000000',GradientType=0);
}
.block-text {
  position: relative;
}
.content-container.home-service-container {
  padding: 100px 0;
}
ul.home-services-list {
  width: calc(100% + 60px);
  margin-left: -30px;
}
ul.home-services-list li.hsl-item {
  width: 25%;
  padding: 30px;
}
.hsl-box {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.2);
}
.hsl-bg {
  width: 100%;
  height: 100%;
}
.hsl-bg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hsl-content {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
  font-family: 'Montserrat';
  color: #fff;
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 25px 48px 25px;
}
.home-service-slider .slick-prev {
  left: -9%;
}
.home-service-slider .slick-next {
  right: -9%;
}
.border-radius {
  border-radius: 10px !important;
}
.content-container.media-container {
  padding: 105px 0;
}
.media-content-box {
  max-width: 850px;
  margin: 0 auto;
}
.media-box {
  width: 100%;
  position: relative;
}
.media-box .play-btn {
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.query-intro-box {
  position: relative;
  padding-left: 65px;
  padding-top: 32px;
}
.query-intro-box:before {
  content: '';
  width: 55px;
  height: 112px;
  background-image: url(../images/quote-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 45px;
}
.query-intro-box h3 {
  font-size: 56px;
  line-height: 1.1;
  font-weight: 900;
  font-family: 'Montserrat';
  color: #621bc0;
  margin-bottom: 34px;
}
.query-intro-box h3 > span {
  display: block;
  font-size: 126px;
}
.query-btn {
  max-width: 250px;
  text-align: center;
}
.query-btn .btn {
  margin-bottom: 30px;
}
ul.helping-member-list li.hm-item {
  flex-grow: 1;
  max-width: 286px;
  padding: 0;
}
ul.helping-member-list li.hm-item + li.hm-item {
  margin-left: 32px;
}
.helping-member-list.nav-tabs {
  border-bottom: 0;
}
.helping-member-list.nav-tabs .nav-link {
  padding: 0;
  border: 0;
}
.add-box-shadow {
  margin-bottom: 20px;
  box-shadow: 0 10px 8px rgb(0 0 0 / 26%);
}
.hm-box {
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #26262b;
  text-align: center;
}
.hm-img {
  position: relative;
  width: 100%;
  height: 302px;
  max-width: 300px;
  margin: 0 auto 27px;
  border-radius: 10px;
  overflow: hidden;
}
.hm-img:before {
  content: '';
  width: 100%;
  height: calc(100% - 57px);
  background: #f68e3f;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px 10px 0 0;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2);
  z-index: 0;
}
.hm-img > img {
  position: relative;
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
.hm-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 17px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.member-detail-head {
  padding: 28px 0;
  background: #621bc0;
  position: relative;
}
.member-detail-head h6 {
  font-size: 28px;
  color: #fff;
  margin-bottom: 0;
  font-weight: 700;
  font-family: 'Montserrat';
}
.md-close-icon {
  width: 19px;
  height: 27px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.member-detail-body {
  padding: 63px 0 78px;
  background: #fff8f1;
}
.mdb-content {
  padding-left: 55px;
}
ul.new-dot-list li {
  font-size: 24px;
  color: #26262a;
  padding: 0 24px;
  font-weight: 400;
  position: relative;
}
ul.new-dot-list li:not(:last-child) {
  margin-bottom: 15px;
}
ul.new-dot-list li:before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #26262a;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.content-container.block-intro-container {
  padding: 60px 0 170px;
}
.intro-textarea-content {
  width: 100%;
  max-width: 789px;
  box-shadow: 0 10px 15px rgb(98 27 192 / 16%);
}
.intro-textarea-content textarea.form-control {
  padding: 43px 38px;
  font-size: 24px;
  color: #6c6c6c;
  border-color: #621bc0;
  height: 359px;
}
.intro-textarea-content textarea.form-control::placeholder {
  color: #6c6c6c;
}
.block-intro-container .intro-text-box h3 {
  margin-bottom: 20px;
}
.new-btn-group {
  margin-top: 47px;
}
.new-btn-group .btn {
  min-width: 150px;
  padding: 11px 15px;
}
.new-btn-group .btn + .btn {
  margin-left: 53px;
}
.hero-container {
  width: 100%;
  height: 100vh;
  max-height: 800px;
  color: #fff;
  background: #917bc4;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-content {
  padding-left: 13%;
  padding-top: 250px;
  padding-bottom: 75px;
  padding-right: 12%;
}
.hero-content h3 {
  font-size: 74px;
  font-weight: 600;
  color: #f68e3f;
  font-family: 'Montserrat';
  padding-bottom: 23px;
  display: inline-block;
  position: absolute;
  top: 0;
  margin-left: -34%;
  margin-bottom: 0;
}
.hero-content h3 > span {
  display: block;
  color: #fff;
}
.hero-content h3:before {
  content: '';
  width: calc(100% - 105px);
  height: 3px;
  background: #fff;
  position: absolute;
  right: 23px;
  top: 100%;
}
.hero-content p {
  font-family: 'Montserrat';
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 40px;
}
.hero-btn {
  background: #432a7f;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  padding: 11px 45px;
  box-shadow: 6px 6px 8px rgb(32 10 99 / 36%);
}
.hero-btn:hover {
  color: #fff;
  box-shadow: none;
}
.add-blue-shape {
  position: relative;
}
.add-blue-shape:before {
  content: '';
  width: 100%;
  max-width: 911px;
  height: 664px;
  background-image: url(../images/002.png);
  position: absolute;
  left: -190px;
  top: -190px;
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.add-orange-shape {
  position: relative;
}
.add-orange-shape:before {
  content: '';
  width: 100%;
  max-width: 911px;
  height: 664px;
  background-image: url(../images/001.png);
  position: absolute;
  left: -190px;
  top: -190px;
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 768px) {
  .pr-head .nav-tabs {
    display: none; 
  }
}
@media (min-width: 768px) {
  .chatbot-body .card .card-header,
  .member-detail-container.card .card-header,
  .tab-content-box .card .card-header {
    display: none;
  }
  .member-detail-container.card .collapse {
    display: block;
  }
}
.member-detail-container.card {
  border: 0;
  box-shadow: none;
}
.navbar-inside .nav-item:last-child {
  margin-right: 0 !important;
}
.right-content {
  margin-left: 25px;
  font-size: 16px;
}
.dashboard-icon {
  width: 21px;
  margin-right: 10px;
}
.right-content a:hover {
  outline: none;
  color: #fff;
}

/* ================= Home End ================= */

/* ================= doctor’s profile page Start ================= */
.content-container.doctor-profile-container {
  padding-bottom: 225px;
}
.doc-img img {
  width: 100%;
}
.doc-img {
  max-width: 465px;
}
.doc-intro-box {
  padding: 0 25px;
}
.location-pin-icon {
  width: 16px;
  margin-right: 10px;
}
.doc-intro-desc h5 {
  font-size: 32px;
  color: #26262a;
  font-weight: 600;
}
.doc-intro-desc h6 {
  color: #26262a;
  font-size: 19px;
  margin-bottom: 20px;
}
ul.stats-list li h6 {
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #26262a;
  margin-bottom: 0;
  text-transform: uppercase;
}
ul.stats-list li p {
  font-size: 71px;
  color: #621bc0;
  font-weight: 800;
  line-height: 79px;
}
ul.stats-list li:not(:last-child) {
  margin-bottom: 55px;
}
.doc-fee-desc {
  color: #26262a;
  font-weight: 700;
}
.button-group .btn + .btn {
  margin-left: 0;
  margin-top: 20px;
}
ul.fee-list li {
  position: relative;
  padding: 0 8px;
}
ul.fee-list li:not(:last-child):before {
  content: '';
  width: 2px;
  height: calc(100% - 8px);
  background: #26262a;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.doc-fee-desc > div {
  margin: 20px 0;
}
.registration-box {
  margin-top: 30px;
  font-size: 20px;
  color: #432a7f;
  font-weight: 500;
}
.registration-box > img {
  width: 23px;
  margin-right: 15px;
}
.profile-card-container {
  background: #f6f4ff;
}
.profile-card-content {
  width: calc(100% + 60px);
  margin-left: -30px;
  transform: translateY(-170px);
  font-size: 24px;
  color: #26262a;
  font-weight: 400;
}
.profile-card-content h5 {
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  color: #432a7f;
  margin-bottom: 0;
  font-family: 'Montserrat';
}
.profile-card-content h6 {
  font-size: 24px;
  color: #26262a;
  font-weight: 600;
}
.profile-card-content p {
  font-size: 20px;
}
.profile-card-content .pc-content-left,
.profile-card-content .pc-content-right {
  width: 50%;
  padding: 0 30px;
}
.profile-card-box {
  width: 100%;
  background: #fff;
  box-shadow: 1px 8px 20px rgb(98 27 192 / 20%);
}
.profile-card-box a:hover {
  text-decoration: underline;
}
.profile-lower a:hover {
  color: #fff;
}
.profile-card-box + .profile-card-box {
  margin-top: 50px;
}
.pcb-head {
  padding: 30px 42px 24px;
}
.pcb-body {
  position: relative;
}
.profile-upper {
  border-bottom: 1px solid #d2d2d2;
  padding: 24px 95px 130px 42px;
}
.profile-upper h5,
.profile-mid h5 {
  margin-bottom: 45px;
}
.profile-upper p {
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 0;
}
.profile-mid {
  padding: 45px 42px 60px;
}
.profile-mid p {
  font-size: 24px;
}
.profile-lower {
  background: #621bc0;
  color: #fff;
  border-radius: 0 0 10px 10px;
  padding: 24px 50px 24px 34px;
}
.profile-lower h4 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 700;
}
.profile-lower .pl-copy-link {
  font-size: 16px;
  margin-left: 25px;
}
ul.pcb-query-list {
  padding-bottom: 15px;
}
ul.pcb-query-list li {
  width: 100%;
  display: flex;
}
ul.pcb-query-list li {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.pcb-query-icon {
  width: 25px;
  margin-right: 10px;
}
.pcb-query-text {
  width: calc(100% - 25px);
}
.pcb-query-text h6 {
  margin-bottom: 0;
}
.pcb-query-text h6 + p {
  margin-top: 18px;
}
.pcb-query-text h6 > a {
  color: #f68e3f;
  padding-left: 15px;
}
.pcb-query-text p {
  font-size: 24px;
  line-height: 1.3;
}
.profile-card-content ul:not([class]) {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.profile-card-content ul:not([class]) li {
  position: relative;
  padding: 24px 42px;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 20px;
}
.profile-card-content ul:not([class]) li:last-child {
  border-bottom: 0;
}
ul.pcb-query-list li {
  padding: 23px 42px 35px;
}
ul.pcb-query-list li:not(:last-child) {
  border-bottom: 1px solid #d2d2d2;
}
ul.award-list li {
  padding: 15px 42px 30px;
  display: flex;
  flex-wrap: wrap;
}
ul.award-list li:not(:last-child) {
  border-bottom: 1px solid #d2d2d2;
}
.award-img-box {
  width: 70px;
}
.award-text-desc {
  width: calc(100% - 70px);
  padding-left: 35px;
}
.showall-btn {
  position: absolute;
  right: 38px;
  bottom: 30px;
  font-size: 18px;
  color: #079fff;
}

/* ================= doctor’s profile page End ================= */

/* ================= Modal css ================= */
.form-popup .modal-content {
  padding: 45px 92px 50px 86px;
  background: #fafafa;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.16);
  min-height: 456px;
}
.form-content {
  width: 100%;
  padding: 0;
}
.fc-left {
  width: 310px;
  padding-right: 15%;
  padding-top: 40px;
}
.fc-right {
  width: calc(100% - 310px);
}
.form-popup .modal-dialog {
  max-width: 913px;
  padding: 15px;
}
.fc-left h4 {
  font-family: 'Montserrat';
  font-size: 34px;
  font-weight: 700;
  color: #621bc0;
  line-height: 49px;
}
.fc-right .form-control {
  background: transparent;
  letter-spacing: -0.1px;
  height: 20px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #777777;
  font-size: 12px;
  font-weight: 300;
  padding: 0 0 5px 0px;
}
.fc-right .form-control:focus {
  box-shadow: none;
  border-color: #621ac0;
}
.fc-right .form-group {
  margin-bottom: 38px;
  position: relative;
  z-index: 1;
}
.fc-right label {
  font-size: 20px;
  color: #26262a;
}
.fc-right h5 {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Montserrat';
  margin-bottom: 35px;
}
.fc-right .btn {
  padding: 10px 15px;
  min-width: 150px;
  font-size: 18px;
  font-weight: 600;
}
.form-popup .close {
  position: absolute;
  right: 26px;
  top: 18px;
  padding: 0;
  margin: 0;
}
.form-popup .modal-body {
  padding: 0;
  padding-top: 0px;
}
.popup-content h3 {
  font-size: 27px;
  font-weight: 500;
  line-height: 36px;
  font-family: 'Montserrat';
}
.popup-content img {
  margin-bottom: 75px;
}
.popup-content {
  width: 100%;
  max-width: 500px;
  margin: auto;
}
.form-popup.video-popup .modal-content {
  padding: 0;
  background: transparent;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 75px rgb(0, 0, 0, 0.57);
}
.fc-right .label-placeholder {
  position: absolute;
  top: -5px;
  transition: all 200ms;
  z-index: -1;
}
.fc-right .form-control:focus + .label-placeholder {
  font-size: 16px;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
}
.fc-right .form-control:focus + .label-placeholder,
.fc-right .form-control:valid + .label-placeholder {
  font-size: 16px;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
}
ul.query-list li:last-child {
  padding-right: 0;
}
a.header-right-box img {
  margin-right: 8px;
  width: 16px;
}
.appointment-btn .btn {
  min-width: 300px;
}
.gradient-bg {
  background: rgb(140, 86, 213);
  background: -moz-linear-gradient(
    left,
    rgba(140, 86, 213, 1) 0%,
    rgba(78, 12, 167, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(140, 86, 213, 1) 0%,
    rgba(78, 12, 167, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(140, 86, 213, 1) 0%,
    rgba(78, 12, 167, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8c56d5',endColorstr='#4e0ca7',GradientType=1);
}
ul.new-service-list .new-service-item {
  width: 14.28%;
  padding: 0 10px;
  height: 100%;
}
ul.new-service-list li .new-service-box p {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  line-height: 27px;
}
ul.new-service-list li .new-service-box .new-sb-img {
  width: 121px;
  margin: 0 auto 35px;
}
ul.new-service-list li .new-service-box img {
  height: 100%;
  width: 100%;
}
.block-container .block-left,
.block-container .block-right {
  width: 50%;
}
.block-container .block-right {
  padding: 100px 62px;
  position: relative;
  z-index: 2;
}
.gray-bg {
  background: #f6f4ff;
}
.reverse {
  flex-direction: row-reverse;
}
ul.bl-list.check-icon-list {
  width: calc(100% + 64px);
  margin-left: -32px;
}
.reverse ul.bl-list {
  width: 100%;
  margin-left: 0;
}
ul.bl-list {
  width: calc(100% + 64px);
  margin-left: -32px;
}
ul.bl-list.check-icon-list .bl-item {
  padding: 0 32px;
}
ul.bl-list .bl-item {
  width: 50%;
  padding: 0 32px;
  margin-bottom: 80px;
}
ul.bl-list.reverse .bl-item {
  padding: 0 45px 0 48px;
}
.reverse ul.bl-list .bl-item {
  padding: 0 10px;
}
.bl-icon {
  width: 110px;
  background: #432a7f;
  padding: 27px 15px;
  border-radius: 10px;
  position: relative;
}
.bl-icon img {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}
.bl-item h6 {
  font-size: 22px;
  font-weight: 600;
  width: calc(100% - 110px);
  padding-left: 30px;
  margin-bottom: 0;
  text-transform: uppercase;
}
ul.bl-list .bl-item:nth-last-of-type(-n + 2) {
  margin-bottom: 0;
}
.bl-shape {
  position: absolute;
  top: 20px;
  right: 22px;
  height: 20px;
  width: 20px;
  background: #f68e3f;
  border-radius: 50%;
  z-index: 1;
}
.block-container .block-right:before {
  border-radius: 15px;
  content: '';
  height: 100%;
  width: calc(100% + 65px);
  margin-left: -65px;
  position: absolute;
  top: 0;
  left: 0;
  background: #f6f4ff;
  z-index: -1;
}
.block-left {
  position: relative;
  z-index: 3;
}
.h-line {
  width: auto;
  flex-grow: 1;
  margin-left: 10px;
  height: 1px;
  background: #c3c3c3;
}
.heading2 h5 {
  margin-bottom: 0;
}
.block-content-box {
  padding: 40px 0;
}
.block-content-box.bg-box .block-right:before {
  background: #fef4ec;
}
.block-content-box.bg-box .bl-icon {
  background: #917bc4;
}
.block-content-box.bg-box .bl-shape {
  right: auto;
}
.reverse .block-right:before {
  margin-left: 0;
}
.block-content-box .block-right h5 {
  font-size: 46px;
  font-weight: 700;
  line-height: 60px;
  font-family: 'Montserrat';
  margin-bottom: 30px;
}
.block-content-box .block-right p {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 37px;
}
.heading2 {
  margin: 100px 0 60px;
}
.block-content-outer h5 {
  font-size: 34px;
  font-family: 'Montserrat';
  line-height: 60px;
}
.block-content-box .bl-box {
  padding: 32px 40px 40px 20px;
  box-shadow: 6px 3px 19px rgba(222, 214, 255, 0.79);
  background: #fff;
  border-radius: 10px;
  width: 100%;
}
.bl-img-box {
  height: 100%;
  width: 100%;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 16%);
  border-radius: 10px;
  overflow: hidden;
  color: #fff;
}
.bl-img-box .block-text-box h4 {
  font-size: 24px;
}
.bl-box h3 {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  width: calc(100% - 83px);
  padding-left: 30px;
}
.bl-box .bl-img {
  width: 83px;
}
.bl-img img {
  height: 100%;
  width: 100%;
}
ul.new-service-list {
  width: calc(100% + 60px);
  margin-left: -30px;
  height: 100%;
}
.block-right .btn {
  min-width: 250px;
}
.separeter {
  width: 100%;
  background: #f68e3f;
  height: 1px;
  display: block;
  margin: 5px 0;
}
.bl-img-box .block-bg {
  background-position: center;
}
.bl-img-box .block-bg {
  min-height: 340px;
  padding: 35px;
}
ul.founder-list {
  padding: 73px 0 0 0;
}
ul.founder-list li.founder-item {
  width: 33.33%;
  padding: 0 32px;
}
ul.founder-list .founder-box {
  font-family: 'Montserrat';
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 19px;
  color: #26262a;
}
ul.founder-list .founder-img {
  width: 100%;
  height: 304px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 26px;
}
ul.founder-list .founder-box h4 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #26262a;
  font-family: 'Open Sans', sans-serif;
}
ul.founder-list .founder-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.block-box-bg {
  background: #fff8f1;
  width: 100%;
  border-radius: 10px;
}
.block-box-content {
  padding: 40px 0;
  width: 100%;
  max-width: 748px;
  margin: 0 auto;
}
.block-box-content h3 {
  font-size: 32px;
  font-weight: 500;
  font-family: 'Montserrat';
  line-height: 42px;
  margin-bottom: 20px;
}
.block-box-content p {
  font-size: 22px;
  line-height: 34px;
}
.content-container.block-box-container {
  padding: 60px 0 45px 0;
}
.intro-content h4 {
  color: #f68e3f;
  font-size: 148px;
  font-weight: 700;
  margin-bottom: 0;
  font-family: 'Montserrat';
  line-height: 120px;
}
.intro-content h4 span {
  font-size: 100px;
  line-height: 180px;
}
.blockquote-box {
  max-width: 553px;
  margin-left: auto;
  position: relative;
  padding: 70px 40px 85px 90px;
}
.blockquote-box h3 {
  font-size: 46px;
  font-weight: 700;
  color: #432a7f;
  line-height: 60px;
  margin-bottom: 5px;
  font-family: 'Montserrat';
}
.blockquote-box p {
  font-size: 24px;
  color: #917bc4;
  line-height: 34px;
}
.blockquote-box:before {
  content: '';
  width: 110px;
  height: 142px;
  background-image: url(../images/quote-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  left: -20px;
  top: 35px;
}
.blockquote-box:after {
  content: '';
  width: 86px;
  height: 120px;
  background-image: url(../images/quote-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  right: 100px;
  bottom: 26px;
  transform: rotate(180deg);
}
.heading4 h5 {
  font-size: 64px;
  font-weight: 700;
  font-family: 'Montserrat';
}
.heading4 {
  margin-bottom: 30px;
}
.heading4 h5 span {
  color: #621bc0;
  font-weight: 400;
  position: relative;
  letter-spacing: 1px;
  padding-left: 25px;
  font-family: 'Montserrat';
  margin-left: 8px;
}
.heading4 h5 span:before {
  content: '';
  height: calc(100% - 18px);
  width: 4px;
  position: absolute;
  margin-top: 6px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background: #621bc0;
}
.intro-block h4 {
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 3px;
  letter-spacing: 3px;
}
.intro-block p {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 35px;
}
.intro-block {
  padding-right: 16%;
}
.intro-block-heading h4 {
  margin-bottom: 0;
  font-size: 66px;
  font-weight: 500;
  color: #917bc4;
}
.intro-block-heading h4 span {
  font-weight: 700;
  color: #f68e3f;
}
.intro-block-img {
  width: 436px;
}
.intro-block-heading {
  width: calc(100% - 436px);
  padding-left: 37px;
}
.intro-block .know-more-link {
  font-size: 20px;
  font-weight: 600;
  color: #f68e3f;
  text-align: left;
  padding: 0;
  letter-spacing: -0.9px;
}
.intro-block a.know-more-link img {
  margin-left: 15px;
}
.intro-heading-block {
  margin-top: -73px;
  padding-left: 28px;
}
.intro-block-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.ib-head {
  background: #9a81cd;
  padding: 28px 0;
}
.ib-head h5 {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  font-family: 'Montserrat';
  margin-bottom: 0;
}
.ib-body {
  background: #fff8f1;
  padding: 98px 0;
  overflow: hidden;
  font-size: 24px;
  line-height: 34px;
}
.pb-img {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.pb-img:before {
  content: '';
  width: 100vw;
  height: 5px;
  background: #000;
  position: absolute;
  bottom: 17.52px;
  right: 0;
  left: 100%;
}
.ib-head h5 + span {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  font-family: 'Montserrat';
  margin-bottom: 0;
}
.ib-head h5 + span {
  position: relative;
  margin-left: 15px;
  padding-left: 15px;
}
.ib-head h5 + span:before {
  content: '';
  height: 100%;
  width: 4px;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
}
.ib-body p {
  margin-bottom: 35px;
}
.info-block-pannel.open-pannel {
  display: block !important;
}
.pb-img img {
  height: 100%;
  width: 100%;
}
.inner-hero-container {
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgb(61, 0, 142);
  background: -moz-linear-gradient(
    left,
    rgba(61, 0, 142, 1) 0%,
    rgba(162, 141, 211, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(61, 0, 142, 1) 0%,
    rgba(162, 141, 211, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(61, 0, 142, 1) 0%,
    rgba(162, 141, 211, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3d008e',endColorstr='#a28dd3',GradientTypes=1);
  margin-bottom: 60px;
}
.inner-hero-content-box {
  padding: 145px 0 148px;
  position: relative;
}
.inner-hero-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0px;
  margin-left: 80px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 1;
}
.inner-hero-content-left {
  width: 63%;
  font-weight: 700;
  margin-top: -200px;
  padding-left: 99px;
  position: relative;
}
.inner-hero-content-left h1 {
  font-size: 87px;
  font-weight: 700;
  font-family: 'Montserrat';
  margin-bottom: 0;
}
.inner-hero-content-left span {
  font-size: 87px;
  line-height: 1;
  font-weight: 700;
  font-family: 'Montserrat';
  position: relative;
  padding-bottom: 10px;
  margin-left: 100px;
  flex-grow: 1;
}
.inner-hero-content-left span:before {
  content: '';
  width: 100%;
  height: 4px;
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 15px;
}
.inner-hero-content-right {
  width: 37%;
  position: relative;
  z-index: 998;
}
.inner-hero-content {
  max-width: 491px;
  padding-left: 40px;
  padding-top: 75px;
  letter-spacing: 0.5px;
}
.inner-hero-content p {
  font-size: 24px;
  line-height: 36px;
  font-family: 'Montserrat';
}
.inner-hero-content .btn {
  min-width: 250px;
}
.inner-hero-content a.btn.banner-btn {
  background: #fff;
  color: #432a7f;
  font-size: 20px;
  font-weight: 600;
}
.inner-hero-content .btn.banner-btn:hover,
.inner-hero-content .btn.banner-btn:focus {
  background: #432a7f;
  color: #fff;
}
.add-new-shape:before {
  content: '';
  background-image: url(../images/inner-banner2.png);
  background-position: center;
  background-repeat: repeat-x;
  background-size: cover;
  position: absolute;
  bottom: 0;
  right: 100%;
  width: 100vw;
  z-index: 1;
  height: 108px;
}
.inner-hero-bg img {
  margin-left: -10px;
}

/* ================= Modal css ================= */

/* ================= CLINICIAN START ================= */
.clinician-hero-container {
  background: #8c56d5 url(../images/psychologist-hero-bg.jpg) no-repeat
    center/cover;
  color: #fff;
  padding-top: 52px;
}
.clinician-hero-img {
  display: block;
  max-width: 560px;
  margin-left: 75px;
  position: relative;
}
.clinician-dot {
  position: absolute;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 50%;
}
.clinician-dot > span {
  position: absolute;
  bottom: -3px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
}
.clinician-dot.dot1 {
  bottom: 46.1%;
  left: 4.5%;
}
.clinician-dot.dot1 > span {
  right: 33px;
}
.clinician-dot.dot2 {
  bottom: 85%;
  left: 24.1%;
}
.clinician-dot.dot2 > span {
  right: 32px;
  bottom: 5px;
}
.clinician-dot.dot3 {
  bottom: 85%;
  right: 25%;
}
.clinician-dot.dot3 > span {
  left: 31px;
  bottom: 5px;
}
.clinician-dot.dot4 {
  bottom: 46.1%;
  right: 4.5%;
}
.clinician-dot.dot4 > span {
  left: 34px;
}
.clinician-dot.active > span {
  font-size: 38px;
}
.clinician-dot.active > span {
  bottom: 5px;
}
.clinician-hero-content {
  padding: 30px 0 65px;
}
.clinician-hero-content h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 23px;
  line-height: 36px;
  margin-bottom: 30px;
}
.clinician-hero-content p {
  font-family: 'Montserrat', sans-serif;
  font-size: 23px;
  line-height: 36px;
  margin-bottom: 30px;
}
.clinician-dot.dot-center {
  top: -2%;
  left: 49%;
}
.clinician-dot.dot-center > span {
  bottom: 21px;
  left: 50%;
  transform: translateX(-50%);
}
.prowess-text-box p {
  font-size: 24px;
  line-height: 32px;
  color: #26262a;
  padding-right: 35px;
}
.prowess-text-box h3 {
  font-size: 64px;
  line-height: 1.3;
  color: #26262a;
  margin-bottom: 30px;
  font-weight: 700;
  font-family: 'Montserrat';
}
.prowess-text-box h3 > span {
  display: block;
  color: #621bc0;
}
ul.prowess-list {
  padding-left: 30px;
}
.prowess-item {
  flex-basis: 50%;
  max-width: 50%;
  padding: 50px 20px;
}
.prowess-icon {
  width: 75px;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
  position: relative;
}
.prowess-item h6 {
  font-size: 20px;
  line-height: 34px;
  font-weight: 600;
  width: calc(100% - 75px);
  padding-left: 35px;
  margin-bottom: 0;
}
.accordion.pc-outer {
  padding: 0 6%;
}
.pc-outer > .card {
  border: 0;
  border-radius: 10px !important;
  background: #f6f4ff;
  color: #505050;
  margin-bottom: 25px;
}
.pc-outer > .card > .card-header {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: #505050;
  padding: 22px 60px;
  position: relative;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.pc-outer > .card > .card-header:after {
  content: '';
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 11px solid #26262a;
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  transition: all 0.2s ease;
}
.pc-outer > .card > .card-header:not(.collapsed):after {
  transform: translateY(-50%) rotate(180deg);
}
.pc-outer .card-body {
  padding: 15px 19% 60px 60px;
  font-size: 24px;
  line-height: 34px;
}
.accordion-link-box {
  padding: 12px 60px;
}
.accordion-link-box > a {
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  color: #505050;
}
.accordion-link-box > a > i {
  margin-left: 15px;
  font-size: 21px;
  vertical-align: text-bottom;
  transition: all 0.2s ease;
}
.accordion-link-box > a:hover > i {
  margin-left: 12px;
}
.pc-outer > .card.card-true-false > .card-header {
  cursor: auto;
  padding-right: 150px;
}
.pc-outer > .card.card-true-false > .card-header::after {
  display: none;
}
.pc-outer > .card.card-true-false > .card-header > span {
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  text-transform: uppercase;
  color: #000;
}
.cta-container {
  background: #432a7f;
  color: #fff;
  padding: 50px 0 30px;
}
.cta-content h3 {
  font-family: 'Montserrat';
  font-size: 60px;
  line-height: 70px;
  font-weight: 900;
  margin-bottom: 40px;
}
.cta-btn {
  padding: 0 35px;
}
.cta-btn > span {
  margin-top: 10px;
  font-size: 19px;
  line-height: 30px;
  font-weight: 700;
}
.cgl-container {
  padding: 150px 0;
}
.caregivers-outer {
  display: block;
  width: 100%;
  position: relative;
}
.caregivers-doc-img {
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 408px;
}
ul.caregivers-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
li.caregivers-item {
  width: 50%;
}
.caregivers-box {
  width: 100%;
  max-width: 490px;
  position: relative;
  margin-bottom: 140px;
}
.caregivers-content {
  display: block;
  width: 100%;
  background: #fef4ec;
  color: #505050;
  border-radius: 10px;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.16);
  font-size: 21px;
  line-height: 32px;
  position: relative;
}
.caregivers-content-inside {
  max-width: 490px;
  padding: 54px 45px;
  position: relative;
}
.caregivers-content-inside:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 10px solid transparent;
  border-top: 30px solid #fef4ec;
  filter: drop-shadow(3px 4px 3px rgba(0, 0, 0, 0.1));
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -20px;
}
.caregivers-num {
  font-family: 'Montserrat';
  font-size: 62px;
  line-height: 56px;
  font-weight: 700;
  text-align: center;
  position: absolute;
  top: calc(100% + 30px);
  left: 0;
  width: 100%;
}
li.caregivers-item:nth-child(2n) .caregivers-box {
  margin-left: auto;
}
li.caregivers-item:nth-last-child(2) .caregivers-box,
li.caregivers-item:nth-last-child(1) .caregivers-box {
  max-width: none;
  margin-bottom: 0;
}
li.caregivers-item:nth-last-child(1) .caregivers-content-inside {
  margin-left: auto;
}
.content-container.community-qoute-conainer {
  padding: 100px 0;
}
.community-hero-container {
  background: #8c56d5 url(../images/psychologist-hero-bg.jpg) no-repeat
    center/cover;
  color: #fff;
  min-height: 280px;
  padding-top: 93px;
}
.community-hero-content {
  margin-top: 5px;
  letter-spacing: 0.5px;
  padding-bottom: 75px;
}
.community-hero-content p {
  font-size: 18px;
  line-height: 26px;
}
.community-hero-content p strong {
  display: block;
  font-weight: 700;
}
.community-hero-img {
  margin-bottom: -1px;
}
.cq-img-box {
  width: 40.5%;
  position: relative;
}
.cq-img-box .cq-img {
  height: 100%;
}
.cq-content {
  width: 59.5%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 50px 60px 50px 80px;
}
.cq-content .heading {
  margin-bottom: 24px;
}
.cq-content .heading h5 {
  font-size: 33px;
}
.cq-content blockquote {
  font-size: 26px;
  font-weight: 600;
  line-height: 35px;
  margin-bottom: 35px;
}
.cq-img-info {
  position: absolute;
  bottom: 18%;
  background: #f6f4ff;
  padding: 9px 33px;
  right: -1px;
  color: #432a7f;
  font-weight: 700;
  border: none;
  font-size: 22px;
}
.cq-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cq-btn .btn {
  line-height: 18px;
  padding: 10px;
  font-size: 14px;
  min-width: 220px;
}
.community-tabs .tab-content > .active {
  height: auto;
}
.cq-content-outer {
  background: #f6f4ff;
}
.community-tab-container {
  overflow: hidden;
}
.community-tabs ul.home-services-list {
  max-width: 1015px;
  position: relative;
  margin: 0px auto;
  padding: 0;
}
.community-tabs .nav-tabs {
  border: none;
  text-transform: uppercase;
  color: #fff;
  padding: 0 12%;
  margin: 0px auto;
  margin-bottom: 30px !important;
  width: calc(100% + 44px);
  margin-left: -22px;
}
.community-tabs .nav li {
  padding: 0px 22px;
  flex: 1;
  text-align: center;
}
.community-tabs .nav-tabs .nav-link {
  background-color: #432a7e;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0px rgb(67, 42, 126, 0.5) !important;
}
.community-tabs .nav li:not(:last-child):before {
  display: none;
}
.community-tabs .nav-tabs .nav-link:focus,
.community-tabs .nav-tabs .nav-link:hover,
.community-tabs .nav-tabs .nav-link.active {
  color: #432a7e;
  background-color: transparent;
  border: 2px solid #432a7e;
  box-shadow: none;
}
.community-tabs .card-body {
  padding: 24px 0 60px;
}
.community-tabs .slick-prev,
.community-tabs .slick-next {
  background: #432a7e;
}
.community-tabs .card-body::before {
  content: '';
  position: absolute;
  width: 100vw;
  background: #fef4eb;
  height: 100%;
  top: 0px;
}
.community-tabs .card-body::after {
  content: '';
  position: absolute;
  width: 100vw;
  background: #fef4eb;
  right: 0;
  height: 100%;
  top: 0px;
  z-index: -9;
}
.community-tab-inner {
  position: relative;
}
.pkw-heading {
  margin-bottom: 27px;
}
.pkw-heading h3::before {
  content: '';
  position: absolute;
  width: 100vw;
  height: 1px;
  background: #7b7b7b;
  left: 100%;
  margin-left: 20px;
  top: 50%;
}
.pkw-heading h3 {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  font-size: 27px;
}
.communinty-kw-content {
  padding-left: 10%;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 24px);
  margin-left: -12px;
}
.communinty-ckc-left {
  width: 66.66%;
  padding: 0px 12px;
}
.communinty-ckc-right {
  width: 33.33%;
  padding: 0px 12px;
}
.community-pkw-content {
  padding: 0px 15px;
  margin-top: 15px;
}
.community-kw-list {
  width: calc(100% + 24px);
  margin-left: -12px;
}
.community-kw-list li {
  padding: 10px 12px;
}
.community-kw-link {
  padding: 8px 22px;
  border-radius: 10px;
  background: #fddbbf;
  border: 2px solid #ffc595;
}
.communinty-ckc-inner .form-control {
  height: 44px;
  padding-right: 90px;
  padding-left: 44px;
  position: relative;
}
.communinty-ckc-inner .form-group {
  padding: 12px 0;
  margin: 0;
  vertical-align: top;
}
.communinty-ckc-inner input[type='submit'] {
  margin-left: -5px !important;
  position: absolute;
  top: 12px;
  right: 0;
  height: 44px;
  border-radius: 10px;
  border: none;
  background: #3a1171;
  color: #fff;
  padding: 5px 20px;
  transition: 0.3s ease;
}
.communinty-ckc-inner input[type='submit']:hover {
  background-color: #f68e3f;
}
.communinty-ckc-inner .form-group::after {
  content: '';
  position: absolute;
  background-color: transparent;
  height: 44px;
  width: 44px;
  z-index: 999;
  background-image: url(../images/search-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.join-community-content {
  background: #fef4eb;
  padding: 35px 20px 40px 40px;
  border-radius: 20px;
  height: 100%;
}
.join-community-content.right {
  background: #f6f4ff;
}
.join-community-content h2 {
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 15px;
}
.jc-form-content .btn:hover,
.jc-form-content .btn:active,
.jc-form-content .btn:focus {
  box-shadow: none;
}
.jc-form-content {
  padding-top: 20px;
}
.jc-form-content .fc-right {
  width: 61%;
}
.jc-form-content .fc-right label {
  font-size: 16px;
}
.jc-form-img {
  width: 39%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-bottom: 55px;
}
.jc-form-img img {
  width: 150px !important;
}
.mh-sign-post {
  background: #f6f4ff;
  display: flex;
  width: 100%;
}
.mh-sign-post-img {
  width: 50%;
}
.mh-sign-post-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mh-sign-post-content {
  width: 50%;
  padding: 63px 50px 35px;
  display: flex;
  flex-flow: column;
}
.mh-sign-post-content h3 {
  margin-bottom: 25px;
  font-weight: 700;
}
.mh-sign-post-content h6 {
  margin-bottom: 30px;
}
.mh-sign-post-content h6 strong {
  font-weight: 700;
}
.mh-sign-post-list li {
  width: 33.33%;
}
.mh-sign-post-list li .mh-sign-post-link {
  background-color: #917bc4;
}
.mh-sign-post-link {
  font-size: 22px;
  font-weight: 700;
  width: 100%;
  height: 100%;
  vertical-align: top;
  padding: 30px 60px;
  line-height: 30px;
  transition: 0.3s ease;
  color: #fff !important;
}
.mh-sign-post-list li:nth-child(odd) .mh-sign-post-link {
  background: #ff8f00;
}
.mh-sign-post-list li:hover {
  opacity: 0.9;
}
.mh-read_more {
  margin-top: auto;
}
.mh-read_more a {
  padding-right: 18px;
  position: relative;
}
.mh-read_more a span {
  position: absolute;
  right: 0;
  top: 5px;
  line-height: 0;
  transition: 0.3s ease;
}
.mh-read_more a:hover span {
  right: 5px;
}
.keywords-container {
  background: #fef4ec;
  padding: 235px 0 98px;
  margin-top: -200px;
}
.heading-with-line.grey h3 {
  color: #26262a;
  text-transform: capitalize;
}
.heading-with-line.grey h3 > span:before {
  background: #26262a;
}
.keywords-box {
  padding-top: 15px;
}
.keywords-box ul.community-kw-list {
  width: calc(100% + 26px);
  margin-left: -13px;
}
.keywords-box .community-kw-list li {
  padding: 10px 13px;
}
.keywords-box .community-kw-link {
  padding: 16px 29px;
  min-width: 185px;
  text-align: center;
  font-size: 20px;
  font-family: 'Montserrat';
}
.community-kw-link:hover,
.community-kw-link:active,
.community-kw-link:focus {
  color: #26262a;
  background: #ffc595;
}

/* ================= CLINICIAN END ================= */

/* ================= DOCTOR SIDE DASHBOARD START ================= */
.logo-area {
  width: 190px;
}
.dtb-mid {
  color: #180058;
  font-size: 14px;
  padding: 0 0 0 40px;
  flex-grow: 1;
}
.dtb-mid h2 {
  font-size: 16px;
  margin-bottom: 0;
}
.dashboard-top-pannel,
.dashboard-main-pannel {
  width: calc(100% + 30px);
  margin-left: -15px;
}
.dashboard-top-pannel {
  height: 85px;
  margin-bottom: 25px;
}
.pannel-header {
  height: 159px;
  margin-bottom: 15px;
}
.dashboard-main-pannel {
  height: calc(100vh - 250px);
}
.appointments-pannel-outer {
  height: calc(100% - 174px);
  overflow-y: auto;
}
.appointments-pannel {
  padding: 20px 0;
  height: 100%;
}
.tp-text-box,
.tp-button-group {
  width: 50%;
  padding: 25px 15px;
}
.tp-text-box {
  color: #1d0e3d;
  font-family: 'Montserrat';
}
.tp-heading {
  font-size: 24px;
}
.tp-date-desc {
  font-size: 18px;
  position: relative;
  padding: 0 45px;
  text-align: center;
}
.tp-left-arrow,
.tp-right-arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.tp-left-arrow {
  left: 0;
}
.tp-right-arrow {
  right: 0;
}
.tp-time-intro {
  font-size: 22px;
}
.tp-button-group .btn-outline {
  background: #fff;
  color: #f1964f;
}
.tp-button-group .btn-outline:hover,
.tp-button-group .btn-outline:focus,
.tp-button-group .btn-outline:active {
  background: #f1964f;
  color: #fff;
}
.btn2 + .btn2 {
  margin-left: 26px !important;
}
.pannel-left,
.pannel-right {
  width: 50%;
  padding: 0 15px;
  height: 100%;
}
ul.pannel-page-links {
  width: calc(100% + 12px);
  margin-left: -6px;
  margin-bottom: 25px;
}
ul.pannel-page-links li.ppl-item {
  width: 33.33%;
  padding: 0 6px;
}
.ppl-box {
  width: 100%;
  height: 100%;
  padding: 25px 19px;
  color: #180058;
  font-size: 16px;
  background: #fff;
  border: 1px solid #18005833;
  border-radius: 20px;
  position: relative;
}
.ppl-box:after {
  content: '';
  position: absolute;
  border-top: 15px solid #582bb8;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  opacity: 0;
  visibility: hidden;
}
.active .ppl-box:after {
  opacity: 1;
  visibility: visible;
}
.ppl-box:hover {
  border-color: #582bb8;
}
.active .ppl-box {
  background: #582bb8;
  color: #fff;
}
.ppl-box > strong {
  font-size: 29px;
  margin-bottom: 15px;
}
.pannel-left h4 {
  font-size: 24px;
  font-family: 'Montserrat';
  color: #1d0e3d;
  margin-bottom: 0;
  font-weight: 400;
}
.person-details {
  padding: 12px 30px;
  color: #180058;
}
.person-details + .person-details {
  margin-top: 4px;
}
.person-details-box .person-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.person-details-box .person-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.person-text-box .btn.btn-xs {
  font-size: 10px;
}
.person-text-box h3 {
  font-size: 18px;
  font-weight: 700;
  color: #180058;
  margin-bottom: 4px;
}
.person-text-box h3 > i {
  margin-left: 10px;
}
.person-text-box {
  padding-left: 12px;
}
.pd-right .dropright {
  margin-left: 25px;
}
.person-text-box .btn + .btn {
  margin-left: 8px;
}
.pd-right {
  position: relative;
}
.pd-text {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden; /*   transition: all 0.2s ease-in;*/
}
.person-details:hover {
  background: rgb(88 43 184 / 15%);
}
.pd-right strong {
  padding-left: 20px;
}
.person-details:hover .pd-right strong {
  /* opacity: 0;
  visibility: hidden; */
}
.person-details:hover .pd-text {
  opacity: 1;
  visibility: visible;
}
.chat-seprator1 {
  overflow: hidden;
}
.appointments-pannel .chat-seprator1 {
  margin: 36px 30px;
}
.chat-seprator1 > span {
  position: relative;
  font-size: 18px;
  color: #180058;
  padding-right: 25px;
}
.chat-seprator1 > span:after {
  content: '';
  border-top: dashed 1px;
  width: 100vw;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.pr-body .tab-content {
  padding: 36px;
}
.pr-body {
  height: calc(100% - 58px);
  overflow: auto;
}
.pr-box {
  height: 100%;
  position: relative;
}
.new-chatbot-outer {
  width: 100%;
  height: calc(100% - 58px);
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}
.new-chatbot-head {
  height: 95px;
  padding: 18px 20px 18px 45px;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
}
.new-chatbot-img {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  margin-right: 20px;
}
.new-chatbot-text h6 {
  font-size: 18px;
  margin-bottom: 0;
  color: #1d0e3d;
}
.new-chatbot-text h6 > i {
  color: #582bb8;
  margin-left: 9px;
}
.new-chatbot-text {
  font-size: 14px;
  color: #180058;
}
.new-chatbot-body.chat {
  height: calc(100% - 165px);
  overflow-y: auto;
  padding-top: 15px;
  padding-left: 25px;
}
.ncr-avtar-icon {
  width: 35px;
  height: 35px;
  background: #dddbe2;
  color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-chatbot-footer.send {
  padding: 17px 15px;
  height: 70px;
}
.pr-head {
  height: 58px;
}
ul.chat-member-list li {
  padding: 12px 0;
  border-bottom: 1px solid #eeeef3;
}
ul.chat-member-list li:not(:last-child) {
  margin-bottom: 8px;
}
.cm-box {
  width: 100%;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  color: rgb(88 43 184 / 60%);
}
.cm-box:before {
  content: '';
  border: solid #bcbccb;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  transform: translateY(-50%) rotate(-45deg);
  position: absolute;
  right: 0;
  top: 50%;
}
.rate-count {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  background: #582bb8;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
}
.have-chat-box {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: blue;
}
.head h4 {
  font-size: 18px;
  color: #43425d;
  margin-bottom: 0;
  font-weight: 700;
  margin-right: 20px;
}
.pr-body .chat-seprator1 {
  margin: 12px 0;
}
.pr-body ul + .chat-seprator1 {
  margin-top: 25px;
}
.chat-member-list:last-child li:last-child {
  border-bottom: 0;
}
.bordered li.cm-item .cm-box {
  border: 1px solid rgb(24 0 88 / 20%);
  color: #180058;
  border-radius: 10px;
  padding: 6px 40px 6px 15px;
}
.bordered li.cm-item .cm-box:before {
  right: 15px;
}
ul.chat-member-list.bordered li.cm-item {
  border-bottom: 0;
  margin-bottom: 0;
  padding: 6px 0;
}
.bordered li.cm-item:hover .cm-box {
  background: #e6dff5;
  box-shadow: 0px 3px 6px #18005829;
}
.add-icon-box {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #f1964f;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  line-height: 48px;
}
.add-icon-box > img {
  width: 21px;
}
.tab-pane .head {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background: #fff;
  padding: 3px 0;
}
.pd-info-icon {
  width: 14px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 10px;
}
.custom-dropright {
  position: relative;
}
.toggle-content-box {
  display: none;
  min-width: 355px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 3px 6px #18005829;
  padding: 15px;
  z-index: 1;
  right: -30px;
  top: 100%;
  margin-top: 10px;
  border-radius: 10px;
}
.custom-dropright .custom-dropdown-toggle > i {
  margin-left: 12px;
}
.toggle-content-box:before {
  content: '';
  width: 0px;
  height: 0px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fff;
  position: absolute;
  top: -12px;
  right: 25px;
  filter: drop-shadow(0px -1px 1px #18005829);
}
.toggle-content-box.show {
  opacity: 1;
  visibility: visible;
  display: block;
}
.btn.btn-with-icon {
  width: auto;
  min-width: auto;
  border: 2px solid #1d0e3d;
  padding: 10px;
}
.dw-header h3 {
  font-size: 24px;
  color: #1d0e3d;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0;
}
.dw-right .btn-outline:hover,
.dw-right .btn-outline:focus,
.dw-right .btn-outline:active {
  background: #f68e3f !important;
}
.stv-radio-buttons-wrapper {
  box-shadow: 0px 2px 3px #0000000d;
  display: inline-block;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #d7dae2;
}
.stv-radio-button {
  position: absolute;
  left: -9999em;
  top: -9999em;
}
.stv-radio-button + label {
  float: left;
  padding: 4px 15px;
  cursor: pointer;
  border: 1px solid #d7dae2;
  margin-right: -1px;
  color: #180058;
  background-color: transparent;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
}
.stv-radio-button:checked + label {
  background-color: #582bb81a;
  color: #582bb8;
}
.dashboard-wrapper {
  height: calc(100vh - 150px);
}
.dw-header {
  height: 56px;
}
.dm-footer {
  height: 50px;
}
.cc-item {
  white-space: nowrap;
}
.dw-main-content {
  height: calc(100% - 136px);
  width: 100%;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  margin: 15px 0;
}
.dw-main-head {
  padding: 11px 20px 15px;
  border-bottom: 1px solid #d7dae2;
  height: 62px;
}
.dm-main-body {
  height: 100%;
}
.custom-calendar,
.cc-main,
.ccm-content {
  width: 100%;
  height: 100%;
}
.dw-main-head .stv-radio-buttons-wrapper + p {
  margin-bottom: 0;
  font-size: 18px;
  color: #180058;
}
.cc-left {
  width: 77px;
  min-width: 77px;
}
.cc-right {
  border-bottom: 1px solid #eaf0f4;
}
.ccr-row {
  width: 100%;
  min-width: 168px;
  height: 100%;
  flex-grow: 1;
  font-size: 11px;
  font-weight: 700;
  color: #180058;
}
.ccm-left {
  width: 77px;
  min-width: 77px;
}
.ccm-right {
  /*width:auto;*/
  min-width: 168px;
  flex-grow: 1;
  width: calc(100% - 77px);
  height: calc(100% - 62px);
  overflow: auto;
}
.cc-right {
  width: auto;
  min-width: 168px;
  flex-grow: 1;
}
.ccr-row:last-child {
  border-right: 1px solid #eaf0f4;
}
.ccm-column {
  width: 100%;
  height: 49px;
  border-right: 1px solid #eaf0f4;
  font-size: 13px;
  color: #4d4f5c;
  padding-left: 10px;
}
.ccm-column:first-child {
  border-bottom: 1px solid #eaf0f4;
}
.ccm-row {
  width: 100%;
  height: 49px;
  display: flex;
  align-items: center;
}
.ccms-column {
  width: 100%;
  min-width: 168px;
  flex-grow: 1;
  height: 100%;
  border-top: 1px solid #eaf0f4;
  border-right: 1px solid #eaf0f4;
}
.ccms-column .dropright {
  height: 100%;
}
.ccms-column .dropdown-toggle {
  width: 100%;
  height: 100%;
}
.middle-column-slot .dropdown-toggle {
  top: -24.5px !important;
  position: relative;
}
.middle-column-slot .dropdown-toggle .add-column-text {
  top: auto !important;
}
.ccms-column .dropright .dropdown-menu {
  min-width: 350px;
  margin-top: 5px;
  padding: 19px;
}
.dropright-body {
  width: 100%;
  position: relative;
  padding: 15px 0;
  color: rgb(29 14 61 / 60%);
}
.dropright-body:before {
  content: '';
  border: solid #716b7d;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  transform: translateY(-50%) rotate(-45deg);
  position: absolute;
  right: 0;
  top: 50%;
}
.dropright-header {
  color: rgb(29 14 61 / 60%);
  position: relative;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.dropright-header > h3 {
  font-size: 18px;
  color: rgb(29 14 61 / 60%);
  font-weight: 700;
  margin-bottom: 15px;
}
.dropright-header .person-text-box h3 {
  color: #1d0e3d;
}
.dropright-header .person-text-box {
  font-size: 15px;
}
.dropright-status {
  color: #1d0e3d;
  font-size: 18px;
}
.dropright-status p {
  margin-bottom: 0;
  font-size: 14px;
}
.dropright-footer {
  font-size: 15px;
  color: #1d0e3d;
  padding-top: 16px;
  border-top: 1px solid #e5e5e5;
}
.dropright-body p {
  margin-bottom: 0;
}
.dropright-status .status {
  margin-left: 12px;
}
.dropright-footer p {
  margin-bottom: 15px;
}
.dropright-header .dropright {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.middle-column-slot .dropright-header .dropright {
  top: 24.5px;
}
.dropdown-menu2.show {
  display: block;
}
.dropdown-menu2 {
  width: 250px;
  padding: 0;
  margin-top: 15px;
  filter: drop-shadow(0px 3px 6px #18005829);
  border: 0;
  border-radius: 10px;
  top: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: none;
  background: #fff;
}
.dropdown-menu2 .dropdown-item {
  color: #1d0e3d;
  padding: 19px;
}
.dropdown-menu2 .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}
.dropdown-menu2 .dropdown-item {
  color: #1d0e3d;
  padding: 15px 30px;
  font-size: 16px;
}
.dropdown-menu2 .dropdown-item:focus,
.dropdown-menu2 .dropdown-item:hover {
  background-color: transparent;
}
.add-arrow-shape:before {
  content: '';
  width: 0px;
  height: 0px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -12px;
}
.dropright-header a.dropdown-toggle,
.dropright-header .dropright {
  height: auto !important;
}
.middle-column-slot .dropright-header a.dropdown-toggle,
.middle-column-slot .dropright-header .dropright {
  height: 0 !important;
}
.ccm-row .ccms-column:last-child {
  border-right: 1px solid #eaf0f4;
}
.ccm-row:last-child .ccms-column {
  border-bottom: 1px solid #eaf0f4;
}
.ccm-row:first-child .ccms-column:nth-child(3),
.ccm-row:nth-child(2) .ccms-column:nth-child(3),
.ccm-row:nth-child(3) .ccms-column:nth-child(3),
.ccm-row:nth-child(4) .ccms-column:nth-child(3),
.ccm-row:nth-child(8) .ccms-column:nth-child(3),
.ccm-row:nth-child(9) .ccms-column:nth-child(3) {
  background: #eafaff;
}
.ccm-row .ccms-column:nth-child(6) {
  background: rgb(213 117 117 / 20%);
}
.ccm-row:nth-child(5) .ccms-column:nth-child(3),
.ccm-row:nth-child(6) .ccms-column:nth-child(3) {
  background: #e5fcf0;
}
.add-column-text {
  width: 100%;
  height: 100%;
  background: #ebf9ff;
  padding: 15px 12px;
  position: relative;
  font-size: 13px;
  color: #180058;
  border-radius: 4px;
  border: 1px solid #5fb5d7;
}
.add-column-text p {
  margin-bottom: 0;
}
.status {
  color: #ffffff;
  font-size: 7px;
  line-height: 1.2;
  padding: 4px 6px;
  border-radius: 4px;
  margin-left: 7px;
  min-width: 35px;
}
.online .status {
  background: #5fb5d7;
}
.add-column-text.physical {
  border-color: #61c395 !important;
  background: #edfff6 !important;
}
.physical .status {
  background: #61c395 !important;
}
.add-column-text.walkin {
  border-color: #582bb8 !important;
  background: #e6dff5 !important;
}
.walkin .status {
  background: #582bb8 !important;
}
.dm-footer h5 {
  font-size: 13px;
  color: #4d4f5c;
  font-weight: 700;
  margin-bottom: 0;
}
.cc-item:not(:first-child) {
  position: relative;
  padding-left: 30px;
}
.cc-check-icon {
  width: 20px;
  height: 20px;
  border: 1px solid #5fb5d7;
  background: #ebf9ff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.cc-physical .cc-check-icon {
  border-color: #61c395 !important;
  background: #edfff6 !important;
}
.cc-walk-in .cc-check-icon {
  border-color: #582bb8 !important;
  background: #e6dff5 !important;
}
.cc-shedule .cc-check-icon {
  border-color: #eaf0f4 !important;
  background: rgb(213 117 117 / 20%) !important;
}
.cc-online .cc-check-icon {
  border-color: #e5fcf0 !important;
  background: #e5fcf0 !important;
}
.cc-item + .cc-item {
  margin-left: 25px;
}
.cc-right .add-current-time-indicator {
  position: absolute;
  width: 30px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.cc-right {
  position: relative;
  height: 45px;
}
.cc-left .dropright > a {
  width: 100%;
  height: 45px;
  padding: 10px 12px;
  font-size: 11px;
  color: #180058;
  position: relative;
  background: #582bb81a;
  border: 1px solid #582bb8;
}
.dropright .dropdown-toggle > i {
  color: #1d0e3d;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}
.middle-column-slot .add-column-text {
  top: -24.5px !important;
}
.time-block-modal .modal-content {
  box-shadow: none !important;
}
.time-block-modal .modal-dialog {
  min-width: 626px;
}
.tbm-box {
  background: #fff;
  color: #180058;
  padding: 60px 79px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 10px 25px #52466e41;
}
.custom-switch {
  position: relative;
  display: inline-block;
  user-select: none;
  padding: 0;
}
.custom-switch input {
  display: none;
}
.custom-switch label {
  position: relative;
  display: block;
  width: 41px;
  height: 22px;
  border-radius: 22px;
  background: #582bb8;
  cursor: pointer;
  margin-bottom: 0;
}
.custom-switch label:after {
  position: relative;
  display: block;
  content: '';
  width: 14px;
  height: 14px;
  top: 4px;
  left: 22px;
  border-radius: 50%;
  background: #fff;
  pointer-events: none;
  transition: all 0.4s ease-in-out;
}
.custom-switch label:active:after {
  width: 18px;
}
.custom-switch input:checked + label:active:after {
  left: 3px;
}
.custom-switch input:checked + label:after {
  left: 3px;
}
.custom-switch input:checked + label {
  background: #e6dff4;
}
.tbm-head-content h5 {
  font-size: 21px;
  font-weight: 700;
  color: #180058;
  font-family: 'Montserrat';
  margin-bottom: 0;
}
.tbm-head,
.tbm-body > p {
  font-size: 18px;
}
.custom-radio-outer .custom-control-label {
  font-size: 17px;
}
.tbm-custom-radio-box .custom-radio:nth-child(2) {
  flex-grow: 1;
  text-align: center;
}
.tbm-custom-radio-box .custom-radio:last-child {
  margin-left: auto;
}
.tbm-footer .btn {
  flex-grow: 1;
}
.tbm-head-content {
  margin-bottom: 19px;
}
.tbm-head {
  margin-bottom: 35px;
}
.tbm-body > p {
  margin-bottom: 22px;
}
.tbm-body {
  margin-bottom: 60px;
}
.modal-backdrop {
  z-index: 999999;
}
.custom-radio-outer
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #582bb8;
  background-color: #582bb8;
}
.custom-radio-outer
  .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: none;
}
ul.notifications-list li.notify-item {
  width: 100%;
}
.custom-menu.notification-menu,
.dtb-right ul.nav li:first-child {
  position: static;
}
.custom-menu.notification-menu .custom-dropdown {
  width: 100% !important;
  max-width: 395px;
  right: 100px;
}
.custom-menu.notification-menu .custom-dropdown:before {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}
ul.notifications-list li.notify-item {
  text-align: left;
}
.notify-box {
  width: 100%;
}
.notify-box .notify-img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #582bb8;
  margin-right: 16px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 44px;
}
.notify-box .notify-content {
  width: calc(100% - 60px);
}
.notify-content p {
  font-size: 16px;
  color: #180058;
  margin-bottom: 8px;
}
.notify-content small {
  font-size: 12px;
  color: #180058;
}
.add-blue-dots {
  position: relative;
}
.add-blue-dots:before {
  content: '';
  width: 12px;
  height: 12px;
  background: #397ff4;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.custom-menu.notification-menu .custom-dropdown ul li:not(:last-child):after {
  content: none;
}
.notify-img.orange {
  background: #f1964f !important;
}
.week-details-box {
  width: 100%;
  height: 42px;
}
.week-details-box .wd-item {
  text-align: center;
  font-size: 11px;
  line-height: 42px;
  font-weight: 700;
  color: #180058;
  width: auto;
  height: 100%;
  min-width: 180px;
  text-transform: uppercase;
}
.dd-row {
  width: 100%;
}
.dd-column {
  width: 100%;
  min-width: 180px;
  font-size: 13px;
  color: #180058;
  height: 125px;
  padding: 0;
  text-align: right;
  border-left: 1px solid #eaf0f4;
  border-top: 1px solid #eaf0f4;
}
.dd-column.sunday {
  background: rgb(213 117 117 / 20%) !important;
}
.dd-row:first-child .dd-column:nth-child(6) {
  background: #f0f0f0;
}
.dd-row:first-child .dd-column:nth-child(7),
.dd-row:nth-child(2) .dd-column:nth-child(7),
.dd-row:nth-child(4) .dd-column:nth-child(6) {
  background: rgb(213 117 117 / 20%);
}
.dd-row:nth-child(2) .dd-column:nth-child(6) {
  background: #e5fcf0;
}
.dd-column .dropright {
  width: 100%;
  height: 100%;
}
.dd-column > div > a,
.dd-column > a {
  width: 100%;
  height: 100%;
  padding: 17px;
}
.dd-column .dropright.show {
  width: 100%;
  height: 100%;
  border: 1px solid #582bb8;
  background: #e6dff5;
}
.dd-column .dropright .dropdown-menu {
  margin-top: 0;
  top: 35px !important;
}
.add-time-stik {
  background: #397ff4;
  border: 1px solid #eaf0f4;
  color: #fff;
  font-weight: 700;
  position: relative;
}
.add-time-stik:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #397ff4;
  border-radius: 50%;
  position: absolute;
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-left: -4px;
}
.add-time-stik:before {
  content: '';
  width: 100vw;
  height: 3px;
  background: #397ff4;
  position: absolute;
  left: 100%;
  margin-left: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.add-bott-border .ccm-column:not(:last-child) {
  border-bottom: 1px solid #eaf0f4;
}
.single-column-box {
  border: 1px solid #5fb5d7;
  padding: 10px;
  border-radius: 4px;
}
.single-column-box + .single-column-box {
  margin-top: 2px;
}
.single-column-box h3 {
  font-size: 13px;
  color: #180058;
  margin-bottom: 0;
}
.single-column-box.online {
  background: #ebf9ff !important;
}
.single-column-box.physical {
  background: #edfff6 !important;
}
.calendar-cradits-right {
  margin-left: 25px;
  white-space: nowrap;
}
.add-current-time-indicator {
  position: relative;
  height: 12px;
}
.calendar-cradits-right .add-current-time-indicator {
  width: 45px;
  margin-right: 15px;
}
.add-current-time-indicator:before {
  content: '';
  width: 100%;
  height: 2px;
  background: #397ff4;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.dots {
  width: 12px;
  height: 12px;
  background: #397ff4;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
}
.ccm-row:nth-child(5) .ccms-column,
.ccm-row:nth-child(6) .ccms-column,
.ccm-row:nth-child(7) .ccms-column,
.calendar-3 .ccm-row:nth-child(5) .ccms-column:nth-child(3),
.calendar-3 .ccm-row:nth-child(6) .ccms-column:nth-child(3) {
  background: #f8f8f8;
}
.Prescriptions-box .radio-group-box + .radio-group-box {
  margin-top: 15px;
}
.Prescriptions-box .input-group-row {
  width: 100%;
  margin-left: 0;
}
.ig-column-outer .ig-column {
  padding: 0 10px;
  margin-bottom: 10px;
}
.ig-column-outer.input-group-row {
  width: calc(100% + 20px);
  margin-left: -10px;
}
.Prescriptions-box .custom-input label:not(.error) {
  top: 13px;
}
.Prescriptions-box .class-plan-btn-group {
  padding: 10px 0;
}
.Prescriptions-box .ig-column h5 {
  font-size: 15px;
}
.Prescriptions-box .radio-group-box-outer {
  padding: 15px;
}
.Prescriptions-box .class-plan-btn-group h5 {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 35px;
}
.Prescriptions-box .check-box-group {
  padding: 20px 0;
}
.prescription-btn {
  padding-top: 15px;
}
.Prescriptions-box .chats-head {
  padding: 15px 0;
}
.Prescriptions-box i.trash {
  color: #d57575;
}
.fun-box {
  padding: 30px;
}
.files-img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #582bb8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.files-text-box h3 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 400;
  color: #180058;
}
.files-text-box {
  font-size: 14px;
  color: #1d0e3d;
  padding-left: 15px;
}
ul.fun-list li.fun-item {
  width: 50%;
}
ul.fun-list li.fun-item p {
  margin-bottom: 0;
  font-size: 15px;
  letter-spacing: 0px;
  color: #1d0e3d;
  opacity: 0.6;
}
.fun-body .ig-column .form-control {
  min-width: 450px;
}
.fun-box .radio-group-box + .radio-group-box {
  margin-top: 25px;
}
.heading-with-icon-box {
  margin: 25px 0;
  position: relative;
  padding-right: 45px;
}
.hwi-heading {
  width: 100%;
  overflow: hidden;
}
.hwi-heading h3 {
  font-size: 20px;
  font-weight: 700;
  color: #180058;
  position: relative;
  display: inline-block;
  padding-right: 15px;
  margin-bottom: 0;
}
.hwi-heading h3:before {
  content: '';
  width: 100vw;
  height: 2px;
  position: absolute;
  left: 100%;
  top: 50%;
  background-color: rgb(24 0 88 / 10%);
}
.hwi-icon {
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  background-color: #582bb8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #fff;
  position: absolute;
  right: 0;
}
.costom-check-box h3 {
  font-size: 17px;
  color: #180058;
  font-weight: 400;
}
.ccb-box-row {
  padding-left: 20px;
}
.ccb-left {
  width: 20%;
}
.ccb-right {
  width: 80%;
}
.costom-check-box {
  margin-bottom: 25px;
}
.imc-left {
  width: 20%;
}
.imc-right {
  width: 80%;
}
.imc-left h5,
.imc-right .form-group {
  margin-bottom: 0;
}
.imc-right ul.checkbox-group-list li.checkbox-group-item {
  width: 25%;
}
.imc-right .checkbox-group-item:last-child {
  width: auto !important;
}
.imc-lower .form-group.custom-input {
  width: 100%;
}
.imc-lower .checkbox-group-item:nth-child(2) {
  width: 75% !important;
  flex-grow: 1;
}
.imc-right .form-check.form-check-inline,
.imc-lower.form-check.form-check-inline {
  margin-bottom: 0;
}
.imc-row + .imc-row {
  margin-top: 15px;
}
.imc-upper {
  margin-bottom: 15px;
}
.selection-box .ig-column .form-control {
  min-width: 271px;
}
.radio-group-row-outer {
  padding-left: 15px;
}
/* ================= DOCTOR SIDE DASHBOARD  END ================= */
/* ================= Record visit start ================= */
.dw-header p {
  font-size: 18px;
  color: #1d0e3d;
}
.dw-form-box {
  padding: 40px;
  width: 60%;
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  pointer-events: none;
  color: #e6dff5;
  top: 50%;
  transform: translateY(-50%);
  line-height: 40px;
}
.form-group.has-search {
  position: relative;
}
.has-search .form-control {
  padding-left: 45px;
}
.form-group.has-search:before {
  content: '';
  width: 15px;
  height: 9px;
  background-image: url('../images/select-down-arrow.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
/* ================= Record visit  end ================= */
/* ================= TimePicker start here=================  */
.ui-timepicker-container {
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
}
.ui-timepicker {
  box-sizing: content-box;
  display: block;
  height: 205px;
  list-style: none outside none;
  margin: 0;
  padding: 0 1px;
  text-align: center;
}
.ui-timepicker-viewport {
  box-sizing: content-box;
  display: block;
  height: 205px;
  margin: 0;
  padding: 0;
  overflow: auto;
  overflow-x: hidden;
}
.ui-timepicker-standard {
  font-size: 16px;
  background-color: #fff;
  color: inherit;
  margin: 0;
  padding: 15px 0;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 5%);
}
.ui-timepicker-standard a {
  display: block;
  padding: 0.2em 0.4em;
  text-decoration: none;
}
.ui-timepicker-standard .ui-state-hover {
  background-color: #621ac0;
  font-weight: normal;
  color: #fffd;
}
.ui-timepicker-standard .ui-menu-item {
  margin: 0;
  padding: 0;
}
.ui-timepicker-corners,
.ui-timepicker-corners .ui-corner-all {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.ui-timepicker-hidden {
  display: none;
}
.ui-timepicker-no-scrollbar .ui-timepicker {
  border: none;
}
.timepicker {
  background: url(../images/clock-icon.svg) no-repeat calc(100% - 20px) center /
    21px 21px;
}
/* ================= TimePicker end here=================  */
/* ================= Dashboard my Patients start =================  */
.dashboard-single-panel {
  height: 100%;
}
.dsp-head {
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
}
.dsp-head h4 {
  font-size: 24px;
  font-weight: 400;
  color: #1d0e3d;
  font-family: 'Montserrat';
  margin-bottom: 0;
}
.dsp-body {
  width: 100%;
  background: #fff;
  border-radius: 20px;
  height: calc(100% - 65px);
  overflow: hidden;
}
.new-search-box {
  background: #fff;
  padding-left: 40px;
  padding-right: 10px;
  border: 1px solid #180058;
  position: relative;
  border-radius: 10px;
  width: 314px;
  margin-right: 30px;
}
.new-search-box:before {
  content: '';
  width: 18px;
  height: 18px;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: url('https://via.placeholder.com/23x23') no-repeat center/cover;
}
.new-search-input {
  background: transparent;
  border: 0;
  height: 100%;
  width: 100%;
  color: #000;
  font-size: 15px;
}
.new-search-input::placeholder {
  color: rgb(24 0 88 / 50%);
}
.tab-container {
}
.tab-content-box {
  padding: 20px;
  height: calc(100% - 60px);
}
.tab-content-box .tab-content,
.tab-content-box .tab-pane,
.tab-content-box .chat-member-list {
  height: 100%;
}
.tab-content-box .content-body {
  height: 100%;
  overflow: auto;
}
.tab-content-box .tab-pane {
  margin-bottom: 0 !important;
}
.has-shadow li.cm-item .cm-box {
  background: #fff;
  border: 0;
  box-shadow: 0px 3px 6px #00000029;
}
.has-shadow li.cm-item:hover .cm-box {
  background: #fff;
}
ul.chat-member-list.has-shadow li.cm-item:not(:last-child) {
  margin-bottom: 20px;
}
.sic-head {
  background: rgb(29 14 61 / 10%);
  padding: 15px 25px;
}
.sic-head-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}
.sic-head-text {
  width: calc(100% - 53px);
  font-size: 14px;
  color: #180058;
  font-weight: 400;
}
.sic-head-text h4 > i {
  margin-left: 8px;
}
.sic-head-right p {
  font-size: 14px;
  color: #180058;
  font-weight: 400;
  margin-bottom: 0;
}
.sic-head-right a > em {
  font-size: 14px;
  color: #397ff4;
  text-decoration: underline;
}
.sic-head-text h4 {
  font-size: 18px;
  font-weight: 700;
  color: #180058;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.sic-add-icon {
  width: 48px;
  height: 48px;
  cursor: pointer;
  background: #f1964f;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}
.sic-body {
  padding: 15px 25px;
}
.slb-link-box {
  width: 100%;
  position: relative;
  border: 3px dashed #1d0e3d;
  background: #e6dff5;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.slb-link-box:before {
  content: '';
  width: 20px;
  height: 20px;
  background-image: url(../images/chevron-right.svg);
  position: absolute;
  right: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  top: 50%;
  transform: translateY(-50%);
}
.slb-img {
  width: 52px;
  height: 52px;
  background: rgb(88 43 184 / 11%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.slb-text h3 {
  font-size: 20px;
  color: #180058;
  margin-bottom: 0;
}
.slb-text {
  font-size: 14px;
  color: #1d0e3d;
}
.slb-link-box + .slb-link-box {
  margin-top: 25px;
}
span.new-tags {
  display: inline-block;
  padding: 4px 8px;
  color: #fff;
  border-radius: 4px;
  background: #d57575;
  font-size: 9px;
  line-height: 16px;
}
.slb-text > div {
  min-width: 250px;
}
/* ================= Dashboard my Patients  end =================  */
/* ================= Dashboard my Patients Details  start ================  */
.patients-details-box {
  width: calc(100% - 90px);
}
.patients-details-head {
  position: relative;
  padding: 25px 30px 25px 46px;
  margin-bottom: 20px;
  position: relative;
  background: #fff;
  border-radius: 20px;
}
.patients-details-body {
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}
.patients-details-intro .patients-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 34px;
}
.patients-details-intro .patients-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.patients-details-upper h4 {
  margin-bottom: 0;
  font-size: 21px;
  color: #1d0e3d;
  font-weight: 700;
}
.patients-details-info i {
  margin-left: 10px;
}
.patients-varify {
  margin: 0 27px;
  color: rgb(24 0 88 / 60%);
  font-size: 15px;
}
.patients-varify > i {
  margin-right: 10px;
}
.patients-img.has-active:before {
  width: 18px;
  height: 18px;
}
.pdm-inner-box > span,
.ddm-inner-box > span {
  display: inline-block;
  margin-right: 20px;
  color: rgb(24 0 88 / 60%);
  font-size: 15px;
}
.patients-details-mid,
.patients-details-lower {
  width: calc(100% + 44px);
  margin-left: -22px;
}
.pdm-inner-box,
.ddm-inner-box {
  padding: 0 22px;
}
.ddm-inner-box div small {
  display: inline-block;
  margin-right: 10px;
  font-size: 15px;
}
.ddm-inner-box {
  font-size: 15px;
  color: #1d0e3d;
}
.patients-details-mid,
.patients-details-upper {
  margin-bottom: 15px;
}
.patients-back-link {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  left: 20px;
  cursor: pointer;
}
.patients-back-link:before {
  content: '';
  border: solid #716b7d;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  transform: translate(-50%, -50%) rotate(139deg);
  position: absolute;
  top: 50%;
  left: 50%;
}
ul.list-with-icon li {
  width: auto;
}
ul.list-with-icon li:not(:last-child) {
  margin-right: 25px;
}
.icon-box {
  width: 66px;
  height: 54px;
  border: 1px solid #582bb8;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-box.orange-icon {
  border-color: #f1964f !important;
}
.pd-right-dropdown {
  margin-left: 25px;
  width: 6px;
}
.bottom-align .dropdown-toggle {
  border: 0;
  background: transparent;
}
.bottom-align .dropdown-menu {
  filter: drop-shadow(0px 3px 6px #18005829);
  border: 0;
  border-radius: 10px;
  top: 100% !important;
  margin-top: -10px;
  margin-right: -15px;
}
.bottom-align .dropdown-menu:before {
  content: '';
  position: absolute;
  right: 10px;
  top: -8px;
  filter: drop-shadow(0px 1px 28px #582bb833);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
}
.bottom-align .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}
.bottom-align .dropdown-item {
  color: #180058;
  font-size: 16px;
}
.bottom-align .dropdown-item.active,
.bottom-align .dropdown-item:active {
  background-color: transparent;
}
.navigation-links a + a {
  margin-left: 15px;
}
.navigation-links a:hover,
.navigation-links a:focus,
.navigation-links a:active {
  color: #fff;
}
.previous-arrows img {
  margin-left: 9px;
}
.next-arrows img {
  margin-right: 9px;
}
.previous-arrows,
.next-arrows {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.add-treatment-plan-panel {
  padding: 30px;
  border: 3px dashed #1d0e3d;
  border-radius: 10px;
  background: rgb(230 223 245 / 60%);
  cursor: pointer;
  margin-bottom: 25px;
  position: relative;
}
.tp-head {
  font-size: 16px;
  color: #180058;
  font-weight: 400;
  margin-bottom: 12px;
}
.tp-body .form-group:last-child {
  margin-bottom: 0;
}
.tp-block + .tp-block {
  margin-top: 25px;
}
.btn.btn-gray {
  border-radius: 12px;
  background: rgb(88 43 184 / 12%);
  font-size: 17px;
  color: #1d0e3d;
  font-weight: 400;
}
.tpi-box {
  color: #1d0e3d;
  font-size: 17px;
  font-weight: 400;
}
.tpi-box input.form-control {
  width: 100px;
  margin: 0 25px;
}
.new-filter-box .btn-group {
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 2px solid #1d0e3d;
  border-radius: 10px;
}
.new-filter-box .btn-group.show {
  background: #1d0e3d;
}
.new-filter-box .dropdown-toggle i {
  font-size: 15px;
  color: #1d0e3d;
}
.btn-group.show .dropdown-toggle i {
  color: #fff !important;
}
.bottom-align.filter-dropdown .dropdown-menu {
  top: 100% !important;
  margin-top: 15px;
  margin-right: 0;
  right: auto;
  left: 50% !important;
  transform: translateX(-50%) !important;
  min-width: 374px;
}
.bottom-align.filter-dropdown .dropdown-menu:before {
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.new-filter-content {
  padding: 15px;
}
.new-checkbox-content .form-check {
  padding-left: 0;
}
.new-checkbox-content .form-check input[type='checkbox'] {
  display: none;
}
.new-checkbox-content .form-check input[type='checkbox'] + label {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  padding-right: 60px;
  font-size: 16px;
  line-height: 16px;
  color: #180058;
  margin-bottom: 0;
  cursor: pointer;
  text-transform: capitalize;
  margin-top: 4px;
}
.new-checkbox-content
  .new-checkbox-content
  .form-check
  input[type='checkbox']
  + label:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: #fff;
  border: 2px solid #1d0e3d;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 2px;
}
.new-checkbox-content.form-check input[type='checkbox'] + label:after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  display: inline-block;
  font-weight: 900;
  font-size: 13px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  color: #aaa;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(0);
  -moz-transition: all 0.12s ease-in;
  -o-transition: all 0.12s ease-in;
  -webkit-transition: all 0.12s ease-in;
  transition: all 0.12s ease-in;
}
.new-checkbox-content .form-check input[type='checkbox'] + label:after {
  color: #333;
}
.new-checkbox-content .form-check input[type='checkbox']:checked + label:after {
  transform: scale(1);
}
.new-checkbox-content .signup-agree-box + .signup-agree-box {
  margin-top: 18px;
}
.new-checkbox-content
  .form-check
  input[type='checkbox']
  + .form-check-label::before {
  border: 2px solid #1d0e3d;
  width: 18px;
  height: 18px;
}
.new-checkbox-content
  .form-check
  input[type='checkbox']
  + .form-check-label::after {
  width: 18px;
  height: 18px;
}
.new-filter-heading {
  margin-bottom: 20px;
}
.new-filter-heading h6 {
  margin-bottom: 0;
  font-weight: 700;
}
.new-filter-heading a {
  color: #f68e3f;
  text-decoration: underline;
}
.new-seprator {
  width: 100%;
  height: 2px;
  margin: 18px 0;
  background: #d5d5d5;
}
.date-filter-box .form-control {
  border: 1px solid rgba(88, 43, 184, 0.6);
  border-radius: 8px;
  font-size: 14px;
  color: #1d0e3d;
}
.date-filter-box {
  width: 100%;
}
.date-filter-box .dfb-left,
.date-filter-box .dfb-right {
  width: 50%;
}
.dfb-left {
  padding-right: 5px;
}
.dfb-right {
  padding-left: 5px;
}
.new-checkbox-content p {
  color: #180058;
}
.patient-tags {
  background: #582bb8;
  padding: 3px 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 9px;
  line-height: 16px;
  margin-left: 10px;
}
.patient-btn a {
  background: #397ff4;
  padding: 3px 10px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.patient-btn p {
  margin-bottom: 0;
}
.show-bg .new-filter-box {
  background: #1d0e3d;
}
.patient-btn a > i {
  color: #fff;
}
.patient-tags {
  background: #582bb8;
  padding: 3px 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 9px;
  line-height: 16px;
  margin-left: 10px;
}
.patient-btn a {
  background: #397ff4;
  padding: 0px 8px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  height: 27px;
}
.light-blue {
  background: #5fb5d7 !important;
}
.light-green {
  background: #61c395 !important;
}
.item .t-p.ch-main {
  padding: 24px 41px 24px 39px;
}
.ch-content-box:first-child {
  margin-bottom: 40px;
}
.ch-content-box p {
  font-size: 14px;
  line-height: 21px;
  color: rgba(29, 14, 61, 0.6);
  margin-bottom: 16px;
}
.ch-content-box h5 {
  font-size: 17px;
  line-height: 28px;
  color: #180058;
  margin-bottom: 0;
}
ul.ch-list + ul.ch-list {
  margin-top: 40px;
}
ul.ch-list li.ch-item {
  width: 50%;
}
li.ch-item .ch-content-box {
  margin-bottom: 0;
}
.ch-main-box .block-seprator {
  background: #e6dff5;
  margin: 25px 0;
}
.ih-text {
  display: inline-block;
  margin-right: 30px;
}
.iht-content {
  font-size: 14px;
  color: #180058;
  margin-right: 30px;
}
.iht-content p {
  margin-bottom: 0;
}
.patient-tags.light-grey {
  background: rgb(88 43 184 / 15%);
  color: #180058;
  border: 1px solid #180058;
}
.rg-row-content-right .custom-radio-group {
  width: auto;
  border-radius: 5px;
  margin-left: 0 !important;
  background: rgb(88 43 184 / 15%);
}
.rg-row-content-right .custom-radio-group .custom-radio {
  padding: 0;
}
.rg-row-content-right .custom-radio-group .custom-control-label {
  border: 0;
  height: 55px;
}
.rg-row-content-right .form-control {
  height: 55px;
}
.rg-row-content-right .form-group {
  margin-bottom: 0;
}
.rg-row-content-right select.form-control {
  min-width: 200px;
}
.rg-row-right .form-check {
  margin-bottom: 0;
}
.number-input-field {
  max-width: 100px;
  margin: 0 15px;
}
.rg-row-left {
  margin-right: 15px;
}
.add-disable {
  position: relative;
}
.add-disable:before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 60%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.add-disable.active:before {
  content: none;
}
.rg-row-content-left {
  width: 20%;
}
.rg-row-content-right {
  width: 70%;
}
.rg-bar-icon {
  width: 40px;
  background: rgb(88 43 184 / 15%);
  padding: 10px;
  border-radius: 8px;
  margin-left: 15px;
}
.rg-bar-icon span {
  width: 100%;
  height: 2px;
  background: #621ac0;
  display: block;
}
.rg-bar-icon span + span {
  margin-top: 5px;
}
.rg-row + .rg-row {
  margin-top: 15px;
}
/* ================= Dashboard my Patients Details  end =================  */
/* ================= Our experts start =================  */
.content-container.expert-container {
  padding: 82px 00 15px;
}
.heading-with-line {
  width: 100%;
  margin-bottom: 25px;
  position: relative;
}
.heading-with-line h3 {
  font-size: 34px;
  font-weight: 500;
  color: #432a7f;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0;
  text-transform: uppercase;
}
.heading-with-line h3 > span {
  position: relative;
  display: inline-block;
}
.heading-with-line h3 > span:before {
  content: '';
  width: 100vw;
  height: 1px;
  background: #482f82;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 22px;
}
.experts-container {
  padding: 50px 0;
}
ul.experts-list.new-experts-list {
  width: calc(100% + 86px);
  margin-left: -43px;
}
ul.experts-list.new-experts-list li.expert-item {
  padding: 30px 28px;
}
ul.experts-list.new-experts-list .expert-box {
  text-align: center;
}
.expert-btn.viewmore-btn {
  margin-top: 5px;
}
.expert-btn.viewmore-btn .btn {
  padding: 14px 15px;
}
.experts-container:last-child {
  padding-bottom: 135px;
}
/* ================= Our experts end =================  */
/* ================= Blog start =================  */
.blog-left {
  width: 65%;
  padding-right: 45px;
}
.blog-box {
  padding: 55px 50px 42px 85px;
  box-shadow: 1px 8px 20px rgb(98 27 192 / 20%);
  border-radius: 10px;
  overflow: hidden;
}
.blog-box + .blog-box {
  margin-top: 58px;
}
.blog-left-pannel {
  width: calc(100% - 245px);
  padding-right: 45px;
}
.blog-right-pannel {
  width: 245px;
}
.blog-left-pannel h3 {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 22px;
  font-family: 'Montserrat';
}
.blog-left-pannel p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 19px;
  color: #505050;
}
.blog-left-lower .community-kw-list {
  width: calc(100% + 18px);
  margin-left: -9px;
}
.blog-left-lower {
  margin-top: 20px;
}
.blog-left-pannel .mh-read_more a {
  color: #4e11a7;
}
.blog-left-lower .community-kw-link {
  padding: 7px 30px;
  min-width: 150px;
  flex-grow: 1;
  text-align: center;
}
.blog-left-lower .community-kw-list li {
  padding: 10px 9px;
  font-size: 16px;
}
.blog-pannel-img {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.blog-pannel-img img {
  width: 100%;
  height: 100%;
}
.blog-right {
  width: 35%;
  background: #f6f4ff;
  border-radius: 10px;
  overflow: hidden;
}
.br-content-box {
  padding: 46px 70px 50px 50px;
  border-bottom: 2px solid #dcdbe3;
  font-family: 'Montserrat';
}
.br-content-box:last-child {
  border-bottom: 0;
}
.br-seartch-bar .form-control {
  height: 60px;
  border: 2px solid #432a7f;
  font-size: 18px;
  padding: 5px 31px;
}
.br-seartch-bar .form-control::placeholder {
  color: #505050;
}
.br-seartch-bar .form-group::after {
  content: '';
  position: absolute;
  background-color: transparent;
  height: 44px;
  width: 44px;
  z-index: 999;
  background: #fff url(../images/feather-search.svg) no-repeat right center;
  background-size: 25px auto;
  background-position-x: calc(100% - 15px);
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}
.br-content-box .community-kw-list li {
  padding: 10px 12px;
}
.br-content-box .community-kw-link {
  padding: 18px 30px;
  font-size: 20px;
}
.br-seartch-bar .form-group {
  margin-bottom: 21px;
}
.br-content-box .br-heading {
  margin-bottom: 42px;
}
.br-content-box .br-heading h3 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 0;
}
ul.topic-list li:not(:last-child) {
  margin-bottom: 23px;
}
ul.topic-list li .tl-link {
  background: #e3ddfd;
  padding: 18px 30px;
  font-size: 20px;
  font-family: 'Montserrat';
  text-transform: uppercase;
  border-radius: 10px;
  min-width: 360px;
}
ul.topic-list li .tl-link:hover {
  background: #9e88ff;
  color: #fff;
}
ul.pagination li.page-item {
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
}
ul.pagination li.page-item:not(:last-child) {
  padding-right: 20px;
}
ul.pagination li.page-item .page-link {
  color: #26262a;
  padding: 12px 20px;
  background-color: #ffebdb;
  font-weight: 700;
  border: 0;
  font-size: 20px;
  font-family: 'Montserrat';
  border-radius: 10px !important;
}
ul.pagination li.page-item .page-link:hover {
  background: #fddbbf;
}
.blog-left .heading-with-line {
  margin-bottom: 60px;
}
.pagination-container {
  padding-top: 50px;
}
.blog-hero-text {
  position: absolute;
  top: 13%;
  left: 45%;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  text-transform: uppercase;
}
.clinician-hero-container.blog-hero {
  padding-top: 49px;
}
.blog-hero .clinician-hero-img {
  max-width: 720px;
  margin-left: 108px;
  margin-bottom: -18px;
}
.blog-hero .clinician-hero-content p {
  font-size: 24px;
}
.blog-hero .clinician-hero-content {
  padding: 50px 0px 65px 66px;
}
.blog-content-box {
  width: calc(100% + 140px);
  margin-left: -70px;
}
.blog-left .heading-with-line.grey h3 {
  font-size: 30px;
}
ul.latest-article-list li.latest-article-item {
  margin-bottom: 21px;
}
.latest-article-box {
  position: relative;
  padding: 13px 65px 13px 62px;
}
.latest-article-sequence {
  font-size: 48px;
  font-weight: 700;
  color: #432a7f;
  font-family: 'Montserrat';
  position: absolute;
  top: 15px;
  left: 0;
}
.latest-article-text {
  font-size: 20px;
  color: #505050;
  font-weight: 500;
  font-family: 'Montserrat';
}
.latest-article-text h3 {
  font-size: 20px;
  color: #432a7f;
  font-weight: 700;
  margin-bottom: 10px;
}
/* ================= Blog end =================  */
/* ================= Mindy disk start =================  */
.du-header {
  margin-bottom: 24px;
}
.du-header h3 {
  margin-bottom: 0;
}
.du-header ul.query-list {
  background: #621bc0;
  padding: 7px 15px;
}
.du-header .query-list li a > img {
  width: 16px;
  margin-right: 5px;
}
.du-header .query-list li {
  display: flex;
  align-items: center;
}
.du-header .query-list li > a {
  display: flex;
  align-items: center;
}
/*.du-header ul.query-list li:not(:last-child):before{background: #fff;}*/

.desk-upper h3 {
  font-size: 24px;
  letter-spacing: 0px;
  color: #1d0e3d;
  font-family: 'Montserrat', sans-serif;
}
.desk-query-box .form-control {
  font-size: 16px;
}
.desk-query-box {
  padding: 30px;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 40px;
}
.desk-query-box .query-input {
  width: 40%;
  flex-grow: 1;
  margin-right: 20px;
}
.desk-query-box .input-group {
  flex-grow: 1;
  width: 60%;
}
.desk-query-box .submit-btn {
  margin-left: 20px;
}
.desk-query-box .btn.btn-default {
  font-size: 16px;
  min-width: 172px;
  box-shadow: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.faq-box {
  padding: 30px;
  background: #fff;
  border-radius: 20px;
}
.faq-box .ih-box h3 {
  font-size: 17px;
}
.desk-query-box .input-group > .custom-select:not(:last-child),
.desk-query-box .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
/* ================= Mindy disk end =================  */
/* ================= sidebar doc1 Start =================  */
.sidebar-left p {
  font-size: 14px;
}
.sidebar-left span {
  font-weight: 700;
}
a.action-img + .action-img {
  margin-left: 20px;
}
.scb-left,
.scb-right,
.sib-left,
.sib-right {
  width: 50%;
}
.sib-left img{
  width: 50%;
}
.sidebar-info-box h5 {
  font-size: 18px;
  font-weight: 700;
  color: #1d0e3d;
  margin-bottom: 11px;
}
.sidebar-content-box h6 {
  font-size: 17px;
  color: #180058;
  margin-bottom: 0px;
  line-height: 28px;
}
.sidebar-info-box h5 small {
  font-size: 14px;
  font-weight: 700;
}
.sidebar-content-box p,
.sidebar-info-box p {
  font-size: 15px;
  line-height: 25px;
  color: #1d0e3d;
  opacity: 0.6;
  margin-bottom: 0;
}
.sidebar-content-box p {
  margin-bottom: 11px;
}
.scb-left {
  padding-right: 25px;
}
.full-width {
  width: 100% !important;
}
.sidebar-info-box {
  padding: 18px 0;
}
.sidebar-content-box {
  padding: 18px 0;
}
.sidebar-bg-box {
  background: #f7f7f7;
  padding: 15px;
}
.box-h-line {
  width: 100%;
  overflow: hidden;
}
.box-h-line span {
  display: inline-block;
  font-size: 14px;
  line-height: 21px;
  color: #1d0e3d;
  font-weight: 700;
  position: relative;
  padding-right: 10px;
}
.box-h-line span:before {
  content: '';
  width: calc(100vw - 30px);
  height: 100%;
  border-top: 1px dashed #180058;
  opacity: 0.3;
  position: absolute;
  left: 100%;
  top: 50%;
}
li.sidebar-content-item {
  width: 20%;
  padding: 0 4px;
}
.box-h-line span:before {
  content: '';
  width: calc(100vw - 30px);
  height: 100%;
  border-top: 1px dashed #180058;
  opacity: 0.3;
  position: absolute;
  left: 100%;
  top: 50%;
}
li.sidebar-content-item .sidebar-content-box {
  height: 100%;
}
ul.sidebar-content-list {
  width: calc(100% + 8px);
  margin-left: -4px;
}
.scb-content {
  padding-right: 30px;
  position: relative;
  padding-left: 45px;
}
.scb-img {
  position: absolute;
  left: 17px;
  top: 0;
  width: 8px;
}
.text-color h6 {
  color: #f1964f;
  font-weight: 700;
}
.scb-text {
  font-size: 12px;
  color: #397ff4;
}
.border-top-bottom {
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
}

.sic-body .item .t-p {
  padding: 15px;
}
.small-table + .small-table {
  margin-top: 30px;
}
.small-table.table {
  margin-bottom: 0;
  color: #000;
}
.small-table.table thead th {
  color: rgb(29 14 61 / 60%);
  font-weight: 400;
  border: 0;
}
.small-table.table td,
.small-table.table th {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  padding: 7px 15px 7px 0;
  border-top: 0;
  color: #1d0e3d;
}
.td-trash-icon {
  color: #d57575;
  cursor: pointer;
}
.small-table tfoot td b {
  color: #f1964f;
  font-weight: 700;
}
.small-table tfoot td {
  border-top: 1px solid #707070 !important;
  border-bottom: 1px solid #707070 !important;
}

/* ================= sidebar doc1 End =================  */
/* ================= emergency contact modal Start =================  */
.emergency-contact-modal .modal-content {
  padding: 61px 80px;
}
.ecm-box h4 {
  font-size: 21px;
  color: #180058;
  font-weight: 700;
  margin-bottom: 19px;
}
.ecm-box h4 + p {
  font-size: 18px;
  font-weight: 400;
  color: #180058;
}
.emc-column > p {
  color: rgb(24 0 88 / 60%);
  font-size: 15px;
  margin-bottom: 4px;
}
.emc-box,
.emc-column em {
  font-size: 16px;
  color: #180058;
  font-style: normal;
}
.emc-row {
  width: calc(100% + 30px);
  margin-left: -15px;
}
.emc-column {
  width: 50%;
  padding: 0 15px;
  margin-bottom: 19px;
}
.emc-column address {
  margin-bottom: 0;
}
.emc-column:nth-child(3) {
  width: 100% !important;
}
.ecm-btn {
  margin-top: 50px;
}
.emergency-contact-modal .modal-dialog {
  min-width: 626px;
}
/* ================= emergency contact modal End ===================  */
/* ================= My summaries Start =================  */
.green {
  color: #61c395 !important;
}
.blue {
  color: #582bb8 !important;
}
.yellow {
  color: #f1964f !important;
}
.quaternary {
  color: #d57575 !important;
}
.green-border {
  border: 1px solid #61c395 !important;
}
.blue-border {
  border: 1px solid #582bb8 !important;
}
.yellow-border {
  border: 1px solid #f1964f !important;
}
.quaternary-border {
  border: 1px solid #d57575 !important;
}
.msb-head {
  margin-bottom: 7px;
}
.msb-head-left h4 {
  font-size: 24px;
  line-height: 53px;
  color: #1d0e3d;
  margin-bottom: 0;
}
.msb-head-right .stv-radio-buttons-wrapper {
  background: #fff;
  border-radius: 16px;
}
.msb-middle {
  margin-bottom: 21px;
}
ul.earning-list {
  width: calc(100% + 34px);
  margin-left: -17px;
}
li.earning-item {
  width: 25%;
  padding: 17px;
}
.ei-content-box {
  position: relative;
  background: #fff;
  padding: 20px 70px 20px 15px;
  border-radius: 20px;
  height: 100%;
}
.ei-content-box .ei-img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ei-content-box .ei-img img {
  width: 16px;
}
.ei-content-box .ei-desc {
  width: calc(100% - 38px);
  font-size: 13px;
  line-height: 20px;
  padding-left: 15px;
}
.ei-content-box .ei-desc h5 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  margin-bottom: 3px;
  font-weight: 700;
}
.ei-content-box .ei-desc p {
  color: #4d4f5c;
}
.ei-content-box .ei-percentage {
  font-size: 14px;
  line-height: 18px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.ei-content-box .ei-percentage span {
  margin-right: 5px;
}
.msb-body {
  background: #fff;
  padding: 25px 34px 37px 30px;
  border-radius: 20px;
}
.msb-patients-box {
  font-size: 18px;
  line-height: 13px;
  color: #1d0e3d;
  margin-bottom: 21px;
}
.msb-patients-box p {
  margin-bottom: 0;
}
.msb-patients-content-box {
  margin-bottom: 28px;
}
.msb-patients-content-box:last-child {
  margin-bottom: 0px;
}
.msb-img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* ================= My summaries End =================  */
/* ================= CRF page Start =================  */
/*.level-three{height:calc(100vh - 150px);}
.level-three .patients-details-head{height:152px;}
.level-three .patients-details-body{height:calc(100% - 266px);}*/
.patients-details-footer {
  margin-top: 20px;
  height: 74px;
  padding: 12px 20px;
  background: #fff;
  border-radius: 20px;
}
.crf-content-box {
  padding: 0 20px 20px;
}
.crf-content-box h4 {
  font-size: 18px;
  color: #1d0e3d;
  font-weight: 700;
  margin-bottom: 15px;
}
.uplooad-file-intro {
  margin-left: 30px;
}
.radio-group-box h5,
.checkbox-group-row h6,
.textarea-row h5,
.grid-row-outer h5 {
  font-size: 14px;
  font-weight: 400;
  color: #1d0e3d;
  margin-bottom: 14px;
}
.crf-content-box .radio-group-box + .radio-group-box {
  margin-top: 15px;
}
.pane-seprator {
  width: 100%;
  height: 1px;
  background: #707070;
  margin: 30px 0;
}
.crf-content-box .btn-upload,
.crf-content-box .btn-upload:hover {
  color: #fff;
  background: #621ac0;
}
.crf-content-box .btn-upload > span {
  color: #fff;
}
.crf-content-box .btn-upload > span i {
  margin-right: 15px;
}
.radio-group-row .form-check {
  padding: 0 35px 10px 35px;
}
.ig-column .form-group {
  margin-bottom: 0;
}
.dtb-content .input-group-row {
  width: 100%;
  margin-left: 0;
}
.input-group-row {
  width: calc(100% + 30px);
  margin-left: -15px;
  max-width: 1225px;
}
.ig-column {
  padding: 15px;
  width: 33.33%;
}
.rg-row-outer {
  max-width: 1040px;
}
.ig-multiple-column .ig-column {
  padding: 15px;
  width: 50%;
}
.ig-multiple-column .ig-column + .ig-column {
  margin: 0;
}
.ig-multiple-column .ig-column > .custom-toggle-button {
  margin-bottom: 12px;
}
.radio-group-row + .input-group-row {
  margin-top: 10px;
}
.radio-group-box > .form-group {
  max-width: 450px;
}
.custom-toggle-button > p {
  font-size: 17px;
  margin-bottom: 0;
}
.custom-toggle-button .custom-switch-outer {
  margin-left: 15px;
}
.form-group + .custom-toggle-button {
  margin-top: 24px;
}
.checkbox-group-row {
  width: 100%;
  max-width: 960px;
  margin: 30px 0;
}
ul.checkbox-group-list {
  width: calc(100% + 30px);
  margin-left: -15px;
}
ul.checkbox-group-list li.checkbox-group-item {
  width: 50%;
  padding: 0 15px;
}
.checkbox-group-row .form-group {
  max-width: 650px;
}
.textarea-row {
  max-width: 1200px;
}
.crf-content-box ul.checkbox-group-list li.checkbox-group-item {
  width: 25%;
  padding: 0 15px;
}
.diagnosis-box {
  max-width: 1200px;
}
.plan-group-column {
  width: 50%;
}
.diagnosis-box .form-group {
  margin-bottom: 0px;
}
.plan-group-column span {
  margin-right: 5px;
  font-size: 12px;
  font-weight: 400;
}
.plan-group-column h2 {
  font-size: 18px;
  font-weight: 700;
  color: #1d0e3d;
}
.plan-group-column .btn-add {
  border: 0;
  color: #130928;
  background: rgb(88 43 184 / 15%);
}
.mhem-team-box h6 {
  font-weight: 700;
  color: #1d0e3d;
  margin-bottom: 5px;
}
.disclaimer-box span {
  color: #ff8f00;
  font-weight: 700;
}
.pdf-btn {
  max-width: 100px;
}
.pdf-btn a + a {
  margin-left: 30px;
}
.grid-row-outer {
  padding-left: 35px;
  margin-top: 15px;
  margin-bottom: 25px;
}
.grid-row-checkbox {
  width: 40%;
}
.grid-row-right {
  width: 60%;
}
.grid-row-outer {
  max-width: 715px;
}
.grid-row-right .form-group {
  margin: 0;
  max-width: 275px;
}
.grid-row-right select.form-control {
  min-width: 270px;
}
.grid-row-checkbox .form-check {
  margin-bottom: 0;
}
.grid-row + .grid-row {
  margin-top: 15px;
}
.three-column-row .tc-row + .tc-row {
  margin-top: 15px;
}
.tc-row-body {
  padding-left: 30px;
}
.custom-switch-heading h3 {
  font-size: 18px;
  color: #1d0e3d;
  font-weight: 700;
  margin-bottom: 0;
}
.custom-switch-heading .custom-switch-outer {
  margin-left: 15px;
}
.tc-btn {
  border-radius: 8px;
  background: rgb(88 43 184 / 15%);
  box-shadow: none;
}
.tc-btn.active {
  background: #582bb8;
  color: #fff;
  box-shadow: none;
  outline: none;
}
.tc-btn + .tc-btn {
  margin-left: 15px !important;
}
.tc-column + .tc-column {
  margin-left: 15px;
}
.tc-column {
  width: auto;
  flex-grow: 1;
}
.number-input-field {
  max-width: 100px;
}
.new-checkbox-group-box {
  max-width: 489px;
}
.new-checkbox-group-box .ig-column {
  margin-bottom: 15px;
  width: 100%;
}
.crf-content-box
  ul.checkbox-group-list.new-checkbox-group-list
  li.checkbox-group-item {
  width: 50%;
}
.custom-switch-heading {
  margin-bottom: 15px;
}
.fm-box {
  max-width: 800px;
  padding: 10px 0;
}
.fm-form-item {
  width: auto;
  flex-grow: 1;
  padding: 0 15px;
}
.fm-form-list {
  width: calc(100% + 30px);
  margin-left: -15px;
}
.bio-content-panel .ig-column {
  width: 100%;
  padding: 0;
}
.sidebar-body .bio-content-panel .form-control {
  height: 60px;
}
.bio-content-panel .input-group-row {
  width: 100%;
  margin-left: 0;
  max-width: 100%;
}
.bio-content-panel .radio-group-box h5 {
  font-size: 16px;
  color: #180058;
}
.bio-content-panel .radio-group-box + .radio-group-box {
  margin-top: 25px;
}
.new-add-btn {
  margin-top: 50px;
  text-align: right;
}
.bio-content-panel .radio-group-box > .form-group {
  max-width: 100%;
}
.dtb-content .input-group-row {
  width: 100%;
  margin-left: 0;
  max-width: 100%;
}
.formulation-box-outer {
  max-width: 400px;
}
.formulation-box {
  width: 100%;
  padding: 13px 27px 13px 15px;
  position: relative;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  border-bottom: 0;
  cursor: pointer;
}
.formulation-box + .formulation-box {
  margin-left: 20px;
}
.copy-check-box {
  margin-left: 20px;
}
.copy-check-box {
  opacity: 0;
  visibility: hidden;
}
.rg-row.active .copy-check-box {
  opacity: 1;
  visibility: visible;
}
.copy-check-box.active {
  opacity: 1;
  visibility: visible;
}
.copy-check-box .form-check {
  margin-bottom: 0;
}
.fbl-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(88, 43, 184, 0.15);
}
.fbl-text {
  width: calc(100% - 40px);
  padding-left: 20px;
}
.fbl-img img {
  width: 18px;
}
.fbr-img {
  transform: rotate(-90deg);
  width: 16px;
}
/* ================= CRF page End =================  */
/* ================= Dashboard video4 start =================  */
.nvb-box .cm-box:before {
  content: none;
}
.new-video-box {
  width: 100%;
}
.new-video-box-left {
  width: 50%;
}
.new-video-box-right {
  width: 50%;
  padding-left: 20px;
}
.new-video-box .mcb-head {
  background: #fff;
  border-radius: 20px;
  padding: 12px 30px;
  height: 90px;
}
.mcbp-left {
  width: 40%;
}
.mcbp-img {
  width: 65px;
}
.mcbp-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mcbp-desc {
  width: calc(100% - 65px);
  padding-left: 16px;
}
.mcbp-desc h4 {
  font-size: 18px;
  line-height: 26px;
  color: #180058;
  margin-bottom: 0;
}
.mcbp-text span img {
  padding-left: 10px;
}
.mcbp-desc .pd-right-content strong {
  font-size: 15px;
  line-height: 22px;
}
.toggle-content-box p {
  font-size: 13px;
  line-height: 1.2;
}
.mcbp-right {
  position: relative;
  width: 60%;
  padding-left: 20px;
}
.mcbp-right .pdm-inner-box span {
  font-size: 15px;
}
.mcbp-right:before {
  content: '';
  width: 1px;
  height: 100%;
  background: #1800582f;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.mcb-profile-head .patients-details-mid,
.patients-details-upper {
  margin-bottom: 0;
}
.mcb-profile-head .btn {
  border-radius: 10px;
  padding: 11px 15px;
  min-width: 172px;
  font-size: 16px;
  line-height: 25px;
}
.btn-pink,
.btn-pink:focus {
  border-color: #d57575;
  color: #fff;
  background-color: #d57575;
}
.btn-pink:hover,
.btn-pink:active {
  border-color: #d57575;
  color: #d57575;
  background-color: transparent;
}
.mcb-footer-bottom {
  background: #ffffff;
  border-radius: 20px;
  height: 84px;
  font-size: 20px;
  line-height: 26px;
  color: #180058;
}
.mcb-footer + .mcb-footer-bottom {
  margin-top: 16px;
}
.nvb-box p {
  margin-bottom: 0;
  font-size: 20px;
  color: #180058;
}
.nvb-box {
  background: #fff;
  border-radius: 20px;
  padding: 30px;
}
.new-video-box-left .mcb-head {
  margin-bottom: 20px;
}
.nvb-box + .nvb-box {
  margin-top: 20px;
}
.nvbr-box {
  width: 100%;
  background: #fff;
  border-radius: 20px;
}
.nvb-tab-box > h3 {
  font-size: 24px;
  color: #1d0e3d;
  line-height: 53px;
  margin: 15px 0;
}
.right .toggle-content-box {
  right: auto;
}
.right .toggle-content-box:before {
  right: auto;
  left: 9px;
}
/* ================= Dashboard video4 end =================  */
/* ================= Dashboard appointments history start ================  */
.appointment-history-box {
  padding: 0 5px;
}
.seprator-text-row {
  width: 100%;
  margin-bottom: 25px;
  overflow: hidden;
}
.seprator-text-row h4 {
  display: inline-block;
  font-size: 18px;
  color: #180058;
  margin-bottom: 0;
  position: relative;
  padding: 0 30px 0 80px;
}
.seprator-text-row h4:before {
  content: '';
  width: 100vw;
  height: 1px;
  background: #e5e5e5;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
}
.seprator-text-row h4:after {
  content: '';
  width: 50px;
  height: 1px;
  background: #e5e5e5;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.nptb-left {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.nptb-left p {
  margin-top: auto;
}
.nptb-right {
  padding-left: 25px;
  display: flex;
  flex-flow: column;
  height: 100%;
}
.nptb-right strong {
  font-size: 14px;
  color: #180058;
  display: inline-block;
  margin-top: auto;
}
.nptb-left h5 > i {
  margin-left: 10px;
}
.yellow-tag {
  background: #f1964f !important;
}
.appointment-history-box ul + .seprator-text-row {
  margin-top: 25px;
}

/* ================= Dashboard appointments history end =================  */
.appointment-modal .modal-dialog {
  max-width: 626px;
}
.appointment-modal .form-group {
  margin-bottom: 15px;
}
.appointment-form .form-group:last-child {
  margin-bottom: 0;
}
.appointment-modal textarea {
  height: 180px;
}
.appointment-modal .btn {
  min-width: 219px;
}
.appointment-modal.custom-modal h5 {
  margin-bottom: 20px;
}
.appointment-form {
  margin-bottom: 25px;
}
.appointment-modal .custom-modal-btn .btn {
  margin-bottom: 0;
}
.personal-details-modal .modal-content {
  border-radius: 16px;
  background: #582bb8;
  color: #fff;
  padding: 61px 62px 73px;
}
.personal-details-modal .modal-body {
  padding: 0;
}
.personal-details-modal .pdm-icon {
  margin-bottom: 33px;
}
.personal-details-modal h5 {
  font-size: 21px;
  margin-bottom: 19px;
  font-family: 'Montserrat', sans-serif;
}
.personal-details-modal {
  font-size: 18px;
}
.personal-details-modal .btn-default {
  margin-top: 33px;
}
.personal-details-modal h5 + p {
  margin-bottom: 0;
}
.walkin-person-details .pd-text {
  opacity: 1;
  visibility: visible;
}
.dashboard-main-heading {
  display: none;
}
@media (max-width: 1790.98px) {
  .intro-block h4,
  .heading4 h5 {
    font-size: 55px;
  }
  .intro-block-heading h4 {
    margin-bottom: 0;
    font-size: 55px;
  }
}
@media (max-width: 1670.98px) {
  .my-diary-box .mdb-head h4,
  .my-profile-box h4,
  .dsp-head h4 {
    font-size: 22px;
  }
  .outer-container {
    padding-top: 125px;
  }
  .header-upper {
    height: 45px;
  }
  .header-upper-right {
    padding-right: 0;
  }
  ul.query-list li > img {
    width: 16px;
  }
  .header-lower .navbar {
    height: 80px;
  }
  .header-lower .navbar-brand {
    width: 130px;
  }
  .header-lower .navbar-expand-xl .navbar-nav .nav-item {
    margin: 0 15px;
  }
  .header-lower .header-right {
    margin-left: 20px;
  }
  .header-btn {
    min-width: 170px;
    padding: 14px 15px;
  }
  .footer-logo-area {
    padding-left: 0;
    width: 135px;
  }
  .footer-upper-content {
    margin-left: 10%;
  }
  ul.footer-nav {
    margin-bottom: 50px;
  }
  .fuc-box:first-child,
  ul.footer-nav li:first-child,
  .fuc-box:nth-child(2),
  ul.footer-nav li:nth-child(2),
  .fuc-box:last-child,
  ul.footer-nav li:last-child {
    width: 33.33%;
  }
  .footer-nav-link {
    font-size: 16px;
    padding: 8px 15px;
  }
  .fuc-box {
    font-size: 18px;
  }
  .fuc-icon {
    margin-bottom: 25px;
  }
  .fuc-box h4 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .social-link svg {
    width: 28px;
  }
  .footer-lower p.copyright {
    padding-right: 0;
  }
  .intro-text-box {
    padding: 0px 5% 0px 0;
  }
  .intro-text-box h3 {
    font-size: 46px;
    line-height: 1.2;
    margin-bottom: 30px;
  }
  .intro-text-box p {
    font-size: 20px;
    line-height: 28px;
  }
  .intro-btn-group {
    padding-right: 50px;
  }
  .btn.btn-default {
    font-size: 16px;
  }
  .intro-btn-group h6 {
    font-size: 18px;
    line-height: 25px;
    margin-left: 30px;
  }
  .heading h4 {
    font-size: 48px;
  }
  .heading h5 {
    font-size: 36px;
  }
  ul.experts-list {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  ul.experts-list li.expert-item {
    padding: 15px 15px 0;
  }
  .expert-box h4 {
    font-size: 18px;
  }
  .expert-box p {
    font-size: 15px;
  }
  .expert-img {
    margin-bottom: 15px;
  }
  ul.grid-list {
    min-height: 350px;
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .grid-disc {
    padding: 50px 25px;
  }
  ul.block-list {
    width: calc(100% + 30px);
    margin-left: -15px;
    min-height: 425px;
  }
  ul.block-list li.block-item {
    padding: 25px 15px;
  }
  .block-text-box h4 {
    font-size: 34px;
  }
  .block-bg {
    padding: 50px 35px 60px;
  }
  .block-text-box {
    max-width: 340px;
    margin-bottom: 25px;
  }
  .block-box p {
    font-size: 18px;
    line-height: 30px;
  }
  ul.home-services-list {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  ul.home-services-list li.hsl-item {
    padding: 30px 15px;
  }
  .hsl-content {
    padding: 25px 25px 25px;
  }
  .query-intro-box h3 {
    font-size: 46px;
    margin-bottom: 30px;
  }
  .query-intro-box h3 > span {
    font-size: 86px;
  }
  ul.helping-member-list li.hm-item {
    max-width: 240px;
  }
  .hm-box {
    font-size: 22px;
  }
  .hm-img {
    height: 270px;
    margin-bottom: 15px;
  }
  .hm-icon {
    width: 22px;
    height: 22px;
  }
  ul.helping-member-list li.hm-item + li.hm-item {
    margin-left: 30px;
  }
  .hm-img > img {
    object-fit: cover;
  }
  .media-box .play-btn {
    width: 60px;
  }
  .intro-textarea-content {
    max-width: 630px;
  }
  .intro-textarea-content textarea.form-control {
    padding: 30px 30px;
    font-size: 22px;
    height: 285px;
  }
  .new-btn-group {
    margin-top: 40px;
  }
  .new-btn-group .btn + .btn {
    margin-left: 35px;
  }
  .hero-container {
    height: auto;
  }
  .hero-content {
    padding-top: 170px;
    padding-right: 0%;
  }
  .hero-content h3 {
    font-size: 50px;
    padding-bottom: 15px;
    line-height: 1;
    top: 30px;
  }
  .member-detail-head {
    padding: 15px 0;
  }
  .member-detail-body {
    padding: 35px 0 50px;
  }
  .member-detail-head h6 {
    font-size: 22px;
  }
  .md-close-icon {
    width: 15px;
    height: 25px;
  }
  ul.new-dot-list li {
    font-size: 20px;
    padding: 0 20px;
  }
  .home-service-slider .slick-prev {
    left: -6%;
  }
  .home-service-slider .slick-next {
    right: -6%;
  }
  .content-container.media-container,
  .content-container.home-service-container {
    padding: 60px 0;
  }
  .content-container.home-block-container {
    padding: 60px 0 40px;
  }
  .content-container.block-intro-container {
    padding: 60px 0 90px;
  }
  .profile-card-content {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .profile-card-content .pc-content-left,
  .profile-card-content .pc-content-right {
    padding: 0 15px;
  }
  .profile-card-box + .profile-card-box {
    margin-top: 30px;
  }
  .profile-upper {
    padding: 24px 50px 45px 30px;
  }
  .profile-mid {
    padding: 30px 30px 40px;
  }
  .pcb-head {
    padding: 20px 30px 15px;
  }
  .profile-card-content ul:not([class]) li {
    position: relative;
    padding: 15px 30px;
    margin-bottom: 12px;
  }
  ul.award-list li {
    padding: 15px 20px 30px;
  }
  .profile-upper p {
    font-size: 18px;
    line-height: 40px;
  }
  .profile-mid p {
    font-size: 18px;
  }
  .pcb-query-text p {
    font-size: 16px;
  }
  .profile-card-content {
    font-size: 18px;
  }
  .profile-card-content h6 {
    font-size: 18px;
  }
  .profile-card-content h5 {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .doc-intro-desc h5 {
    font-size: 28px;
  }
  .doc-intro-desc h6 {
    font-size: 18px;
  }
  .showall-btn {
    right: 25px;
    bottom: 10px;
    font-size: 16px;
  }
  .profile-lower {
    padding: 15px;
  }
  .award-img-box {
    width: 50px;
  }
  .award-text-desc {
    padding-left: 20px;
  }
  .right-content {
    font-size: 14px;
  }
  .profile-upper h5,
  .profile-mid h5 {
    margin-bottom: 30px;
  }
  ul.pcb-query-list li {
    padding: 23px 42px 23px;
  }
  .container.container1 {
    width: calc(100% - 150px);
  }
  li .new-service-box .new-sb-img {
    width: 100px;
    margin-bottom: 25px;
  }
  li .new-service-box p {
    font-size: 15px;
    line-height: 1.4;
  }
  ul.new-service-list li .new-service-box .new-sb-img {
    width: 98px;
  }
  ul.new-service-list li .new-service-box p {
    font-size: 18px;
    line-height: 1.3;
  }
  .block-content-box .block-right h5 {
    font-size: 42px;
    margin-bottom: 12px;
    line-height: 1.2;
  }
  .block-content-box .block-right p {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .bl-img-box .block-text-box h4 {
    font-size: 20px;
  }
  .bl-item h6 {
    font-size: 18px;
    width: calc(100% - 80px);
    padding-left: 20px;
    word-break: break-all;
  }
  .bl-icon {
    width: 80px;
    padding: 20px 10px;
  }
  .bl-box h3 {
    margin-bottom: 0;
    font-size: 17px;
    width: calc(100% - 51px);
    padding-left: 20px;
  }
  .block-content-outer h5 {
    font-size: 30px;
    line-height: 30px;
  }
  ul.bl-list .bl-item {
    padding: 0 10px;
    margin-bottom: 60px;
  }
  .block-container .block-right {
    padding: 60px 30px;
  }
  .bl-img-box .btn {
    min-width: 150px;
    padding: 10px 15px;
  }
  .bl-shape {
    top: 15px;
    right: 15px;
    height: 18px;
    width: 18px;
  }
  ul.bl-list {
    width: 100%;
    margin-left: 0;
  }
  .block-content-box .bl-box {
    padding: 20px 34px 20px 10px;
  }
  ul.bl-list.check-icon-list .bl-item {
    padding: 0 15px;
  }
  ul.bl-list.check-icon-list {
    width: 100%;
    margin-left: 0;
  }
  .bl-box .bl-img {
    width: 51px;
  }
  ul.founder-list li.founder-item {
    padding: 0 20px;
  }
  ul.founder-list .founder-box h4 {
    font-size: 18px;
  }
  ul.founder-list .founder-box p {
    font-size: 15px;
  }
  ul.founder-list {
    padding: 40px 0 0 0;
  }
  .content-container.block-box-container {
    padding: 60px 0 30px 0;
  }
  .block-box-content h3 {
    font-size: 28px;
  }
  .block-box-content p {
    font-size: 18px;
  }
  .intro-content h4 {
    font-size: 120px;
    line-height: 100px;
  }
  .intro-content h4 span {
    font-size: 70px;
    line-height: 120px;
  }
  .blockquote-box {
    padding: 55px 20px 85px 70px;
  }
  .blockquote-box h3 {
    font-size: 40px;
  }
  .blockquote-box:before {
    width: 85px;
    height: 120px;
  }
  .blockquote-box:after {
    width: 70px;
    height: 100px;
  }
  .blockquote-box p {
    font-size: 18px;
  }
  .intro-block h4,
  .heading4 h5 {
    font-size: 48px;
    letter-spacing: 0;
  }
  .heading4 h5 span {
    letter-spacing: 0;
  }
  .intro-block p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .intro-block-heading h4 {
    margin-bottom: 0;
    font-size: 55px;
  }
  .heading4 h5 span:before {
    margin-top: 0;
  }
  .intro-heading-block {
    margin-top: 0;
    padding-left: 0;
  }
  .intro-block-img {
    width: 300px;
  }
  .intro-block-heading {
    width: calc(100% - 300px);
    padding-left: 15px;
  }
  .intro-block .know-more-link {
    font-size: 18px;
  }
  .ib-head h5,
  .ib-head h5 + span {
    font-size: 22px;
  }
  .ib-body {
    padding: 60px 0;
    font-size: 18px;
    line-height: 30px;
  }
  .ib-body p {
    margin-bottom: 30px;
  }
  .pb-img:before {
    bottom: 15.52px;
  }
  .inner-hero-content-left span,
  .inner-hero-content-left h1 {
    font-size: 60px;
  }
  .inner-hero-content p {
    font-size: 18px;
    line-height: 30px;
  }
  .inner-hero-content a.btn.banner-btn {
    font-size: 18px;
  }
  .inner-hero-bg {
    margin-left: 0;
  }
  .dashboard-main-container,
  .dc-body {
    padding: 30px;
  }
  ul.dashboard-link-list li:not(:last-child) {
    margin-right: 25px;
  }
  ul.dashboard-link-list li > a {
    padding-left: 45px;
    line-height: 35px;
  }
  ul.dashboard-link-list li > a:before {
    width: 35px;
    height: 35px;
  }
  .dc-head-left h4,
  .dc-right-content h1,
  ul.dashboard-link-list li > a {
    font-size: 16px;
  }
  .dc-right-content p {
    font-size: 15px;
  }
  .dc-head-left h4 > span {
    font-size: 22px;
  }
  .dc-profile-img {
    width: 80px;
    height: 80px;
  }
  .dc-profile-intro > span {
    margin: 0 15px;
    font-size: 16px;
  }
  .wb-icon {
    width: 70px;
    margin: 0 auto 25px;
  }
  .wb-content-box h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .wb-content-box {
    padding: 45px;
    font-size: 16px;
  }
  .dc-block-box {
    padding: 30px 0;
  }
  .dc-block {
    padding: 0 0 0 30px;
  }
  .block-seprator {
    margin: 30px 0;
  }
  .tpc-upper {
    padding: 40px 30px 25px;
  }
  ul.documents-list {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  ul.documents-list li {
    width: 33.33%;
    padding: 15px;
  }
  .nav-tabs.nav-tabs2 .nav-link {
    font-size: 18px;
    padding: 0;
    line-height: 55px;
  }
  .chatbot-pannel-left h4,
  .chatbot-pannel-right h4,
  .timeline-box h4,
  .treatment > h4 {
    font-size: 22px;
  }
  .cpl-head h3,
  .cpl-head h2,
  .cpl-text-box h5,
  .cpl-intro-desc p strong,
  .tpc-month-box h6 {
    font-size: 16px;
  }
  .cpl-wrapper-box,
  .cpl-box p small {
    font-size: 14px;
  }
  .cpl-icon-box {
    width: 70px;
  }
  .cpl-icon-box > img {
    width: 30px;
  }
  .chatbot-pannel-left h4,
  .chatbot-pannel-right h4 {
    font-size: 20px;
  }
  .tpc-box p {
    margin: 0 15px 0;
  }
  .tpc-icon {
    width: 70px;
  }
  .tpc-box {
    padding: 28px;
  }
  .tpc-box .btn {
    min-width: 130px;
    padding: 10px 15px;
  }
  .document-img {
    width: 40px;
  }
  span.document-intro {
    padding: 6px 10px;
  }
  .sp-doc-box {
    padding: 15px 25px;
    font-size: 18px;
  }
  .sp-doc-box > img {
    margin-right: 20px;
    width: 48px;
  }
  .sp-body {
    padding: 30px;
  }
  .sp-head {
    padding: 20px 30px 20px 30px;
  }
  .notes-box {
    padding: 14px 15px;
    font-size: 16px;
  }
  .notes-box > i {
    font-size: 24px;
  }
  .sidebar-box {
    width: 650px;
  }
  .sidebar-head h5,
  .check-box-group > label {
    font-size: 14px;
  }
  .close-img {
    width: 12px;
  }
  .sidebar-head {
    padding: 15px 25px;
    height: 50px;
  }
  .sidebar-body {
    padding: 25px;
    height: calc(100% - 130px);
  }
  .check-box-group > label {
    margin-bottom: 15px;
  }
  .check-box-group + .check-box-group {
    margin-top: 30px;
  }
  .radiobox-group .form-check:not(:last-child) {
    margin-right: 60px;
  }
  .sidebar-footer {
    padding: 15px;
    height: 80px;
  }
  .sidebar-footer .btn {
    font-size: 14px;
    min-width: 140px;
    padding: 12px 15px;
  }
  .sidebar-body .form-control {
    height: 45px;
    font-size: 14px;
  }
  .notes-pane-content {
    font-size: 14px;
  }
  .notes-pane-content > p {
    margin-bottom: 15px;
  }
  .np-mid span + span {
    margin-left: 40px;
  }
  .np-right a + a {
    margin-left: 15px;
  }
  .np-right a {
    padding: 8px 13px;
  }
  .dc-check-icon > img {
    width: 24px;
  }
  .clinician-hero-img {
    max-width: 450px;
  }
  .clinician-hero-content h4 {
    font-size: 18px;
    line-height: 32px;
  }
  .clinician-hero-content p,
  .blog-hero .clinician-hero-content p {
    font-size: 18px;
    line-height: 32px;
  }
  .prowess-text-box h3 {
    font-size: 46px;
    line-height: 1.2;
    margin-bottom: 30px;
  }
  .prowess-text-box p {
    font-size: 20px;
    line-height: 28px;
  }
  ul.prowess-list {
    padding-left: 0;
  }
  .prowess-item h6 {
    font-size: 17px;
    line-height: 28px;
  }
  .community-tabs .home-service-slider .slick-prev {
    left: -8%;
  }
  .community-tabs .home-service-slider .slick-next {
    right: -8%;
  }
  .sidebar-box {
    width: 650px;
  }
  .dairy-sidebar h5 {
    font-size: 20px;
  }
  .dairy-sidebar p {
    font-size: 15px;
  }
  .dairy-sidebar::placeholder {
    font-size: 14px;
  }
  .sidebar-head-right .icon {
    width: 15px;
  }
  .sidebar-head-right .icon {
    width: 15px;
  }
  .sidebar-head-right .btn.btn-default {
    font-size: 14px;
    min-width: auto;
    padding: 8px 15px;
  }
  .sidebar-head-right a + a {
    margin-left: 15px;
  }
  .sidebar-head-right > div {
    margin-right: 15px;
  }
  .mdb-content-head h2 {
    font-size: 22px;
    margin-bottom: 0;
    margin-right: 15px;
  }
  .mdb-content-head h2 + a {
    width: 20px;
  }
  .mdb-content-right b {
    font-size: 14px;
    margin-right: 25px;
  }
  .mdb-content-body h5 {
    font-size: 20px;
  }
  .mdb-content-body {
    font-size: 16px;
  }
  .mdb-text-box {
    font-size: 15px;
  }
  .mdb-intro-box h5 {
    font-size: 17px;
    margin-bottom: 3px;
  }
  .mdb-intro-box {
    font-size: 13px;
  }
  .mdb-sidebar-body h6 {
    font-size: 14px;
  }
  .mdb-sidebar-body {
    padding: 25px 0;
  }
  .mdb-sidebar .form-control {
    font-size: 14px;
  }
  .video-box .mcb-head {
    padding: 12px 18px;
  }
  .mcb-head .di-img {
    width: 45px;
    height: 45px;
  }
  .mcb-head .di-text-box {
    width: calc(100% - 45px);
  }
  .di-text-box h6 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .di-text-box,
  ul.doc-intro-list li h3 {
    font-size: 13px;
    line-height: 1.4;
  }
  ul.doc-intro-list {
    width: calc(100% + 50px);
    margin-left: -25px;
  }
  ul.doc-intro-list li.di-item {
    padding: 0 25px;
    font-size: 13px;
    line-height: 1.4;
  }
  .video-box .mcb-footer {
    padding: 15px 18px;
    font-size: 16px;
  }
  .mcb-link {
    font-size: 14px;
  }
  .mcb-link .img {
    width: 45px;
    height: 45px;
    margin-right: 8px;
  }
  .mcb-link .img > img {
    width: 20px;
  }
  .mcb-link + .mcb-link {
    margin-left: 30px;
  }
  .fullscreen-button {
    width: 48px;
    height: 48px;
  }
  .fullscreen-button > img {
    width: 25px;
  }
  .front-thumbnail-box {
    width: 150px;
    height: 90px;
    border-radius: 10px;
  }
  .videochat-tabs a img {
    width: 20px;
  }
  .videochat-tabs .nav-link b {
    font-size: 16px;
    margin-left: 10px;
  }
  .video-box .main-content-box {
    width: calc(100% - 500px);
  }
  .video-box .video-sidebar {
    width: 500px;
  }
  .vs-box ul.documents-list li {
    width: 50%;
  }
  .custom-accordion .ac-label {
    padding: 17px 100px 17px 30px;
  }
  .ac-label-img {
    width: 45px;
    height: 45px;
    margin-right: 20px;
  }
  .ac-label-img img {
    width: 20px;
  }
  .ac-label-text,
  .ac-label-right h4,
  .custom-accordion .ac-label {
    font-size: 14px;
  }
  .ac-label-text h3,
  .custom-accordion .article h6 {
    font-size: 16px;
  }
  .custom-accordion .article {
    font-size: 18px;
  }
  .doc-info-box {
    padding: 25px 40px;
  }
  .doc-info-upper .left,
  .block h6,
  .block .text-block {
    font-size: 14px;
  }
  .block .text-block h3,
  .mid .block,
  .block h5 {
    font-size: 16px;
  }
  .block .img-block {
    width: 45px;
    height: 45px;
    margin-right: 15px;
  }
  .block .text-block {
    width: calc(100% - 65px);
  }
  .doc-info-lower .mid .block {
    padding-left: 60px;
  }
  .custom-btn {
    width: 60px;
    height: 42px;
  }
  .dtb-mid {
    padding: 0 0 0 30px;
  }
  .btn2 + .btn2 {
    margin-left: 10px !important;
  }
  .tp-text-box,
  .tp-button-group {
    width: 50%;
    padding: 0 15px;
  }
  .tp-heading {
    font-size: 18px;
  }
  .tp-left-arrow,
  .tp-right-arrow {
    width: 20px;
    height: auto;
  }
  .tp-date-desc {
    font-size: 14px;
    padding: 0 30px;
  }
  .tp-time-intro {
    font-size: 16px;
  }
  .btn2 {
    font-size: 13px !important;
  }
  .dashboard-top-pannel {
    height: 50px;
  }
  .ppl-box {
    padding: 12px 15px;
    font-size: 14px;
  }
  .ppl-box > strong {
    font-size: 22px;
  }
  .pannel-left h4 {
    font-size: 18px;
  }
  .pannel-header {
    height: 125px;
  }
  .dashboard-main-pannel {
    height: calc(100vh - 200px);
  }
  .appointments-pannel-outer {
    height: calc(100% - 134px);
  }
  .person-details {
    padding: 10px 15px;
  }
  .tbm-box {
    padding: 40px 59px;
  }
  .tbm-head-content h5 {
    font-size: 19px;
  }
  .tbm-head {
    margin-bottom: 25px;
  }
  .tbm-head,
  .tbm-body > p {
    font-size: 16px;
  }
  .tbm-body > p {
    margin-bottom: 18px;
  }
  .custom-radio-outer .custom-control-label {
    font-size: 15px;
  }
  .tbm-footer .btn {
    font-size: 15px;
  }
  .dw-right .btn + .btn {
    margin-left: 15px;
  }
  .dw-right .btn-outline,
  .dw-right .btn-outline:focus,
  .dw-right .btn {
    font-size: 15px;
  }
  .dw-right .btn-outline,
  .dw-right .btn-default {
    min-width: 140px;
  }
  .btn-with-icon > img {
    width: 22px;
  }
  .dw-header h3 {
    font-size: 22px;
  }
  .dw-main-head .stv-radio-buttons-wrapper + p {
    font-size: 16px;
  }
  /*.dashboard-main-container{min-height:calc(100vh - 60px);}*/
  .dashboard-wrapper {
    height: calc(100vh - 130px);
  }
  .patients-details-intro .patients-img {
    width: 70px;
    height: 70px;
    margin-right: 18px;
  }
  .patients-details-box {
    width: calc(100% - 70px);
  }
  .patients-img.has-active:before {
    width: 14px;
    height: 14px;
  }
  .patients-details-upper h4 {
    font-size: 16px;
  }
  .patients-varify,
  .pdm-inner-box > span,
  .ddm-inner-box > span {
    font-size: 14px;
  }
  .pdm-inner-box > span,
  .ddm-inner-box > span {
    margin-right: 10px;
  }
  .patients-varify {
    margin: 0 15px;
  }
  .patients-details-head {
    padding: 20px 20px 20px 35px;
  }
  .patients-back-link {
    left: 12px;
  }
  .patients-details-info i {
    width: 13px;
  }
  .patients-varify > i {
    width: 15px;
  }
  .icon-box {
    width: 55px;
    height: 46px;
  }
  ul.list-with-icon li:not(:last-child) {
    margin-right: 15px;
  }
  .pd-right-dropdown {
    margin-left: 15px;
  }
  .icon-box > img {
    width: 24px;
  }
  .bottom-align .dropdown-item {
    font-size: 14px;
  }
  .patients-details-mid,
  .patients-details-upper {
    margin-bottom: 10px;
  }
  ul.experts-list.new-experts-list {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  ul.experts-list.new-experts-list li.expert-item {
    padding: 15px;
    width: 25%;
  }
  .expert-btn.viewmore-btn {
    margin-top: 30px;
  }
  .experts-container:last-child {
    padding-bottom: 90px;
  }
  .heading-with-line h3 {
    font-size: 28px;
  }
  .keywords-box {
    padding-top: 0;
  }
  .keywords-box .community-kw-list li {
    padding: 8px 10px;
  }
  .keywords-box ul.community-kw-list {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .keywords-box .community-kw-link {
    padding: 10px 12px;
    min-width: auto;
    font-size: 16px;
  }
  .keywords-container {
    padding: 180px 0 70px;
    margin-top: -140px;
  }
  .blog-content-box {
    width: 100%;
    margin-left: 0;
  }
  .blog-left {
    padding-right: 30px;
  }
  .blog-left .heading-with-line.grey h3 {
    font-size: 28px;
  }
  .blog-left .heading-with-line {
    margin-bottom: 45px;
  }
  .blog-box {
    padding: 25px;
  }
  .blog-left-pannel {
    padding-right: 25px;
  }
  .blog-left-pannel h3 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .blog-left-pannel p {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 15px;
  }
  .blog-left-lower .community-kw-link {
    padding: 7px 15px;
    min-width: 98px;
  }
  .blog-left-lower .community-kw-list li {
    padding: 5px 5px;
    font-size: 14px;
  }
  .br-content-box {
    padding: 48px 30px;
  }
  .br-seartch-bar .form-control {
    height: 50px;
    font-size: 16px;
    padding: 5px 25px;
  }
  .br-seartch-bar .form-group::after {
    background-size: 20px auto;
  }
  .br-content-box .community-kw-link {
    padding: 8px 15px;
    font-size: 15px;
  }
  .br-content-box .community-kw-list li {
    padding: 5px 5px;
  }
  .br-content-box .br-heading h3 {
    font-size: 30px;
  }
  .br-content-box .br-heading {
    margin-bottom: 30px;
  }
  ul.topic-list li .tl-link {
    padding: 12px 15px;
    font-size: 16px;
    min-width: 300px;
  }
  ul.topic-list li:not(:last-child) {
    margin-bottom: 15px;
  }
  .latest-article-box {
    padding: 10px 25px 12px 40px;
  }
  .latest-article-text h3,
  .latest-article-text {
    font-size: 16px;
  }
  .latest-article-sequence {
    font-size: 38px;
    top: 14px;
  }
  ul.latest-article-list li.latest-article-item {
    margin-bottom: 15px;
  }
  .blog-box + .blog-box {
    margin-top: 25px;
  }
  .blog-left-lower {
    margin-top: 15px;
  }
  .add-treatment-plan-panel {
    padding: 15px 25px;
  }
  .tp-head,
  .tpi-box {
    font-size: 14px;
  }
  .tpi-box input.form-control {
    width: 70px;
    margin: 0 12px;
  }
  .sidebar-body .tp-body .form-control {
    height: 60px;
  }
  .tp-body .badge-group .tag {
    font-size: 12px;
  }
  .tp-body .badge-group .add-more-field {
    font-size: 12px;
  }
  .emergency-contact-modal .modal-content {
    padding: 30px 40px;
  }
  .sidebar-left p,
  .sidebar-head h5,
  .previous-arrows,
  .next-arrows,
  .sidebar-content-box p,
  .sidebar-info-box p {
    font-size: 12px;
  }
  .previous-arrows img,
  .next-arrows img {
    width: 22px;
  }
  .sidebar-info-box h5 {
    font-size: 16px;
  }
  .sidebar-info-box,
  .sidebar-content-box {
    padding: 15px 0;
  }
  .sidebar-content-box h6 {
    font-size: 12px;
    line-height: 1.2;
  }
  ul.new-profile-list li.np-item:not(:last-child) {
    margin-bottom: 25px;
  }
  ul.new-profile-list li.np-item {
    padding: 12px 22px;
  }
  .np-right a > img {
    width: 20px;
  }
  .scb-content {
    padding-right: 11px;
    padding-left: 10px;
  }
  .scb-img {
    left: 0px;
  }
  .scb-img {
    width: 5px;
  }
  .add-treatment-plan-panel {
    padding: 15px 30px;
  }
  .pr-body .tab-content {
    padding: 15px;
  }
  .tpc-month-box {
    margin: 20px 0;
  }
  .slb-link-box {
    padding: 15px;
  }
  .slb-img {
    width: 40px;
    height: 40px;
  }
  .slb-img > img {
    width: 16px;
  }
  .slb-text h3 {
    font-size: 18px;
  }
  .slb-text > div {
    min-width: 225px;
  }
  .slb-link-box:before {
    width: 15px;
    height: 15px;
  }
  .sic-body {
    padding: 0px 25px 15px;
  }
  .sic-add-icon {
    width: 40px;
    height: 40px;
  }
  .sic-add-icon > img {
    width: 15px;
  }
  .mcbp-desc h4 {
    font-size: 13px;
  }
  .mcbp-right .pdm-inner-box span {
    font-size: 11px;
  }
  .mcbp-desc .pd-right-content strong {
    font-size: 12px;
    line-height: 24px;
  }
  .video-box2 .call-end h4 {
    font-size: 22px;
  }
  .video-box2 .btn {
    padding: 10px 15px;
  }
  .ih-box h3 {
    font-size: 18px;
  }
  .ih-box h3 > svg {
    width: 22px;
  }
  .icon i > img {
    width: 15px;
  }
  .pd-text .btn,
  .pd-right .btn {
    padding: 10px 15px;
    min-width: 110px;
    font-size: 12px;
  }
  .person-text-box h3,
  .pd-text {
    font-size: 16px;
  }
  .rate-count {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    line-height: 20px;
    font-size: 12px;
  }
  .mcbp-desc h4 {
    font-size: 13px;
  }
  .mcbp-desc .pd-right-content strong {
    font-size: 12px;
    line-height: 24px;
  }
  .video-box2 .call-end h4 {
    font-size: 22px;
  }
  .video-box2 .btn {
    padding: 10px 15px;
  }
  .rg-row-content-left {
    width: 18%;
  }
  .rg-row-content-right {
    width: 82%;
  }
  .personal-details-modal .pdm-icon {
    width: 90px;
    margin: 0 auto 33px;
  }
}
@media only screen and (max-width: 1390px) and (min-width: 992px) {
  .rg-bar-icon-outer {
    margin-top: 10px;
  }
}
@media (max-width: 1400.98px) {
  .sidebar-box {
    width: 589px;
  }
  .radiobox-group .form-check:not(:last-child) {
    margin-right: 30px;
  }
  .profile-lower h4 {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .profile-lower .pl-copy-link {
    font-size: 15px;
    margin-left: 15px;
  }
  .pl-copy-link img {
    width: 25px;
    margin-right: 5px;
  }
  .profile-card-content h5 {
    font-size: 22px;
  }
  .container.container1 {
    width: calc(100% - 100px);
  }
  ul.award-list li {
    padding: 15px 80px 30px 20px;
  }
  .profile-card-content ul:not([class]) li {
    padding: 15px 80px 15px 30px;
  }
  .block-content-box .block-right h5 {
    font-size: 40px;
  }
  .block-content-box .bl-box {
    padding: 20px 15px;
  }
  .bl-box h3 {
    font-size: 16px;
    padding-left: 7px;
  }
  .intro-content h4 {
    font-size: 100px;
    line-height: 90px;
  }
  .intro-content h4 span {
    font-size: 60px;
    line-height: 100px;
  }
  .blockquote-box {
    padding: 40px 10px 70px 50px;
  }
  .blockquote-box:before {
    width: 64px;
    height: 85px;
  }
  .blockquote-box:after {
    width: 50px;
    height: 90px;
  }
  .intro-block h4,
  .heading4 h5 {
    font-size: 42px;
  }
  .intro-block-heading h4 {
    font-size: 40px;
  }
  .intro-block-img {
    width: 250px;
  }
  .intro-block-heading {
    width: calc(100% - 250px);
    padding-left: 10px;
  }
  .pb-img:before {
    bottom: 13.52px;
    height: 3px;
  }
  ul.dashboard-link-list li:not(:last-child) {
    margin-right: 20px;
  }
  ul.dashboard-link-list li:not(:last-child) {
    margin-right: 18px;
  }
  ul.dashboard-link-list li > a {
    padding-left: 32px;
    line-height: 25px;
  }
  ul.dashboard-link-list li > a:before {
    width: 25px;
    height: 25px;
  }
  .dc-head-left h4,
  ul.dashboard-link-list li > a,
  .dc-profile-intro > span,
  .dc-right-content h1 {
    font-size: 15px;
  }
  .dc-right-content p {
    font-size: 14px;
  }
  .dc-head-left,
  .dc-head-right {
    width: auto;
  }
  .dc-head-left h4 > span {
    font-size: 20px;
  }
  .nav-tabs.nav-tabs2 .nav-link {
    font-size: 16px; /*padding:0 8px;*/
  }
  .documents-list h4 {
    font-size: 14px;
  }
  .notes-box {
    padding: 12px 15px;
    font-size: 14px;
  }
  .clinician-hero-img {
    max-width: 420px;
  }
  .clinician-dot.active > span {
    font-size: 28px;
  }
  .prowess-item h6 br {
    display: none;
  }
  .cta-content h3 {
    font-size: 50px;
    line-height: 65px;
  }
  .caregivers-box,
  .caregivers-content-inside {
    max-width: 400px;
  }
  .caregivers-doc-img {
    width: 320px;
    bottom: -9px;
  }
  .mid .block + .block,
  .doc-info-upper .right .block + .block {
    margin-left: 15px;
  }
  .doc-info-upper {
    padding-bottom: 15px;
  }
  .doc-info-lower {
    padding-top: 15px;
  }
  .doc-info-box {
    padding: 25px 30px;
  }
  .doc-info-upper .right,
  .doc-info-lower .right {
    padding-left: 15px;
  }
  .blog-hero-text {
    top: 11.2%;
    left: 44%;
  }
  ul.earning-list {
    width: calc(100% + 16px);
    margin-left: -8px;
  }
  li.earning-item {
    padding: 8px;
  }
}

/* ========================================== 
! Large devices (desktops, less than 1200px)
=========================================== */
@media (max-width: 1199.98px) {
  .container-fluid {
    max-width: calc(100% - 0px);
  }
  .content-container {
    padding: 45px 0;
  }
  .header {
    box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
  }
  .navbar {
    padding: 0 15px;
    width: 100%;
    height: 80px;
  }
  .header-lower .navbar-toggler {
    width: 54px;
    height: 60px;
    border-radius: 0;
    cursor: pointer;
    text-decoration: none;
    padding: 0;
    border: 0;
    background: transparent;
    margin: 0;
    position: absolute;
    right: 3px;
    top: 50%;
    z-index: 999;
    transform: translateY(-50%);
  }
  .header-lower .navbar-toggler:not([class='collapsed']),
  .header-lower .navbar-toggler:not([class='collapsed']):focus,
  .header-lower .navbar-toggler:not([class='collapsed']):hover {
    background: transparent;
  }
  .header-lower .navbar-toggler.collapsed,
  .header-lower .navbar-toggler.collapsed:focus,
  .header-lower .navbar-toggler.collapsed:hover {
    background: transparent;
  }
  .header-lower .navbar-toggler .navbar-toggler-icon {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 3px;
    color: #243238;
    text-indent: -55px;
    margin-top: 0;
    background: transparent !important;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    vertical-align: middle;
  }
  .header-lower .navbar-toggler .navbar-toggler-icon:before,
  .header-lower .navbar-toggler .navbar-toggler-icon:after {
    content: '';
    width: 30px;
    height: 3px;
    background: #f68e3f;
    position: absolute;
    left: 0;
    transition: all 0.2s ease-out;
  }
  .header-lower .navbar-toggler.collapsed .navbar-toggler-icon {
    background: #f68e3f !important;
  }
  .header-lower .navbar-toggler.collapsed .navbar-toggler-icon:before,
  .header-lower .navbar-toggler.collapsed .navbar-toggler-icon:after {
    background: #f68e3f;
  }
  .header-lower .navbar-toggler.collapsed .navbar-toggler-icon:before {
    top: -10px;
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  .header-lower .navbar-toggler .navbar-toggler-icon:before {
    top: 0;
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }
  .header-lower .navbar-toggler.collapsed .navbar-toggler-icon:after {
    bottom: -10px;
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  .header-lower .navbar-toggler .navbar-toggler-icon:after {
    bottom: 0;
    -webkit-transform: rotateZ(-45deg);
    -moz-transform: rotateZ(-45deg);
    -ms-transform: rotateZ(-45deg);
    -o-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
  }
  .header-right {
    /*position:relative;*/
    z-index: 999;
    margin-right: 50px;
  }
  .header-lower .navbar-collapse,
  .navbar-collapse.collapsing {
    position: fixed;
    top: 105px;
    left: 0;
    background: #f7f7f7;
    padding: 0;
    z-index: 99;
    margin: 0;
    border: 0;
    overflow-y: auto;
    bottom: 0;
    width: 100%;
    height: 0;
    display: block !important;
    transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
  .header-lower .navbar .collapse.show {
    height: calc(100vh - 105px);
  }
  .header-lower .navbar-inside {
    height: calc(100vh - 105px);
    padding: 10px 15px;
    display: flex;
    flex-flow: column;
  }
  .header-lower .navbar-expand-xl .navbar-nav .nav-item {
    padding: 0;
    margin: 0;
  }
  .header-lower .navbar-expand-xl .navbar-nav .nav-link {
    line-height: 40px;
    padding: 0;
    position: relative;
    color: #333;
    text-align: center;
    font-size: 18px;
  }
  .navbar-nav.sm-collapsible .sub-arrow {
    border: 0;
    margin: 0;
    width: auto;
    font-size: 30px;
    line-height: 40px;
    top: 0;
    height: 40px;
    color: #f68e3f;
    width: 40px;
  }
  .navbar-nav .dropdown-menu {
    border: 0;
    background: transparent;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    padding: 0;
    margin: 0;
  }
  .nav-inside {
    width: 100%;
    padding: 0 15px;
  }
  .header-upper {
    height: 35px;
  }
  ul.query-list li > img {
    width: 18px;
    margin-right: 7px;
  }
  .header-lower .navbar-brand {
    width: 98px;
  }
  .header-btn {
    min-width: 150px;
    padding: 10px 15px;
  }
  .header-lower .navbar {
    height: 70px;
  }
  .outer-container {
    padding-top: 105px;
  }
  .schedule-time {
    margin-top: 10px;
  }
  .member-content {
    padding: 45px 70px;
  }
  .member-content h2 {
    margin-bottom: 30px;
  }
  .member-content .search-box {
    margin-bottom: 45px;
  }
  .footer-upper-content {
    margin-left: 0%;
  }
  .intro-text-box {
    padding: 0;
  }
  .intro-text-box p br {
    display: none;
  }
  .intro-text-box h3 {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 25px;
  }
  .intro-text-box p {
    font-size: 18px;
    line-height: 26px;
  }
  .intro-img-box {
    max-width: 550px;
    margin-left: auto;
  }
  .intro-img {
    margin-bottom: 30px;
  }
  .grid-disc {
    padding: 34px 17px;
  }
  .block-text-box h4 {
    font-size: 28px;
  }
  ul.block-list {
    height: auto;
  }
  .home-service-slider .slick-prev {
    left: 15px;
  }
  .home-service-slider .slick-next {
    right: 15px;
  }
  .query-intro-box {
    padding-left: 50px;
    padding-top: 40px;
  }
  .query-intro-box:before {
    width: 40px;
    height: 85px;
  }
  .query-intro-box h3 {
    font-size: 35px;
  }
  .query-intro-box h3 > span {
    font-size: 66px;
  }
  .query-btn {
    max-width: 185px;
  }
  ul.helping-member-list li.hm-item + li.hm-item {
    margin-left: 25px;
  }
  ul.helping-member-list li.hm-item {
    max-width: 220px;
  }
  .hm-box {
    font-size: 20px;
  }
  .intro-textarea-content {
    max-width: 550px;
  }
  .intro-textarea-content textarea.form-control {
    padding: 25px;
    font-size: 20px;
    height: 230px;
  }
  .member-detail-head h6 {
    font-size: 20px;
  }
  .mdb-content {
    padding-left: 0;
  }
  .content-container.media-container {
    padding: 45px 0;
  }
  .content-container.home-service-container,
  .content-container.home-block-container {
    padding: 45px 0;
  }
  .content-container.block-intro-container {
    padding: 45px 0 80px;
  }
  .heading h4 {
    font-size: 38px;
  }
  .heading {
    margin-bottom: 30px;
  }
  .dashboard-icon {
    width: 16px;
    margin-right: 7px;
  }
  .registration-box {
    margin-top: 15px;
    font-size: 16px;
  }
  .profile-lower {
    flex-flow: column;
  }
  .profile-lower h4 {
    margin-bottom: 5px;
  }
  .profile-lower .pl-copy-link {
    margin-left: 0;
  }
  .doc-intro-box {
    padding: 0 15px;
  }
  .doc-intro-desc h6 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .container.container1 {
    width: 100%;
  }
  .doc-intro-desc h5 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .profile-card-content h5 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .profile-upper {
    padding: 24px 30px 35px 30px;
  }
  .profile-upper p {
    font-size: 16px;
    line-height: 35px;
  }
  .profile-mid p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .profile-card-content h6 {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .profile-card-content p {
    font-size: 16px;
  }
  .profile-card-content {
    font-size: 16px;
  }
  .doc-fee-desc > div {
    margin: 10px 0;
  }
  ul.query-list.mob-query-list li {
    color: #333;
    line-height: 40px;
    font-size: 16px;
    font-weight: 500;
  }
  ul.query-list.mob-query-list {
    flex-flow: column;
    display: flex;
  }
  ul.query-list.mob-query-list li:not(:last-child):before {
    content: none;
  }
  ul.query-list li {
    font-weight: 400;
  }
  .new-service-list .slick-dots li.slick-active button,
  .slick-dots li:hover button {
    background-color: #fff;
  }
  .new-service-list .slick-dots li button {
    border: 1px solid #fff;
  }
  ul.bl-list {
    width: 100%;
    margin-left: 0;
  }
  .bl-item h6 {
    font-size: 16px;
    padding-left: 15px;
  }
  .block-content-box .block-right h5 {
    font-size: 35px;
  }
  .block-content-box .bl-box {
    padding: 20px 10px;
  }
  .bl-box h3 {
    font-size: 15px;
  }
  .intro-block h4,
  .heading4 h5 {
    font-size: 40px;
  }
  ul.founder-list li.founder-item {
    padding: 0 10px;
  }
  .content-container.block-box-container {
    padding: 45px 0;
  }
  .block-box-content h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .block-box-content p {
    font-size: 16px;
    line-height: 30px;
  }
  .intro-content h4 {
    font-size: 80px;
    line-height: 60px;
  }
  .intro-content h4 span {
    font-size: 45px;
    line-height: 90px;
  }
  .blockquote-box h3 {
    font-size: 30px;
  }
  .blockquote-box:before {
    width: 65px;
    height: 80px;
  }
  .blockquote-box:after {
    width: 60px;
    height: 80px;
  }
  .intro-block-heading h4 {
    font-size: 40px;
  }
  .ib-body {
    padding: 45px 0;
  }
  .ib-body p {
    margin-bottom: 20px;
  }
  .inner-hero-content-left span,
  .inner-hero-content-left h1 {
    font-size: 45px;
  }
  .inner-hero-bg {
    margin-left: -5%;
  }
  .dashboard-top-bar .navbar-brand {
    margin-left: 45px;
  }
  .menu-toggle {
    display: block;
  }
  .dashboard-left-bar {
    left: -100%;
  }
  body.dashboard {
    padding-left: 0;
  }
  ul.dashboard-link-list li:not(:last-child) {
    margin-right: 0;
  }
  .dashboard-top-bar .navbar-brand {
    width: 75px;
  }
  .nav li {
    padding: 0 15px;
  }
  .profile-img {
    width: 30px;
    height: 30px;
    font-size: 12px; /*margin-left:0;*/
  }
  .dc-right-content {
    max-width: 100%;
  }
  .history .dc-left h4 {
    padding-left: 40px;
    padding-top: 40px;
    margin-bottom: 0;
  }
  .ipad-hidden {
    display: none !important;
  }
  .custom-menu .custom-dropdown {
    right: -12px;
  }
  .chatbot-pannel-left,
  .chatbot-pannel-right {
    width: 100%;
    padding: 0;
  }
  .cpl-wrapper-box + .cpl-wrapper-box {
    margin-top: 15px;
  }
  .cpl-wrapper-box {
    width: calc(50% - 30px);
    margin: 15px;
  }
  .cpl-wrapper-outer {
    width: calc(100% + 30px);
    margin-left: -15px;
    display: flex;
    flex-wrap: wrap;
  }
  .np-mid span + span {
    margin-left: 50px;
  }
  ul.new-profile-list li.np-item {
    padding: 12px 20px;
  }
  ul.stats-list li h6 {
    font-size: 18px;
  }
  ul.stats-list li p {
    font-size: 51px;
    line-height: 59px;
  }
  .clinician-hero-img {
    max-width: 360px;
  }
  .clinician-dot.dot1 > span {
    right: 25px;
  }
  .clinician-dot > span {
    font-size: 13px;
  }
  .clinician-dot.active > span {
    font-size: 18px;
  }
  .clinician-hero-content {
    padding: 0 0 55px;
  }
  .prowess-item {
    padding: 40px 10px;
  }
  .prowess-item h6 {
    font-size: 16px;
    line-height: 26px;
    padding-left: 25px;
  }
  .cta-content h3 {
    font-size: 40px;
    line-height: 55px;
  }
  .caregivers-content {
    font-size: 18px;
    line-height: 30px;
  }
  .caregivers-box,
  .caregivers-content-inside {
    max-width: 390px;
  }
  .caregivers-content-inside {
    padding: 45px;
  }
  .communinty-kw-content {
    padding-left: 2%;
  }
  .community-tabs .home-service-slider .slick-prev {
    left: 2%;
  }
  .community-tabs .home-service-slider .slick-next {
    right: 2%;
  }
  .community-tabs .nav-tabs {
    padding: 0%;
  }
  .dashboard-video .dashboard-main-container,
  .dashboard-video .dc-body {
    padding: 30px 0;
  }
  .video-box {
    overflow: visible;
    height: auto;
  }
  .video-box .main-content-box {
    width: 100%;
    margin-bottom: 30px;
  }
  .video-box .video-sidebar {
    width: 100%;
  }
  .vs-box .tab-content {
    height: auto;
  }
  .video-box.fullscreen .main-content-box {
    margin-bottom: 0;
  }
  .doc-info-box {
    padding: 20px;
  }
  .logo-area {
    width: auto;
  }
  .experts-container:last-child {
    padding-bottom: 60px;
  }
  .blog-hero .clinician-hero-img {
    margin: 0 auto;
  }
  .blog-hero .clinician-hero-content {
    padding: 0 0 55px;
  }
  .hsl-content {
    font-size: 18px;
  }
  .blog-left {
    padding-right: 0;
    width: 100%;
    margin-bottom: 60px;
  }
  .blog-right {
    width: 100%;
  }
  .pagination-container {
    padding-top: 35px;
  }
  .br-content-box {
    padding: 30px;
  }
  .video-box2 .main-content-box.main-content-box2 {
    width: 100%;
  }
  .video-box2 .video-sidebar.video-sidebar2 {
    width: 100%;
  }
  .video-box2 .main-content-box.main-content-box2 {
    width: 100%;
  }
  .video-box2 .video-sidebar.video-sidebar2 {
    width: 100%;
  }
  .new-video-box-left {
    width: 100%;
    padding: 0 15px;
  }
  .new-video-box-right {
    width: 100%;
    padding-left: 0;
    padding: 15px;
  }
  .new-video-box .mcb-head {
    height: auto;
  }
  .video-box .vs-box {
    height: 100%;
  }
  .vs-box .chat {
    height: auto;
  }
}

/*==========================================
! Medium devices (tablets, less than 992px)
========================================== */
@media (max-width: 991.98px) {
  .custom-modal.custom-modal3 .modal-dialog {
    max-width: 100%;
    margin: 5px;
  }
  .services footer {
    margin-bottom: 50px;
  }
  .member-left {
    width: calc(100vw - 440px);
    padding-left: 20px;
  }
  .member-right {
    width: 400px;
  }
  .photo-upload-modal .close {
    top: -40px;
    right: 5px;
  }
  .main-container {
    padding-left: 50px;
    padding-right: 50px;
  }
  .schedule-box {
    min-height: 52px;
  }
  .form-inline .form-group {
    display: block;
    text-align: left;
  }
  .form-inline .form-control,
  .form-inline label {
    display: block;
  }
  .form-inline .form-group > *:not(:last-child) {
    margin-bottom: 5px;
  }
  .location-menu {
    line-height: 65px;
    font-size: 11px;
  }
  .location-menu .dropdown-toggle > svg {
    width: 15px;
  }
  .location-menu span + svg {
    margin: 0;
  }
  .sb-left .pc-text-box {
    width: 100%;
  }
  .pills-content-box {
    padding-right: 0;
    text-align: center;
    padding: 10px;
    border-right: 1px solid #f2f2f2;
  }
  .cb-img-box {
    width: 60px;
    height: 60px;
  }
  .cb-text-box {
    width: calc(100% - 60px);
    padding: 0 15px;
  }
  .service-container {
    padding-top: 0;
    padding-bottom: 70px;
  }
  .sb-left {
    width: calc(100% + 30px);
    border: 1px solid #f2f2f2;
    margin-left: -15px;
    margin-bottom: 14px;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 5%);
  }
  .sb-left .vertical-nav.nav-pills .nav-link:last-child .pills-content-box {
    border-right: 0;
  }
  .sb-left .vertical-nav.nav-pills .nav-link {
    flex-grow: 1;
    white-space: nowrap;
  }
  .sb-left .nav-pills.vertical-nav {
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;
  }
  .cb-lower,
  .cb-upper {
    padding: 20px;
  }
  .mob-cb-btn {
    display: flex !important;
    margin-top: 15px;
  }
  .mob-cb-btn .btn {
    flex-grow: 1;
    min-width: 100px;
    font-size: 13px;
    padding: 9px 15px;
  }
  .cb-text-box h4 {
    font-size: 14px;
    letter-spacing: 0;
  }
  .cb-experience-box img {
    width: 10px;
  }
  .cb-experience-box {
    font-size: 11px;
  }
  ul.status-list li {
    font-size: 12px;
    letter-spacing: 0;
  }
  .cb-location-box {
    font-size: 11px;
    line-height: 1.2;
  }
  .btn-text {
    font-size: 11px;
    line-height: 1.2;
  }
  .active .pills-content-box:before,
  .pills-content-box:hover:before,
  .pills-content-box:before {
    content: none;
  }
  .pc-text-box h3 {
    margin-bottom: 0;
    font-size: 13px;
  }
  .vertical-nav.nav-pills .nav-link + .nav-link {
    margin-top: 0;
  }
  .sb-right {
    width: 100%;
    padding: 0;
  }
  .pi-exp {
    font-size: 11px;
  }
  .pi-fee-box {
    position: relative;
    right: auto;
    bottom: auto;
  }
  ul.fee-list li + li {
    position: relative;
    padding: 0 10px;
    font-size: 13px;
  }
  ul.fee-list li {
    padding: 0 10px 0 0;
    font-size: 15px;
  }
  .pi-body p {
    line-height: 27px;
    font-size: 11px;
    margin-bottom: 15px;
  }
  .pi-body h6 {
    font-size: 13px;
    margin-bottom: 10px;
  }
  .pi-body a {
    font-size: 12px;
  }
  .separator {
    margin: 15px 0;
  }
  .pi-text-box h4 {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .navbar-brand {
    width: 98px;
    margin: 0;
  }
  #header {
    box-shadow: none;
  }
  .location-menu > svg {
    width: 15px;
  }
  .avtar-admin-icon {
    margin-left: 15px;
    width: 18px;
  }
  .side-pannel-box {
    padding: 25px;
  }
  ul.tile-slot-list {
    overflow-y: scroll;
  }
  .data-card-box {
    padding: 5px 10px;
  }
  .side-pannel-container,
  .online-visit-sidebar,
  .tab-sidebar {
    min-width: 100%;
    right: -100%;
  }
  .ts-box {
    padding: 10px 35px;
  }
  .pi-body {
    padding: 0 0 15px 0;
  }
  .pane-btn .btn {
    min-width: 275px;
  }
  .slide-arrow {
    display: none !important;
  }
  ul.data-card-list {
    padding-right: 0px;
  }
  .pane-content-box + .pane-content-box {
    margin-top: 25px;
  }
  .pannel-intro-box {
    padding: 0 10px;
  }
  .search-box {
    border-color: #f2f2f2;
  }
  .search-input {
    color: #e0e0e0;
    font-size: 12px;
  }
  .search-input::placeholder {
    color: #e0e0e0;
  }
  .tab-sidebar-top h4 {
    font-size: 20px;
  }
  .tab-sidebar-top .icon-img {
    width: 20px;
    margin-right: 10px;
  }
  .tab-sidebar-top img {
    height: 20px;
    width: 20px;
  }
  .bottom-filter-block {
    position: fixed;
    z-index: 9999;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
    background: #fff;
    font-family: 'Open Sans', sans-serif;
  }
  .filter-block-content:before {
    content: '';
    width: 0.5px;
    height: calc(100% - 7px);
    background: #e0e0e0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .filter-block-content > div {
    flex-grow: 1;
    padding: 13px 0;
    cursor: pointer;
  }
  .filter-block .fb-text {
    font-size: 13px;
    letter-spacing: 0.57px;
    font-weight: 400;
    color: #26262a;
  }
  .filter-block .fb-icon {
    margin-right: 5px;
  }
  .filter-drop.dropdown .dropdown-menu {
    position: fixed !important;
    min-width: 100%;
    width: 100%;
    height: 100%;
    background: #fff;
    bottom: 0 !important;
    right: 0 !important;
    transform: none !important;
    margin: 0;
    border-radius: 0;
    border: 0;
    padding: 0;
  }
  .fd-head {
    width: 100%;
    height: 54px;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #333;
    text-transform: capitalize;
    padding: 16px 14px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
  }
  .fd-head .fd-icon {
    width: 24px;
    margin-right: 16px;
  }
  .fd-body {
    width: 100%;
    height: calc(100vh - 54px);
    overflow-y: auto;
  }
  .filter-tabs {
    width: 40%;
    background: #f8f8f8;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .fd-body .fd-content-box {
    width: 60%;
    padding: 30px;
  }
  .filter-tabs.nav-pills .nav-link.active,
  .filter-tabs.nav-pills .show > .nav-link {
    font-weight: 600;
    color: #621ac0;
    background-color: transparent;
    border-color: #621ac0;
  }
  .filter-tabs.nav-pills .nav-link {
    padding: 16px 15px;
    border-radius: 0;
    border-bottom: 2px solid transparent;
  }
  .form-check-group .form-check {
    padding-bottom: 15px;
    width: 50%;
  }
  .sortby-drop .dropdown-menu {
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    bottom: -1px;
    top: auto !important;
    min-width: 100%;
    width: calc(100% + 6px);
    margin-left: -3px;
    transform: none !important;
    border-radius: 0;
    border-top: 0;
  }
  .sortby-dropdown .dropdown.show:before {
    opacity: 1;
    visibility: visible;
  }
  .sortby-dropdown .dropdown:before {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
  }
  .sortby-dropdown .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
  }
  .sd-content-box h4 {
    font-size: 18px;
  }
  .sortby-dropdown .custom-control-label {
    width: 100%;
    position: static !important;
    font-size: 13px;
  }
  .sortby-dropdown .custom-control {
    padding-left: 0;
    padding: 5px;
  }
  .sortby-dropdown .custom-control-label::before,
  .sortby-dropdown .custom-control-label::after {
    right: 0 !important;
    left: auto;
    height: 16px;
    width: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .custom-control.heading {
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 15px;
  }
  .custom-control.heading h6 {
    margin-bottom: 0;
  }
  .custom-control-body {
    padding: 15px;
  }
  .sortby-drop.dropdown.show:before {
    opacity: 1;
    visibility: visible;
  }
  .sortby-drop.dropdown:before {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
  }
  .sortby-drop .custom-control.custom-radio {
    padding: 5px 0;
  }
  .sortby-drop .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #621ac0;
    background-color: #621ac0;
  }
  .header-logo-area {
    padding-left: 0;
  }
  .custom-radio-group,
  .custom-radio-group1 {
    overflow-x: auto;
    flex-wrap: wrap !important;
  }
  .custom-radio-group .custom-control-label,
  .custom-radio-group1 .custom-control-label {
    white-space: nowrap;
  }
  .custom-radio-group1 .custom-radio {
    width: auto;
    min-width: 80px;
  }
  .footer-logo-area {
    margin: 0 auto 25px;
  }
  .footer-upper-content {
    padding-top: 0;
  }
  ul.footer-nav {
    margin-bottom: 40px;
  }
  .fuc-icon {
    margin-bottom: 20px;
    width: 30px;
  }
  .fuc-box h4 {
    font-size: 18px;
  }
  .fuc-box {
    font-size: 16px;
  }
  .footer-lower p > img {
    margin-right: 5px;
    width: 15px;
  }
  .intro-img-box {
    margin: 0 auto;
  }
  .intro-text-box h3 {
    font-size: 35px;
  }
  .intro-text-box p {
    font-size: 16px;
    line-height: 24px;
    padding-right: 0;
  }
  .intro-text-box {
    text-align: center;
  }
  ul.experts-list.slick-slider {
    padding-bottom: 54px;
  }
  ul.experts-list li.expert-item {
    width: 33.33%;
  }
  .block-bg {
    padding: 35px 35px;
  }
  ul.helping-member-list {
    width: 100%;
  }
  .query-intro-box {
    max-width: 290px;
    margin: 0 auto 35px;
  }
  ul.helping-member-list li.hm-item {
    max-width: 100%;
  }
  .hm-img {
    height: auto;
  }
  .hm-img > img {
    object-fit: cover;
  }
  .fc-left h4 {
    font-size: 30px;
    line-height: 40px;
  }
  .fc-left {
    width: 310px;
    padding-right: 4%;
    padding-top: 0;
  }
  .fc-right h5 {
    font-size: 18px;
  }
  .fc-right label {
    font-size: 16px;
  }
  .form-popup .modal-content {
    padding: 30px 15px;
    min-height: auto;
  }
  .fc-right .btn {
    padding: 9px 15px;
    min-width: 120px;
    font-size: 16px;
  }
  .popup-content img {
    margin-bottom: 30px;
    width: 60px;
  }
  .popup-content h3 {
    font-size: 22px;
    line-height: 1.2;
  }
  .intro-textarea-content {
    max-width: 100%;
  }
  .new-btn-group {
    justify-content: center;
    margin: 35px 0;
  }
  .hero-container {
    height: auto;
    min-height: none;
  }
  .hero-content h3 {
    font-size: 40px;
    line-height: 1;
    top: 15px;
  }
  .hero-content {
    padding-top: 140px;
    padding-right: 0%;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .hero-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .hero-btn {
    font-size: 18px;
  }
  .member-detail-body {
    padding: 35px 0;
  }
  ul.new-dot-list li {
    font-size: 18px;
    padding: 0 15px;
  }
  .fc-right .form-control:focus + .label-placeholder,
  .fc-right .form-control:valid + .label-placeholder {
    font-size: 14px;
  }
  .profile-card-content .pc-content-left,
  .profile-card-content .pc-content-right {
    padding: 0 15px;
    width: 100%;
  }
  ul.pcb-query-list li,
  .pcb-head,
  .profile-mid,
  .profile-upper {
    padding: 30px 15px 0 15px;
  }
  ul.award-list li {
    padding: 15px 70px 15px 15px;
    display: flex;
    align-items: start;
  }
  .profile-card-content ul:not([class]) li {
    margin-bottom: 15px;
  }
  .profile-card-box + .profile-card-box {
    margin-top: 15px;
  }
  .registration-box > img {
    width: 18px;
    margin-right: 8px;
  }
  .profile-card-content .pc-content-left {
    margin-bottom: 15px;
  }
  .content-container.doctor-profile-container {
    padding-bottom: 30px;
  }
  .profile-card-content {
    transform: none;
  }
  .award-img-box {
    width: 40px;
  }
  .registration-box {
    margin-top: 10px;
    font-size: 12px;
  }
  .doc-intro-box .button-group .btn + .btn {
    margin-left: 0;
    margin-top: 10px;
  }
  .profile-card-content p {
    font-size: 16px;
    line-height: 35px;
    margin-bottom: 5px;
  }
  .profile-card-content h5 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .profile-card-content {
    font-size: 16px;
  }
  .profile-card-content h6 {
    font-size: 18px;
  }
  .profile-card-container {
    padding: 30px 0;
  }
  .profile-card-content ul:not([class]) li {
    padding: 15px 30px 10px 15px;
  }
  .doc-intro-box .button-group {
    margin-top: 30px;
  }
  .pcb-query-text h6 {
    margin-bottom: 0;
  }
  .block-content-outer h5 {
    font-size: 25px;
  }
  .block-container .block-left,
  .block-container .block-right {
    width: 100%;
  }
  .reverse ul.bl-list .bl-item {
    padding: 0 30px;
    width: 100%;
  }
  .block-container .block-right:before {
    width: 100%;
    margin-left: 0;
  }
  ul.bl-list.check-icon-list .bl-item {
    padding: 0 30px;
    width: 50%;
  }
  ul.new-service-list {
    width: 100%;
    margin-left: 0;
  }
  ul.bl-list {
    width: 100%;
    margin-left: 0;
  }
  .block-container .block-right {
    padding: 30px 15px;
  }
  ul.new-service-list br {
    display: none;
  }
  ul.bl-list .bl-item {
    width: 100%;
    padding: 0;
  }
  .block-content-box .block-right h5 {
    font-size: 35px;
  }
  .block-content-box .block-right p {
    font-size: 16px;
    line-height: 30px;
  }
  .reverse ul.bl-list .bl-item {
    padding: 0px;
    margin-bottom: 20px;
    width: 50%;
  }
  .block-content-box {
    padding: 20px 0;
  }
  .bl-item h6 {
    font-size: 16px;
    width: calc(100% - 80px);
  }
  .bl-shape {
    top: 15px;
    right: 15px;
    height: 15px;
    width: 15px;
  }
  .heading2 {
    margin: 30px 0 30px;
  }
  .mob-pt-0 {
    padding-top: 0 !important;
  }
  .bl-img-box .block-text {
    text-align: left;
  }
  .bl-img-box .btn {
    min-width: 150px;
    padding: 10px 15px;
  }
  .bl-img-box .block-text-box h4 {
    font-size: 18px;
  }
  .bl-img-box .block-bg {
    min-height: 260px;
  }
  .bl-img-box .block-text-box {
    margin-bottom: 15px;
  }
  ul.bl-list.check-icon-list {
    width: 100%;
    margin-left: 0;
    max-width: 560px;
    margin: 0 auto;
  }
  ul.bl-list.check-icon-list .bl-item {
    padding: 0px 10px;
    width: 50%;
    margin-bottom: 30px;
    margin-top: 0;
  }
  ul.new-service-list.slick-slider {
    padding-bottom: 40px;
  }
  ul.new-service-list li .new-service-box .new-sb-img {
    margin: 0 auto 25px;
  }
  .block-container .block-right:before {
    border-radius: 0;
  }
  .mob-mb-20 {
    margin-bottom: 20px !important;
  }
  ul.founder-list li.founder-item {
    padding: 0 5px;
  }
  ul.founder-list {
    padding: 20px 0 0 0;
  }
  .block-box-content p {
    font-size: 15px;
    line-height: 25px;
  }
  .block-box-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 30px;
  }
  .block-box-bg {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .block-box-content {
    padding: 30px 15px;
  }
  .blockquote-box:after {
    width: 40px;
    height: 75px;
  }
  .blockquote-box:before {
    width: 40px;
    height: 85px;
    top: 10px;
  }
  .blockquote-box {
    padding: 30px 10px 60px 30px;
  }
  .blockquote-box p {
    font-size: 18px;
    line-height: 30px;
  }
  .intro-content h4 {
    font-size: 60px;
    line-height: 45px;
  }
  .intro-content h4 span {
    font-size: 45px;
    line-height: 80px;
  }
  .intro-block h4,
  .heading4 h5 {
    font-size: 35px;
  }
  .intro-block {
    padding-right: 0;
  }
  .intro-block-heading h4 {
    font-size: 35px;
  }
  .heading4 h5 span {
    margin-left: 2px;
    padding-left: 10px;
  }
  .intro-block p {
    line-height: 28px;
    margin-bottom: 10px;
  }
  .intro-heading-block {
    margin-bottom: 25px;
  }
  .pb-img:before {
    content: none;
  }
  .inner-hero-content-box {
    padding: 50px 0 0 0;
    position: relative;
    text-align: center;
  }
  .inner-hero-content-left span,
  .inner-hero-content-left h1 {
    font-size: 30px;
  }
  .inner-hero-content-left span {
    flex-grow: 0;
    padding-bottom: 0;
    margin-left: 0px;
  }
  .inner-hero-content-left {
    width: 100%;
    margin-top: 0px;
    padding-left: 0px;
    justify-content: center;
    margin-bottom: 20px;
  }
  .inner-hero-content-right {
    width: 100%;
  }
  .inner-hero-content {
    max-width: 100%;
    padding-left: 0;
    padding-top: 0;
    margin-bottom: 20px;
  }
  .inner-hero-content a.btn.banner-btn {
    font-size: 16px;
  }
  .inner-hero-content .btn {
    min-width: 200px;
    padding: 10px 15px;
  }
  .inner-hero-img {
    width: 100%;
  }
  .inner-hero-bg {
    display: none;
  }
  .inner-hero-img img {
    margin-left: -30px;
  }
  .dc-body {
    flex-flow: column-reverse;
  }
  .dc-left,
  .dc-right {
    width: 100%;
  }
  .dc-right {
    margin-bottom: 30px;
  }
  .history .dc-left,
  .history .dc-right {
    width: 100%;
  }
  .history .dc-right {
    padding-top: 30px;
  }
  .history .dc-left h4 {
    padding-top: 0;
  }
  .history .form-group.custom-input {
    min-width: 100%;
  }
  .dc-block {
    padding: 0 40px 0 40px;
  }
  .mob-mt-15 {
    margin-top: 15px !important;
  }
  .np-img-box {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .np-right a > img {
    width: 22px;
  }
  .np-right a {
    padding: 8px 10px;
  }
  .np-right a + a {
    margin-left: 15px;
  }
  ul.stats-list li h6 {
    font-size: 16px;
  }
  ul.stats-list li p {
    font-size: 45px;
    line-height: 55px;
  }
  .dc-box .dc-left {
    width: 100%;
  }
  .dc-bottom-box {
    margin-top: 30px;
  }
  .my-profile-box .dc-box > div {
    flex-direction: column-reverse !important;
  }
  .my-profile-box .dc-right h3 {
    font-size: 18px;
  }
  .my-profile-box .dc-right {
    margin-bottom: 0;
  }
  .clinician-hero-container {
    padding-top: 30px;
    text-align: center;
  }
  .clinician-hero-img {
    margin: 0 auto;
  }
  .prowess-text-box h3 {
    font-size: 35px;
  }
  .prowess-text-box p {
    font-size: 16px;
    line-height: 24px;
    padding-right: 0;
  }
  .pc-outer > .card > .card-header {
    font-size: 20px;
    line-height: 30px;
  }
  li.caregivers-item {
    width: 100%;
  }
  .caregivers-box,
  li.caregivers-item:nth-last-child(2) .caregivers-box,
  li.caregivers-item:nth-last-child(1) .caregivers-box {
    margin-bottom: 100px;
  }
  .caregivers-box,
  .caregivers-content-inside {
    max-width: none;
  }
  .caregivers-content-inside {
    padding: 25px 30px;
  }
  .caregivers-num {
    font-size: 42px;
    line-height: 46px;
  }
  .caregivers-doc-img {
    position: relative;
    width: 240px;
    bottom: 0;
  }
  .content-container.community-qoute-conainer {
    padding: 50px 0;
  }
  .community-hero-content {
    padding-bottom: 40px;
  }
  .cq-content .heading h5 {
    font-size: 27px;
  }
  .cq-content {
    padding: 30px;
  }
  .cq-content blockquote {
    font-size: 21px;
    line-height: 27px;
  }
  .community-tabs .card-body {
    padding: 0px 0px 30px;
  }
  .community-tabs .nav-tabs {
    display: none;
  }
  .community-tabs .tab-pane {
    display: block !important;
    opacity: 1;
    margin-bottom: 3px;
    border: none;
  }
  .community-tabs .nav-link,
  .community-tabs .nav-tabs .nav-link {
    font-size: 15px;
  }
  .community-tabs .card-body::before,
  .community-tabs .card-body::after {
    display: none;
  }
  .community-tab-content .card-header {
    background: #432a7f;
    color: #fff;
    text-transform: uppercase;
    border: none;
    padding: 15px 20px;
  }
  .community-tab-content .card-header h5 {
    position: relative;
  }
  .community-tab-content .card-header h5::before {
    content: '\f067';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    font-family: 'font Awesome 5 Free';
    font-weight: 900;
    text-align: center;
  }
  .community-tab-content .active .card-header h5[aria-expanded='true']::before {
    content: '\f068';
  }
  .community-tabs ul.home-services-list {
    margin: 0;
    margin-left: -15px;
  }
  .communinty-kw-content {
    padding: 0;
    flex-flow: column-reverse;
  }
  .communinty-ckc-left,
  .communinty-ckc-right {
    width: 100%;
  }
  .community-pkw-content {
    padding: 0;
  }
  .community-kw-link {
    padding: 5px 15px;
  }
  .community-kw-list li {
    padding: 10px 7px;
  }
  .mh-sign-post-content {
    padding: 25px;
  }
  .mh-sign-post-link {
    font-size: 18px;
    padding: 20px;
    line-height: 25px;
  }
  .join-community-content {
    padding: 30px;
  }
  .community-tab-content .collapse {
    display: block !important;
    height: 0px;
    overflow: hidden;
  }
  .community-tab-content .collapse.show {
    height: auto !important;
  }
  .tpc-report ul.documents-list li {
    width: 33.33%;
  }
  .md-left {
    width: 100%;
    padding-right: 0;
    border-right: 0;
    margin-bottom: 25px;
  }
  .mdr-head {
    margin-bottom: 25px;
    padding: 0 0 20px 0;
  }
  .md-right {
    width: 100%;
  }
  .mdr-body {
    padding: 0px;
  }
  .my-diary-box .mdb-sidebar {
    width: 100%;
  }
  .my-diary-box .mdb-content {
    width: 100%;
  }
  .mdb-sidebar-head .form-group {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .mdb-sidebar-head .form-group {
    width: calc(100% - 8px);
  }
  .video-box .mcb-head {
    height: auto;
  }
  ul.doc-intro-list li.di-item:first-child,
  ul.doc-intro-list li.di-item {
    width: 100%;
  }
  ul.doc-intro-list li.di-item:not(:last-child):before {
    content: none;
  }
  .video-box .mcb-footer {
    height: auto;
    font-size: 14px;
  }
  .mcb-link .img {
    width: 35px;
    height: 35px;
    margin-right: 8px;
  }
  .mcb-link .img > img {
    width: 16px;
  }
  .mcb-link {
    font-size: 12px;
  }
  .mcb-link + .mcb-link {
    margin-left: 18px;
  }
  .front-thumbnail-box {
    width: 120px;
    height: 80px;
  }
  .fullscreen-button {
    width: 50px;
    height: 50px;
  }
  .fullscreen-button > img {
    width: 24px;
    margin-top: 3px;
  }
  ul.doc-intro-list li.di-item {
    padding: 0 25px;
  }
  ul.doc-intro-list li.di-item:not(:last-child) {
    margin-bottom: 15px;
  }
  .videochat-tabs a img {
    width: 20px;
  }
  .videochat-tabs .nav-link b {
    font-size: 16px;
  }
  .fullscreen .mcb-body {
    height: calc(100% - 80px) !important;
  }
  .doc-info-lower .left,
  .doc-info-upper .left,
  .doc-info-lower .mid,
  .doc-info-upper .mid,
  .doc-info-lower .right,
  .doc-info-upper .right {
    width: 100%;
  }
  .doc-info-upper .right,
  .doc-info-lower .right {
    padding-left: 0;
  }
  .doc-info-lower .mid .block {
    padding-left: 0;
  }
  .doc-info-upper .left > span {
    display: inline-block;
  }
  .doc-info-upper .mid,
  .doc-info-lower .mid {
    margin: 15px 0;
  }
  .pannel-left,
  .pannel-right {
    width: 100%;
  }
  .dashboard-top-pannel,
  .dashboard-main-pannel {
    height: auto;
  }
  .tp-text-box,
  .tp-button-group {
    width: 100%;
  }
  .custom-menu.notification-menu .custom-dropdown {
    right: 0;
  }
  .notify-box .notify-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 30px;
  }
  .notify-box .notify-content {
    width: calc(100% - 46px);
  }
  .notify-content p {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0px;
  }
  .notify-content small {
    font-size: 10px;
  }
  .notify-img > img {
    width: 15px;
  }
  .notifications-list {
    height: 100vh;
    overflow-y: auto;
  }
  .custom-menu.notification-menu .custom-dropdown:before {
    left: auto;
    transform: none;
    right: 85px;
  }
  .patients-details-mid,
  .patients-details-lower {
    width: 100%;
    margin-left: 0;
  }
  .pdm-inner-box,
  .ddm-inner-box {
    padding: 0;
    width: 100%;
  }
  .patients-details-head {
    align-items: flex-start;
  }
  .patients-details-box {
    width: 100%;
  }
  .patients-varify {
    margin: 5px 0;
  }
  .patients-back-link {
    top: auto;
    transform: none;
  }
  ul.experts-list.new-experts-list li.expert-item {
    width: 33.33%;
  }
  .experts-container:last-child {
    padding-bottom: 60px;
  }
  .keywords-box .community-kw-link {
    font-size: 15px;
  }
  .heading-with-line h3 {
    font-size: 26px;
  }
  ul.home-services-list.responsive-services-list li.hsl-item {
    padding: 15px;
    width: 33.33%;
  }
  .blog-left .heading-with-line {
    margin-bottom: 30px;
  }
  .msb-head {
    margin-bottom: 14px;
  }
  li.earning-item {
    width: 50%;
  }
  .np-mid {
    margin-bottom: 10px;
  }
  .rg-row-content-right,
  .rg-row-content-left {
    width: 100%;
  }
  .rg-row-outer {
    max-width: 600px;
  }
  .rg-row-content-left {
    margin-bottom: 15px;
  }
  .crf-content-box ul.checkbox-group-list li.checkbox-group-item {
    width: 33.33%;
  }
  .dtb-content {
    margin-bottom: 25px;
  }
  .plan-group-column {
    width: 100%;
  }
  .plan-group-column + .plan-group-column {
    margin-top: 25px;
  }
  .tp-button-group {
    margin-top: 25px;
  }
  .pannel-right {
    margin-top: 25px;
  }
  .tab-pane .head {
    padding: 15px 0;
  }
  .pannel-right .content-body {
    padding: 20px 30px;
  }
  /*.np-left .np-img-box{margin-right:15px;}*/
  .custom-mcb-link .mcb-link + .mcb-link {
    margin-left: 15px;
  }
  .number-input-field {
    margin: 15px 0;
  }
  .rg-bar-icon-outer {
    margin-top: 15px;
  }
  .rg-bar-icon {
    margin: 0 !important;
  }
  .np-box .np-left {
    width: 100%;
  }
  .np-box .np-mid {
    width: 100%;
  }
  .np-box .np-right {
    width: 100%;
    justify-content: center;
  }
  ul.fl-nav {
    margin-top: 10px;
    justify-content: center;
  }
}
@media (min-width: 992px) {
  .community-tabs .nav-tabs {
    display: flex;
  }
  .community-tabs .card {
    border: none;
  }
  .community-tabs .card .card-header {
    display: none;
  }
  .community-tabs .card .collapse {
    display: block;
  }
}

/* =================================================  
! Small devices (landscape phones, less than 768px)
================================================= */
@media (max-width: 767.98px) {
  .container.container1 {
    width: 100%;
  }
  .heading h4 {
    font-size: 35px;
  }
  .login-form {
    flex-flow: column;
    text-align: center;
  }
  .login-form .member-left {
    width: 100%;
    padding: 30px 30px 30px;
  }
  .login-form .member-right {
    width: 100%;
    padding: 15px;
  }
  .login-form .member-right-content {
    max-width: 100%;
    padding: 30px 20px;
    margin: 0;
    min-height: auto;
  }
  .login-form .form-group {
    max-width: 100%;
  }
  .otp-box {
    justify-content: center;
  }
  .fb-form .form-check {
    display: inline-block;
  }
  .fb-content {
    width: 100%;
  }
  .fb-form {
    width: 100%;
    padding-left: 0;
  }
  .fb-content {
    margin-bottom: 15px;
  }
  .ml-box h2 {
    font-size: 30px;
    font-family: 'Ramaraja', serif;
    font-weight: 500;
    letter-spacing: 2px;
    margin-bottom: 5px;
  }
  .ml-box p {
    font-size: 18px;
  }
  .login-form .form-group .btn {
    line-height: 20px;
  }
  .mr-head h2 {
    font-size: 22px;
    margin-bottom: 25px;
  }
  .member-container {
    padding: 30px 0;
  }
  .top-logo {
    width: 100%;
    text-align: center;
    position: relative;
    padding: 0;
  }
  .mr-bottom {
    position: relative;
    padding: 20px 30px 0px;
  }
  .photo-upload-left {
    width: 100%;
    max-width: 320px;
    margin: 0 auto 40px;
  }
  .photo-upload-right {
    width: 100%;
  }
  .nav-toggler {
    display: block;
  }
  .main-head {
    display: flex;
  }
  .leftbar {
    transform: translateX(-100%);
    width: 100%;
    z-index: 999;
    transition: all 0.2s ease-in;
  }
  .active .leftbar {
    transform: translateX(0%);
  }
  .main-container {
    width: 100%;
    margin-left: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
  .contract-content {
    line-height: 24px;
  }
  .member-content {
    padding: 30px 35px;
  }
  ul.appointment-type-list .at-item {
    width: 100%;
  }
  ul.appointment-type-list .at-item:not(:last-child):before {
    width: 100%;
    height: 1px;
    top: 100%;
    transform: none;
  }
  .at-box {
    padding: 25px 15px;
  }
  .card-top h3 {
    margin-bottom: 5px;
  }
  .filter-tabs {
    width: 30%;
  }
  .fd-body .fd-content-box {
    width: 70%;
  }
  .fd-body .fd-content-box .form-check {
    margin-bottom: 15px;
  }
  .content-container {
    padding: 35px 0;
  }
  .footer-upper {
    padding: 30px 0 25px;
  }
  .footer-upper-content {
    text-align: center;
  }
  .fuc-box:first-child,
  ul.footer-nav li:first-child,
  .fuc-box:nth-child(2),
  ul.footer-nav li:nth-child(2),
  .fuc-box:last-child,
  ul.footer-nav li:last-child {
    width: 100%;
    padding: 0;
  }
  .footer-nav li + li,
  .fuc-box + .fuc-box {
    margin-top: 15px;
  }
  ul.footer-nav {
    margin-bottom: 25px;
  }
  .fuc-icon {
    width: 25px;
    margin: 0 auto 10px;
  }
  ul.social-link {
    margin-top: 0;
    text-align: center;
    justify-content: center;
  }
  .fuc-box h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  ul.experts-list li.expert-item {
    width: 50%;
  }
  ul.grid-list {
    min-height: auto;
  }
  ul.grid-list li.grid-item {
    width: 50%;
  }
  .grid-disc {
    padding: 24px 12px;
    width: 60%;
  }
  ul.block-list {
    width: 100%;
    margin-left: 0;
  }
  ul.block-list li.block-item {
    width: 100%;
    padding: 0;
  }
  ul.block-list li.block-item:not(:last-child) {
    margin-bottom: 25px;
  }
  .block-text-box h4 {
    font-size: 25px;
  }
  .block-text-box {
    max-width: 100%;
    margin-bottom: 15px;
  }
  .block-bg {
    padding: 25px 25px 40px;
  }
  .block-text {
    text-align: center;
  }
  ul.helping-member-list li.hm-item + li.hm-item {
    margin-left: 0;
    margin-top: 30px;
  }
  ul.helping-member-list {
    flex-flow: column;
  }
  .fc-right {
    width: 100%;
  }
  .fc-left {
    width: 100%;
    padding-right: 30px;
  }
  .fc-left br {
    display: none;
  }
  .fc-left h4 {
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .form-popup .modal-dialog {
    padding: 15px;
  }
  .fc-right h5 {
    font-size: 16px;
  }
  .fc-right label {
    font-size: 13px;
  }
  .fc-right .form-group {
    margin-bottom: 30px;
  }
  .form-popup .close {
    width: 16px;
  }
  .fc-right .btn {
    padding: 5px 0px;
    min-width: 84px;
    font-size: 13px;
  }
  .popup-content img {
    margin-bottom: 15px;
    width: 60px;
  }
  .popup-content h3 {
    font-size: 16px;
    line-height: 1.2;
  }
  .hero-container {
    height: auto;
    max-height: 100%;
  }
  .hero-content h3 {
    font-size: 30px;
    position: relative;
    margin-left: 0;
    top: auto;
    margin-bottom: 25px;
  }
  .hero-content p {
    font-size: 16px;
  }
  .hero-btn {
    font-size: 16px;
    padding: 10px 25px;
  }
  .hero-content {
    text-align: center;
    padding: 40px 0;
  }
  .add-blue-shape:before {
    height: 300px;
    left: 0;
    top: -50px;
  }
  .helping-member-list {
    display: none;
  }
  .member-detail-container.tab-pane,
  .chatbot-body .tab-pane,
  .tab-content-box .tab-pane,
  .video-sidebar .tab-pane,
  .chatbot-body .tab-pane,
  .tab-content-box .tab-pane {
    display: block !important;
    opacity: 1;
  }
  .content-body:not(.show) {
    display: none;
  }
  .member-detail-container.fade.show,
  .chatbot-body .fade.show,
  .tab-content-box .fade.show {
    opacity: 1;
  }
  .member-detail-container.fade:not(.show),
  .chatbot-body .fade:not(.show),
  .tab-content-box .fade:not(.show),
  .video-sidebar.fade:not(.show),
  .chatbot-body .fade:not(.show),
  .tab-content-box .fade:not(.show) {
    opacity: 1;
  }
  .card-header {
    padding: 0 0 15px;
    background: transparent;
    border: 0;
  }
  .card-header:first-child {
    border-radius: 0;
  }
  .hm-img:before {
    z-index: 0;
  }
  .hm-img > img {
    position: relative;
  }
  .add-box-shadow {
    box-shadow: none !important;
    margin-bottom: 0;
  }
  .add-mob-box-shadow {
    margin-bottom: 20px;
  }
  .active .add-mob-box-shadow {
    box-shadow: 0 10px 8px rgb(0 0 0 / 26%);
  }
  .mob-no-padd-top {
    padding-top: 0 !important;
  }
  .mob-no-padd-bott {
    padding-bottom: 0 !important;
  }
  .query-intro-box h3 {
    font-size: 28px;
  }
  .query-intro-box h3 > span {
    font-size: 48px;
  }
  .query-intro-box {
    max-width: 245px;
    padding-left: 35px;
    padding-top: 35px;
    margin: 0 auto 25px;
    padding-right: 25px;
  }
  .query-intro-box:before {
    width: 25px;
    height: 55px;
    top: 15px;
  }
  .add-orange-shape:before {
    content: none;
  }
  .content-container.media-container {
    padding: 35px 0 0;
  }
  ul.experts-list li.expert-item {
    padding: 0px 15px 0;
  }
  ul.home-services-list li.hsl-item {
    padding: 0px 15px;
  }
  .content-container.home-block-container {
    padding: 0;
  }
  .content-container.block-intro-container {
    padding: 35px 0 35px;
  }
  .md-close-icon {
    display: none;
  }
  .right-content {
    margin-left: 15px;
  }
  .header-upper-right b {
    font-weight: 500;
  }
  .dashboard-icon {
    margin-right: 0;
  }
  .doc-img img {
    width: 100%;
  }
  .doc-img {
    max-width: 100%;
  }
  .doc-intro-box {
    padding: 15px 0px;
  }
  .doc-intro-desc h5 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .doc-intro-desc h6 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .location-pin-icon {
    width: 14px;
    margin-right: 6px;
  }
  .doc-intro-desc span {
    font-size: 14px;
  }
  ul.fee-list li {
    font-size: 14px;
  }
  .doc-fee-desc > div {
    margin: 10px 0;
  }
  .profile-card-content h5 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .profile-upper p {
    font-size: 14px;
    line-height: 30px;
  }
  .profile-mid p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .profile-lower .pl-copy-link {
    font-size: 12px;
    margin-left: 0;
  }
  .pl-copy-link img {
    width: 20px;
  }
  .profile-lower h4 {
    font-size: 17px;
    margin-bottom: 5px;
  }
  .pcb-query-text p {
    font-size: 14px;
  }
  .profile-card-content h6 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  ul.pcb-query-list li {
    padding: 15px 15px 15px 15px;
    align-items: baseline;
  }
  .pcb-head {
    padding: 30px 15px 0 15px;
  }
  .profile-mid {
    padding: 25px 15px 30px 15px;
  }
  .profile-upper {
    padding: 30px 15px 25px 15px;
  }
  .pcb-query-text h6 + p {
    margin-top: 0;
  }
  .pcb-query-icon {
    width: 20px;
    margin-right: 5px;
  }
  .profile-card-content p {
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 25px;
  }
  .profile-card-content ul:not([class]) li {
    margin-bottom: 0px;
  }
  .profile-card-content {
    font-size: 14px;
  }
  .profile-card-content ul:not([class]) li {
    padding: 15px;
    padding-right: 70px;
  }
  .showall-btn {
    right: 25px;
    font-size: 12px;
  }
  .award-img-box {
    width: 25px;
  }
  .award-text-desc {
    padding-left: 10px;
  }
  .pl-copy-link a {
    margin-right: 8px;
  }
  ul.pcb-query-list {
    padding-bottom: 30px;
  }
  .pcb-query-text h6 {
    margin-bottom: 5px;
  }
  .registration-box > img {
    width: 15px;
    margin-right: 5px;
  }
  .doc-fee-desc {
    font-size: 14px;
  }
  ul.fee-list li:not(:last-child):before {
    height: calc(100% - 10px);
  }
  .profile-card-content p:last-child {
    margin-bottom: 0;
  }
  a.header-right-box {
    line-height: 24px;
  }
  ul.query-list li {
    display: flex;
    align-items: center;
  }
  .block-content-box .block-right h5 {
    font-size: 22px;
  }
  .block-content-box .block-right p {
    font-size: 15px;
    line-height: 28px;
  }
  .reverse ul.bl-list .bl-item {
    margin-bottom: 10px;
  }
  .bl-icon {
    width: 60px;
    padding: 20px 10px;
  }
  .bl-shape {
    top: 17px;
    right: 15px;
    height: 10px;
    width: 10px;
  }
  .bl-item h6 {
    font-size: 14px;
    width: calc(100% - 60px);
  }
  .block-content-outer h5 {
    font-size: 18px;
    line-height: 20px;
  }
  .block-right .btn {
    min-width: 150px;
    padding: 10px 15px;
    font-size: 16px;
  }
  .bl-box .bl-img {
    width: 40px;
  }
  .bl-box h3 {
    font-size: 15px;
    width: calc(100% - 40px);
    padding-left: 10px;
  }
  ul.founder-list li.founder-item {
    padding: 0;
    width: 100%;
    margin-bottom: 15px;
  }
  .intro-content {
    text-align: center;
  }
  .blockquote-box:before {
    left: 0;
  }
  .blockquote-box p {
    font-size: 16px;
    line-height: 25px;
  }
  .blockquote-box {
    max-width: 100%;
    padding: 60px 0px;
  }
  .intro-content h4 {
    font-size: 35px;
    line-height: 30px;
  }
  .intro-content h4 span {
    font-size: 25px;
    line-height: 30px;
  }
  .blockquote-box h3 {
    font-size: 18px;
    line-height: 40px;
    margin-bottom: 0;
  }
  .intro-block h4,
  .heading4 h5 {
    font-size: 30px;
    margin-bottom: 0;
  }
  .heading4 h5 span:before {
    height: calc(100% - 10px);
    width: 3px;
  }
  .heading4 {
    margin-bottom: 10px;
  }
  .intro-block p {
    line-height: 25px;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .intro-block .know-more-link {
    font-size: 16px;
  }
  .intro-heading-block {
    flex-flow: column;
  }
  .intro-block-img {
    width: 100%;
    margin-bottom: 15px;
  }
  .intro-block-heading {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  .intro-block-heading h4 {
    font-size: 30px;
  }
  .ib-body {
    padding: 30px 0;
    font-size: 16px;
  }
  .ib-body p {
    margin-bottom: 15px;
    line-height: 25px;
  }
  .ib-head h5,
  .ib-head h5 + span {
    font-size: 18px;
  }
  .ib-head h5 + span:before {
    width: 3px;
    height: calc(100% - 3px);
  }
  .ib-head {
    background: #9a81cd;
    padding: 18px 0;
  }
  .pb-img:before {
    bottom: 11px;
  }
  .cb-btn {
    width: 100%;
  }
  .cb-btn {
    min-width: 100px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  .cb-btn .btn {
    min-width: 120px;
    padding: 10px 10px;
  }
  .cb-btn .btn + .btn {
    margin: 7px;
  }
  .cb-upper-content {
    width: 100%;
  }
  .fee-list {
    margin-left: 4px;
  }
  .intro-block-img img {
    max-width: 240px;
  }
  .inner-hero-container {
    margin-bottom: 0;
  }
  .dc-block .form-check {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .dc-block {
    padding: 0 30px 0 30px;
  }
  .tpc-box {
    padding: 15px;
  }
  .tpc-upper {
    padding: 25px 15px;
  }
  .tpc-box {
    text-align: center;
  }
  .tpc-icon {
    width: 60px;
    margin: 0 auto 10px;
  }
  .tpc-box p {
    margin: 0 0 10px;
  }
  .cpl-wrapper-box {
    width: 100%;
    margin: 0;
  }
  .cpl-wrapper-outer {
    width: 100%;
    margin-left: 0;
  }
  .chatbot-pannel-left,
  .chatbot-pannel-right {
    width: 100%;
    padding: 0 15px;
  }
  .nav-tabs.nav-tabs2 .nav-link {
    font-size: 16px;
  }
  .cpl-head h3,
  .cpl-head h2,
  .cpl-text-box h5,
  .cpl-intro-desc p strong,
  .tpc-month-box h6 {
    font-size: 14px;
  }
  .cpl-wrapper-box,
  .cpl-box p small {
    font-size: 12px;
  }
  .chatbot-pannel-left h4,
  .chatbot-pannel-right h4,
  .timeline-box h4,
  .treatment > h4 {
    font-size: 18px;
  }
  .chatbot-pannel-left {
    margin-bottom: 20px;
  }
  .chatbot-body {
    padding: 15px;
  }
  .sidebar-box {
    width: 100%;
  }
  .fillup-btn {
    padding: 5px 26px;
  }
  .notes-box > i {
    font-size: 16px;
    margin-right: 10px;
  }
  .notes-pane-content {
    padding-top: 25px;
  }
  .chat-area {
    padding: 25px 0 0;
  }
  .input-message {
    padding: 8px 12px;
    border-radius: 0;
    font-size: 12px;
  }
  .send {
    display: flex;
    padding: 20px 0 15px;
  }
  .chatbot-body .tab-content .card:not(:last-child),
  .tab-content-box .tab-content .card:not(:last-child) {
    margin-bottom: 12px;
  }
  .np-box {
    font-size: 14px;
    text-align: center;
  }
  .np-img-box {
    margin: 0 auto 10px;
  }
  .np-right {
    justify-content: center;
  }
  .np-text-box {
    margin-bottom: 5px;
  }
  .np-mid {
    margin-bottom: 10px;
  }
  .np-mid span + span {
    margin-left: 15px;
  }
  ul.new-profile-list li.np-item:not(:last-child) {
    margin-bottom: 15px;
  }
  .cb-outer {
    padding: 25px;
  }
  ul.stats-list li:not(:last-child) {
    margin-bottom: 30px;
  }
  .dc-profile-img {
    width: 65px;
    height: 65px;
  }
  .dc-check-icon > img {
    width: 20px;
  }
  .dc-check-icon {
    margin-left: 15px;
    font-size: 13px;
  }
  .dc-left .agree-box {
    margin-top: 15px;
  }
  .dc-left .agree-box {
    margin-top: 15px;
    padding: 0;
  }
  .dc-box {
    padding: 20px;
  }
  .my-profile-box .dc-right h3 {
    font-size: 16px;
  }
  .clinician-hero-img {
    max-width: 280px;
  }
  .accordion.pc-outer {
    padding: 0;
  }
  .pc-outer > .card > .card-header {
    font-size: 18px;
    line-height: 24px;
    padding: 18px 25px;
    padding-right: 60px;
  }
  .pc-outer > .card > .card-header:after {
    right: 25px;
  }
  .pc-outer .card-body {
    padding: 15px 25px 35px;
    font-size: 16px;
    line-height: 24px;
  }
  .accordion-link-box {
    padding: 0 25px 15px;
  }
  .cta-content h3 {
    font-size: 30px;
    line-height: 45px;
  }
  .caregivers-content {
    font-size: 16px;
    line-height: 26px;
  }
  .mh-sign-post-list li {
    width: 50%;
  }
  .community-hero-container {
    padding-top: 50px;
  }
  .cq-img-box,
  .cq-content {
    width: 100%;
  }
  .cq-content {
    padding: 20px 15px;
  }
  .cq-img-info {
    padding: 5px 20px;
  }
  .cq-content blockquote {
    font-size: 18px;
    line-height: 25px;
  }
  .cq-content .heading h5 {
    font-size: 23px;
    margin-bottom: 0;
  }
  .content-container.community-qoute-conainer {
    padding-bottom: 0;
  }
  .mh-sign-post-link {
    font-size: 13px;
    padding: 10px;
    line-height: 20px;
  }
  .mh-sign-post-list li:nth-child(3) .mh-sign-post-link {
    background-color: #917bc4;
  }
  .mh-sign-post-list li:nth-child(4) .mh-sign-post-link {
    background-color: #ff8f00;
  }
  .mh-sign-post-img,
  .mh-sign-post-content {
    width: 100%;
  }
  .mh-sign-post-content {
    padding: 20px 15px;
  }
  .mh-sign-post-content h6 {
    margin-bottom: 15px;
  }
  .join-community-content h2 {
    font-size: 26px;
    line-height: 30px;
  }
  .join-community-content {
    padding: 20px 15px;
  }
  .jc-form-content {
    flex-flow: column-reverse;
  }
  .jc-form-content .fc-right,
  .jc-form-img {
    width: 100%;
  }
  .jc-form-content .jc-form-img img {
    width: 100px !important;
  }
  .jc-form-content .jc-form-img {
    padding-left: 0;
    padding-bottom: 30px;
  }
  .jc-form-content .btn {
    width: 100%;
  }
  .social-media-conainer .heading h4 {
    font-size: 27px;
  }
  .community-kw-list {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .community-kw-list li {
    padding: 5px 5px;
  }
  .communinty-ckc-inner .form-control,
  .communinty-ckc-inner input[type='submit'] {
    height: 36px;
    font-size: 15px;
  }
  .community-kw-link {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 14px;
  }
  .community-tab-inner ul.home-services-list li.hsl-item {
    padding: 20px 15px;
  }
  .pkw-heading h3 {
    font-size: 24px;
  }
  .tpc-report .tpc-month-box h6 {
    font-size: 14px;
  }
  .tpc-report ul.documents-list li {
    width: 50%;
  }
  .md-body {
    padding: 15px;
  }
  .sidebar-box {
    width: 100%;
  }
  .border-box p {
    font-size: 14px;
  }
  .dairy-sidebar h5 {
    font-size: 18px;
  }
  .dairy-sidebar p {
    font-size: 14px;
  }
  .mdh-share,
  .mdh-delete {
    width: 40px;
    height: 40px;
    padding: 8px;
  }
  .mdr-head {
    margin-bottom: 20px;
  }
  .mdr-content h5 {
    line-height: 30px;
    margin-bottom: 15px;
  }
  .mdr-content p {
    margin-bottom: 12px;
  }
  .mcb-link .img + p {
    display: none;
  }
  .mcb-link .img {
    margin-right: 0;
  }
  .front-thumbnail-box {
    width: 70px;
    height: 50px;
    right: 15px;
    bottom: 15px;
  }
  .fullscreen-button {
    width: 40px;
    height: 40px;
    left: 15px;
    bottom: 15px;
  }
  .fullscreen-button > img {
    width: 19px;
  }
  .doc-outer {
    bottom: 15px;
    padding: 0 15px;
  }
  .video-box.fullscreen {
    height: auto;
  }
  .content-box-inner {
    padding: 15px;
  }
  .custom-accordion .ac-label {
    padding: 15px 60px 15px 15px;
  }
  .ac-label-text h3,
  .custom-accordion .article h6 {
    font-size: 15px;
  }
  .ac-label-text,
  .ac-label-right h4 {
    font-size: 13px;
  }
  .ac-label-img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
  .ac-label-img img {
    width: 18px;
  }
  .treatment,
  .treatment .chatbot-box,
  .treatment .chatbot-body {
    height: auto;
  }
  .custom-accordion .article {
    font-size: 16px;
  }
  .custom-accordion .article {
    padding: 15px 15px;
  }
  .doc-info-upper .mid .block {
    width: 100%;
  }
  .doc-info-upper .mid,
  .doc-info-lower .mid,
  .doc-info-upper .right {
    flex-flow: column;
  }
  .doc-info-upper .right .block {
    width: 100%;
  }
  .mid .block + .block,
  .doc-info-upper .right .block + .block {
    margin-left: 0;
  }
  .doc-info-upper .mid .block,
  .doc-info-upper .right .block {
    margin-bottom: 15px;
  }
  ul.filter-check-list {
    padding-left: 0;
  }
  .download-btn-group {
    justify-content: center;
    margin-bottom: 15px;
  }
  .stv-radio-button + label {
    padding: 4px 8px;
  }
  .dtb-mid {
    display: none;
  }
  .dashboard-main-heading {
    display: flex;
    color: #180058;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
  }
  .dashboard-main-heading h2 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .dw-main-head {
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 11px 15px 15px;
  }
  .dw-main-head .stv-radio-buttons-wrapper + p {
    margin: 8px 0;
  }
  .ccm-right,
  .dw-main-content {
    height: auto;
  }
  .dw-main-content {
    border-radius: 10px;
  }
  .dashboard-wrapper {
    height: auto;
  }
  .dm-footer {
    height: auto;
  }
  .calendar-cradits {
    padding-bottom: 8px !important;
  }
  .dw-form-box {
    padding: 30px;
    width: 100%;
  }
  .tab-content-box {
    padding: 15px;
    height: auto;
  }
  .tab-content-box .tab-content,
  .tab-content-box .tab-pane,
  .tab-content-box .chat-member-list {
    height: auto;
  }
  .tab-content-box .content-body {
    height: auto;
    padding: 15px;
  }
  .tab-content-box .tab-pane:not(:last-child) {
    margin-bottom: 15px !important;
  }
  .dsp-body {
    border-radius: 10px;
    height: auto;
  }
  .dsp-head {
    height: auto;
  }
  .dsp-head {
    flex-flow: column;
  }
  .new-search-box {
    height: 50px;
  }
  .my-diary-box .mdb-head h4,
  .my-profile-box h4,
  .dsp-head h4 {
    font-size: 20px;
  }
  .dsp-head h4 {
    margin-bottom: 15px;
  }
  .pd-right-box {
    justify-content: flex-end;
    margin-top: 30px;
  }
  .heading-with-line h3 {
    font-size: 27px;
  }
  ul.experts-list.new-experts-list li.expert-item {
    width: 50%;
  }
  .experts-container {
    padding: 30px 0;
  }
  ul.home-services-list.responsive-services-list li.hsl-item {
    width: 50%;
  }
  .blog-left-pannel {
    width: calc(100% - 180px);
  }
  .blog-right-pannel {
    width: 180px;
  }
  .blog-left-pannel h3 {
    font-size: 20px;
    line-height: 28px;
  }
  .blog-left-pannel p {
    font-size: 14px;
  }
  .blog-left-pannel .mh-read_more {
    font-size: 14px;
  }
  .blog-left .heading-with-line.grey h3 {
    font-size: 22px;
  }
  .desk-upper h3 {
    font-size: 20px;
  }
  .desk-lower h3 {
    font-size: 20px;
  }
  .desk-query-box {
    flex-wrap: wrap;
  }
  .desk-query-box .query-input {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }
  .desk-query-box .input-group {
    width: 100%;
  }
  .desk-query-box .submit-btn {
    margin-left: 0;
    width: 100%;
    padding-top: 10px;
  }
  .emergency-contact-modal .modal-content {
    padding: 30px;
  }
  .emergency-contact-modal .modal-dialog {
    min-width: 100%;
  }
  .modal-open .modal {
    padding: 17px !important;
  }
  .item .t-p.ch-main {
    padding: 18px;
  }
  ul.ch-list + ul.ch-list {
    margin-top: 20px;
  }
  ul.ch-list li.ch-item {
    width: 100%;
    margin-bottom: 20px;
  }
  ul.ch-list li.ch-item:last-child {
    margin-bottom: 0px;
  }
  .ch-content-box:first-child {
    margin-bottom: 15px;
  }
  .tab-content-box .content-body {
    padding: 20px 0;
  }
  .ch-content-box p {
    margin-bottom: 0;
  }
  .ch-main-box .block-seprator {
    margin: 15px 0;
  }
  .sidebar-right .close-img {
    margin-left: 15px;
  }
  .previous-arrows img,
  .next-arrows img {
    width: 18px;
    margin: 0;
  }
  .tp-block + .tp-block {
    margin-top: 15px;
  }
  .number-input-field {
    max-width: 100%;
  }
  .rg-bar-icon {
    margin-left: 0;
    margin-top: 15px;
  }
  .number-input-field {
    margin: 15px 0;
  }
  .crf-content-box ul.checkbox-group-list li.checkbox-group-item {
    width: 50%;
  }
  .input-group-row {
    max-width: 100%;
    flex-flow: column;
  }
  .ig-column {
    width: 100%;
  }
  .grid-row-right,
  .grid-row-left {
    width: 100%;
  }
  .crf-content-box {
    padding: 0;
  }
  .patients-details-footer {
    height: auto;
  }
  .pdf-btn {
    max-width: 100px;
    margin: 15px 0;
  }
  .item .t-p {
    padding: 15px;
  }
  .video-sidebar .active .card-header,
  .tab-content-box .active .card-header {
    background: #f68e3f;
    color: #fff;
  }
  .mcbp-left {
    width: 100%;
  }
  .mcbp-right {
    width: 100%;
    padding-left: 0;
    padding-top: 10px;
  }
  .mcbp-right:before {
    content: '';
    width: 100%;
    height: 1px;
    top: 0;
    transform: none;
    left: 0;
  }
  .mcbp-left + .mcbp-right {
    margin-top: 16px;
  }
  .icon-box {
    width: 42px;
    height: 38px;
  }
  .icon-box > img {
    width: 18px;
  }
  .video-box2 .call-end h4 {
    font-size: 20px;
  }
  .mcb-left {
    font-size: 16px;
  }
  .card-header img {
    width: 20px;
  }
  .video-box .mcb-footer {
    flex-flow: column;
  }
  .mcb-right {
    font-size: 16px;
  }
  .video-box2 .mcb-link {
    margin: 10px 0;
  }
  .video-box2 .btn {
    padding: 10px 15px;
  }
  .fcb-img-box {
    justify-content: center;
    margin-top: 8px;
  }
  .videochat-tabs .nav-link b {
    margin-left: 12px;
  }
  .card-header b {
    margin-left: 15px;
    font-size: 14px;
  }
  .video-sidebar2 .card-header > img {
    display: none;
  }
  .person-details:hover .pd-right strong {
    opacity: 1;
    visibility: visible;
  }
  .pd-phone-icon {
    margin-right: 10px;
    font-size: 14px;
    display: inline-block;
  }
  .pannel-right .content-body {
    padding: 10px 15px;
  }
  .chatbot-body .card-header,
  .tab-content-box .card-header {
    font-size: 14px;
  }
  .nvb-text-box {
    flex-flow: column;
  }
  .nvb-text-box p {
    margin-bottom: 10px;
  }
  .nvb-box {
    padding: 15px;
  }
  .nvb-tab-box h3 {
    font-size: 14px;
  }
  .nvb-box p {
    font-size: 14px;
  }
  .mcbp-left {
    width: 100%;
  }
  .mcbp-right {
    width: 100%;
    padding-left: 0;
    padding-top: 10px;
  }
  .mcbp-right:before {
    content: '';
    width: 100%;
    height: 1px;
    top: 0;
    transform: none;
    left: 0;
  }
  .mcbp-left + .mcbp-right {
    margin-top: 16px;
  }
  .icon-box {
    width: 42px;
    height: 38px;
  }
  .icon-box > img {
    width: 18px;
  }
  .video-box2 .call-end h4 {
    font-size: 20px;
  }
  .mcb-left {
    font-size: 16px;
  }
  .card-header img {
    width: 20px;
  }
  .video-box .mcb-footer {
    flex-flow: column;
  }
  .mcb-right {
    font-size: 16px;
  }
  .video-box2 .mcb-link {
    margin: 10px 0;
  }
  .video-box2 .btn {
    padding: 10px 15px;
  }
  .fcb-img-box {
    justify-content: center;
    margin-top: 8px;
  }
  .videochat-tabs .nav-link b {
    margin-left: 12px;
  }
  .card-header b {
    margin-left: 15px;
    font-size: 14px;
  }
  /*.np-left .np-img-box{margin:0 0 15px;}*/
  .np-left {
    flex-flow: column;
  }
  .sic-body {
    padding: 0 15px;
  }
  .Prescriptions-box .doc-text-box h4 {
    font-size: 14px;
  }
  .sidebar-info-box h5 {
    font-size: 14px;
  }
  .form-check {
    padding-left: 35px;
    font-size: 14px;
    line-height: 18px;
  }
  .check-box-text {
    font-size: 14px;
  }
  .form-group {
    margin-bottom: 16px;
  }
  .Prescriptions-box .ig-column h5 {
    font-size: 14px;
  }
  .ig-multiple-column .ig-column {
    padding: 10px 0;
  }
  .prescription-btn {
    flex-flow: column;
  }
  .prescription-btn .btn {
    min-width: 100%;
    font-size: 14px;
  }
  .prescription-btn .btn + .btn {
    margin-top: 10px;
    margin-left: 0;
  }
  .plan-group-column .btn-add {
    font-size: 12px;
  }
  .Prescriptions-box .ig-column-outer .ig-column {
    padding: 0;
  }
  .Prescriptions-box .radio-group-box-outer {
    padding: 0;
  }
  .ig-column-outer.input-group-row {
    width: 100%;
    margin-left: 0;
  }
  .Prescriptions-box .sidebar-bg-box {
    padding: 10px;
  }
  .sic-head-text h4 {
    font-size: 16px;
  }
  .sic-head-left {
    margin-bottom: 15px;
  }
  .sic-head-right {
    width: 100%;
    justify-content: flex-end;
  }
  .scb-left,
  .scb-right,
  .sib-left,
  .sib-right {
    padding: 10px 0;
  }
  .sic-head {
    padding: 15px;
  }
  .fun-box {
    padding: 15px;
  }
  .files-img {
    width: 40px;
    height: 40px;
  }
  .files-img > img {
    width: 15px;
  }
  .files-text-box h3 {
    font-size: 16px;
  }
  .files-text-box {
    font-size: 13px;
  }
  ul.fun-list li.fun-item {
    width: 100%;
  }
  .block-seprator {
    margin: 25px 0;
  }
  ul.fun-list li.fun-item + li.fun-item {
    margin-top: 15px;
  }
  .hwi-heading h3 {
    font-size: 16px;
  }
  .hwi-icon {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  .head h4 {
    font-size: 16px;
    margin-right: 15px;
  }
  .tab-pane .head {
    padding: 10px 0 0 0;
    justify-content: space-between;
  }
  .head .btn {
    padding: 8px 15px;
    min-width: 130px;
    font-size: 12px;
  }
  .appointment-modal .modal-dialog {
    max-width: 100%;
    margin: 10px;
  }
  .modal-open .modal.appointment-modal {
    padding: 0 !important;
  }
  .appointment-modal .form-control {
    height: 45px;
    font-size: 14px;
  }
  .appointment-modal textarea.form-control {
    height: 100px;
  }
  .custom-modal-btn {
    flex-flow: column;
  }
  .appointment-modal .btn {
    min-width: 100%;
    padding: 10px 0;
    font-size: 14px;
  }
  .appointment-modal .custom-modal-btn .btn + .btn {
    margin-top: 15px;
  }
  .new-chatbot-outer {
    top: 70px;
  }
  .personal-details-modal .modal-content {
    border-radius: 10px;
    padding: 30px;
  }
  .personal-details-modal .pdm-icon {
    width: 60px;
    margin: 0 auto 25px;
  }
  .personal-details-modal h5 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .personal-details-modal {
    font-size: 16px;
  }
  .personal-details-modal .btn-default {
    margin-top: 25px;
  }
  .open-chatbot #chats .content-body {
    display: none !important;
  }
  .new-chatbot-body.chat {
    height: 300px;
    background: #fff;
    padding-right: 15px;
  }
  .new-chatbot-head {
    padding: 15px 30px 15px 30px;
  }
  .dashboard-main-container {
    /* min-height: 100%; */
  }
  .front-thumbnail-box {
    border-radius: 8px;
  }
  .mcb-mid {
    margin: 10px 0;
  }
  .chatbot-pannel,
  .chat {
    height: auto;
  }
  #documents .tab-pane-content-box {
    height: auto;
    overflow-y: auto;
    overflow-x: visible;
  }
  .doc-fee-desc .appointment-btn {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 0 5px 8px rgb(0 0 0 / 30%);
  }
  .doc-fee-desc .appointment-btn .btn {
    min-width: 100%;
    border-radius: 0;
  }
  .mob-pd-50 {
    padding-bottom: 50px !important;
  }
  ul.fl-nav li:not(:last-child):before {
    content: none;
  }
  ul.fl-nav {
    text-align: center;
  }
  ul.fl-nav li {
    padding: 0;
  }
  ul.fl-nav li a {
    line-height: 30px;
  }

  .du-header ul.query-list {
    justify-content: center;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .nvbr-body .card .collapse.show,
  .member-detail-container.card .collapse {
    display: block;
  }
  .nvbr-body .card .collapse {
    display: none;
  }
}
@media (min-width: 768px) {
  .mb-md-0, .my-md-0 {
      margin-bottom: 3rem !important;
  }
}

/* ======================================================
! Extra small devices (portrait phones, less than 576px)
====================================================== */
@media (max-width: 575.98px) {
  .otp-box input:not(:last-child) {
    margin-right: 5px;
  }
  .main-container {
    padding: 70px 15px 30px;
  }
  .main-head {
    top: 15px;
    left: 15px;
  }
  .main-top {
    position: relative;
    top: 0;
    right: auto;
    margin: 0 0 10px 15px;
  }
  .photo-upload-modal .modal-content {
    padding: 30px 15px;
  }
  .photo-upload-modal .modal-body {
    padding-bottom: 0;
  }
  .photo-upload-left {
    margin-bottom: 30px;
  }
  .photo-upload-right {
    padding: 0;
    line-height: 24px;
  }
  .photo-upload-right ol {
    margin-bottom: 0;
  }
  .photo-upload-right ol li {
    margin-bottom: 10px;
  }
  .badge-group .add-more-field {
    margin-top: 5px;
  }
  .custom-input label:not(.error) {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
  }
  .add-more-field {
    font-size: 14px;
  }
  .form-inline .form-group {
    margin-bottom: 0;
  }
  .member-content {
    padding: 25px 15px;
  }
  .member-content h2 {
    font-size: 20px;
    letter-spacing: 0;
  }
  .at-img {
    width: 100px;
    margin: 0 auto 25px;
  }
  .at-box h4 {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .at-box .btn {
    font-size: 14px;
    padding: 10px 15px;
  }
  .at-box h4 > span {
    font-size: 10px;
    line-height: 19px;
  }
  ul.city-list li {
    flex: 100% 0 0;
  }
  .city-list li:not(:last-child):before {
    width: 100%;
    height: 1px;
    top: 100%;
    transform: none;
  }
  ul.tile-slot-list .ts-item {
    width: 50%;
  }
  .filter-tabs {
    width: 130px;
  }
  .fd-body .fd-content-box {
    flex-grow: 1;
    padding: 25px 15px;
  }
  .side-pannel-box {
    padding: 15px;
  }
  .pannel-tab-box .nav-tabs {
    max-width: 245px;
  }
  .form-check-group .form-check {
    width: 100%;
  }
  .copyright {
    font-size: 14px;
  }
  .ic-left h4 {
    font-size: 30px;
  }
  .header-upper-right {
    margin-left: auto;
  }
  .intro-text-box h3 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  .intro-btn-group {
    padding-right: 0;
    flex-flow: column;
    align-items: center !important;
  }
  .intro-img {
    margin-bottom: 25px;
  }
  .intro-btn-group h6 {
    font-size: 16px;
    line-height: 22px;
    margin-left: 0;
    padding-bottom: 0;
    margin-top: 15px;
  }
  ul.experts-list li.expert-item {
    width: 100%;
  }
  ul.grid-list {
    width: 100%;
    margin-left: 0;
  }
  ul.grid-list li.grid-item {
    width: 100%;
    padding: 0;
  }
  ul.grid-list li.grid-item:not(:last-child) {
    margin-bottom: 15px;
  }
  .new-btn-group {
    flex-flow: column;
  }
  .new-btn-group .btn {
    min-width: 100%;
    padding: 11px 15px;
  }
  .new-btn-group .btn + .btn {
    margin-left: 0;
    margin-top: 15px;
  }
  .heading h4 {
    font-size: 30px;
  }
  .heading h5 {
    font-size: 25px;
  }
  .heading {
    margin-bottom: 25px;
  }
  .media-box .play-btn {
    width: 35px;
  }
  .intro-btn-group .btn {
    min-width: 185px;
    max-width: 185px;
  }
  a.header-right-box img {
    margin-right: 0;
  }

  .reverse ul.bl-list .bl-item {
    width: 100%;
    margin-bottom: 10px;
  }
  .bl-box h3 {
    font-size: 14px;
  }
  ul.bl-list.check-icon-list {
    max-width: 100%;
  }
  .block-content-box .bl-box {
    padding: 20px 10px;
    width: 100%;
  }
  ul.bl-list.check-icon-list .bl-item {
    width: 100%;
    margin-bottom: 10px;
  }
  .h-line {
    width: 50%;
  }
  .mob-full-width {
    width: calc(100% + 30px) !important;
    margin-left: -15px !important;
  }
  .intro-content h4 {
    font-size: 30px;
    line-height: 40px;
  }
  .intro-content h4 span {
    font-size: 25px;
    line-height: 30px;
  }
  .card-box .cb-text-box {
    padding: 0;
    width: 100%;
    padding-top: 15px;
  }
  .dc-head-left {
    margin-bottom: 15px;
  }
  .dashboard-main-container,
  .dc-body {
    padding: 25px 15px;
  }
  .dc-img-box {
    max-width: 60px;
  }
  .dc-left h4 {
    font-size: 25px;
    text-align: center;
  }
  .dc-profile-img {
    width: 70px;
    height: 70px;
  }
  .dc-profile-intro {
    display: flex;
    width: calc(100% - 70px);
    padding-left: 1px;
    align-items: flex-start;
  }
  .dc-profile-intro .btn.btn-xs {
    min-width: 70px;
  }
  .dc-profile-intro > span {
    margin: 0 10px;
  }
  .dc-right,
  .dc-profile-box {
    margin-bottom: 25px;
  }
  .dc-form-box .form-group {
    margin-bottom: 15px;
  }
  .dc-right-content p {
    padding: 0;
  }
  .dc-footer {
    margin-top: 25px;
  }
  .dc-footer .btn {
    min-width: 100%;
  }
  .dc-footer {
    flex-flow: column;
  }
  .dc-footer .btn + .btn {
    margin-left: 0;
    margin-top: 15px;
  }
  .add-file-upload.file-upload {
    width: 100%;
  }
  .drag-text h3 {
    padding: 50px 0;
    font-size: 15px;
  }
  .wb-content-box {
    padding: 35px;
    font-size: 15px;
  }
  .wb-icon {
    width: 50px;
  }
  .wb-content-box h3 {
    font-size: 16px;
  }
  .welcome-box {
    width: auto;
    margin: 15px;
  }
  .dc-block-body .badge-group .tag + .tag {
    margin-left: 0;
    margin-top: 12px;
  }
  .dc-block-body .form-control.badge-group {
    display: flex;
    flex-flow: column;
    align-items: start;
  }
  ul.specialist-list {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  ul.specialist-list li.specialist-item {
    width: 100%;
    padding: 15px;
  }
  .ftu-heading h6 {
    font-size: 22px;
    margin-bottom: 5px;
  }
  .ftu-heading {
    font-size: 16px;
    margin-bottom: 15px;
  }
  ul.documents-list li {
    width: 100%;
  }
  .sidebar-pannel {
    width: 100%;
  }
  ul.tags li {
    width: 50%;
  }
  .sp-doc-box {
    padding: 15px 15px;
  }
  .sp-doc-box {
    flex-flow: column;
    font-size: 16px;
  }
  .sp-doc-box > img {
    margin-right: 0;
    margin-bottom: 15px;
    width: 35px;
  }
  .sp-doc-box:before {
    width: calc(100% - 80px);
  }
  .sp-body {
    padding: 15px;
  }
  .sp-head h4 {
    font-size: 16px;
  }
  .sp-head {
    padding: 15px;
  }
  .close-icon {
    font-size: 15px;
  }
  .dc-profile-box {
    flex-flow: column;
    align-items: flex-start !important;
  }
  .dc-check-icon {
    margin-left: 0;
    margin-top: 10px;
  }
  .dc-box {
    padding: 25px;
  }
  .custom-modal .modal-body {
    padding: 25px 20px;
  }
  .custom-modal h6 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .custom-modal p,
  .custom-modal-btn .btn,
  .custom-modal-btn .btn-text {
    font-size: 16px;
  }
  .custom-modal p {
    margin-bottom: 25px;
  }
  .custom-modal-btn .btn {
    margin-bottom: 12px;
  }
  .custom-modal h5,
  .custom-modal h4 {
    font-size: 16px;
  }
  .modal-coustom-btn .btn:not(:last-child) {
    margin-bottom: 10px;
  }
  .modal-coustom-btn .btn + .btn {
    margin-left: 0;
  }
  .modal-coustom-btn {
    flex-flow: column;
  }
  ul.rating-list {
    margin: 20px auto;
  }
  .rating-box {
    flex-flow: column;
  }
  .rating-box .rating-text-box {
    margin-right: 0;
  }
  .rating-icon-box {
    margin-left: 0;
  }
  .rating-icon-box i + i {
    margin-left: 3px;
    font-size: 14px;
  }
  ul.rating-list li.rating-item:not(:last-child) {
    margin-bottom: 8px;
  }
  .custom-modal {
    padding-left: 0;
  }
  .rating-box .rating-text-box {
    font-size: 14px;
  }
  .btn.btn-xl {
    min-width: 280px;
    font-size: 16px;
  }
  .clinician-dot > span {
    display: none;
  }
  .clinician-hero-content > h3 {
    font-weight: 700;
    margin-bottom: 15px;
  }
  .clinician-hero-content h4 {
    font-size: 16px;
    line-height: 26px;
  }
  .clinician-hero-content p,
  .blog-hero .clinician-hero-content p {
    font-size: 16px;
    line-height: 26px;
  }
  .prowess-list {
    padding-top: 30px;
  }
  .prowess-item {
    flex-basis: 100%;
    max-width: 100%;
    padding: 10px;
  }
  .prowess-item h6 {
    padding-left: 15px;
  }
  .cta-container {
    padding: 30px 0;
  }
  .cta-content h3 {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 25px;
  }
  .cta-btn {
    padding: 0;
  }
  .cta-btn + .cta-btn {
    margin-top: 18px;
  }
  .cta-btn > span {
    margin-top: 5px;
    font-size: 15px;
    line-height: 22px;
  }
  .pc-outer > .card.card-true-false > .card-header {
    font-size: 16px;
    line-height: 22px;
    padding-right: 25px;
  }
  .pc-outer > .card.card-true-false > .card-header > span {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    display: block;
    color: #432a7f;
    margin-top: 20px;
  }
  .tpc-report ul.documents-list li {
    width: 100%;
  }
  .md-head h4 {
    font-size: 22px;
    margin-bottom: 10px;
    text-align: center;
  }
  .mdh-icon {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .mdh-right {
    flex-flow: column;
  }
  .mdr-date {
    align-items: center;
    justify-content: space-between;
  }
  .mdr-date p {
    margin-bottom: 0;
  }
  .mdr-heading {
    margin-bottom: 15px;
  }
  .video-box .mcb-footer {
    padding: 15px;
    font-size: 12px;
  }
  .mcb-link .img {
    margin-right: 0;
  }
  .mcb-link + .mcb-link {
    margin-left: 8px;
  }
  .mcb-link .img {
    width: 26px;
    height: 26px;
  }
  .mcb-link .img > img {
    width: 12px;
  }
  .ac-lable-content,
  .ac-label-left {
    flex-flow: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  .custom-accordion i {
    transform: translate(-50%, 0);
    right: auto;
    top: auto;
    left: 50%;
  }
  .ac-label-left {
    margin-bottom: 12px;
  }
  .ac-label-img {
    margin-right: 0;
    margin-bottom: 10px;
  }
  ul.number-list li:not(:last-child),
  ul.number-list {
    margin-bottom: 10px;
  }
  .doc-info-upper .mid .block {
    flex-flow: column;
  }
  .block .text-block {
    width: 100%;
  }
  .block .img-block {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .dashboard-main-heading {
    align-items: flex-start;
    flex-flow: column;
  }
  .dashboard-main-heading h2 {
    margin-bottom: 5px;
  }
  .dw-header {
    height: auto;
    flex-flow: column;
    align-items: flex-start !important;
  }
  .dw-right {
    margin-left: 0 !important;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 25px;
  }
  .dw-right .btn + .btn {
    margin-left: 0;
    margin-top: 10px;
  }
  .dw-header h3 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .dw-footer {
    flex-flow: column;
  }
  .dw-footer .btn {
    min-width: 100%;
  }
  .dw-footer .btn + .btn {
    margin-left: 0;
    margin-top: 15px;
  }
  .dsp-right-content {
    flex-flow: column;
  }
  .dsp-head .new-search-box {
    margin-bottom: 15px;
  }
  .new-search-box {
    width: 100%;
  }
  .pd-right-content {
    margin-left: auto;
  }
  .toggle-content-box {
    min-width: 260px;
  }
  .ddm-inner-box span + div {
    flex-flow: column;
    align-items: flex-start !important;
  }
  .ddm-inner-box {
    flex-flow: column;
    align-items: flex-start !important;
  }
  .patients-details-head {
    padding: 15px 15px 15px 30px;
  }
  .expert-container .heading {
    margin-bottom: 10px;
  }
  .heading-with-line {
    margin-bottom: 10px;
  }
  .heading-with-line h3 {
    font-size: 22px;
  }
  ul.experts-list.new-experts-list li.expert-item {
    width: 100%;
  }
  .experts-container:last-child {
    padding-bottom: 35px;
  }
  ul.home-services-list.responsive-services-list li.hsl-item {
    width: 100%;
  }
  .keywords-box .community-kw-list li,
  .keywords-box .community-kw-list li > a {
    width: 100%;
  }
  .keywords-container {
    padding: 70px 0 30px;
    margin-top: -55px;
  }
  .blog-left-upper {
    flex-flow: column-reverse;
  }
  .blog-right-pannel {
    width: 100%;
    margin-bottom: 15px;
  }
  .blog-left-pannel {
    width: 100%;
    padding: 0;
  }
  .pagination-container {
    padding-top: 25px;
  }
  ul.pagination {
    justify-content: center;
  }
  ul.pagination li.page-item .page-link {
    font-size: 15px;
  }
  .blog-left {
    margin-bottom: 35px;
  }
  .br-content-box {
    padding: 18px 15px;
  }
  .br-content-box .br-heading h3 {
    font-size: 24px;
  }
  .br-content-box .br-heading {
    margin-bottom: 15px;
  }
  .latest-article-text h3,
  .latest-article-text {
    font-size: 14px;
  }
  .latest-article-box {
    padding: 10px 0px 12px 25px;
  }
  .latest-article-sequence {
    font-size: 24px;
    top: 8px;
  }
  ul.topic-list li .tl-link {
    padding: 12px 15px;
    font-size: 14px;
    min-width: 100%;
  }
  .mob-pt-0 {
    padding-top: 0 !important;
  }
  .desk-upper h3 {
    font-size: 20px;
  }
  .desk-lower h3 {
    font-size: 20px;
  }
  .faq-box {
    padding: 15px;
    border-radius: 10px;
  }
  .faq-box .ih-box h3 {
    font-size: 14px;
  }
  .emergency-contact-modal .modal-content {
    padding: 15px;
  }
  .ecm-box h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .ecm-box h4 + p {
    font-size: 16px;
  }
  .emc-column {
    width: 100%;
    margin-bottom: 15px;
  }
  .ecm-btn {
    margin-top: 20px;
  }
  .msb-head {
    margin-bottom: 15px;
    text-align: center;
  }
  .msb-head-left h4 {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 12px;
  }
  ul.earning-list {
    width: 100%;
    margin-left: 0px;
  }
  li.earning-item {
    width: 100%;
    margin-bottom: 8px;
    padding: 0px;
  }
  li.earning-item:last-child {
    margin-bottom: 0px;
  }
  .msb-body {
    padding: 15px;
  }
  .msb-patients-box {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  .msb-middle {
    margin-bottom: 12px;
  }
  .new-filter-box-outer {
    margin-bottom: 15px;
  }
  .new-checkbox-content .form-check input[type='checkbox'] + label {
    font-size: 14px;
  }
  .new-checkbox-content
    .form-check
    input[type='checkbox']
    + .form-check-label::before {
    width: 15px;
    height: 15px;
  }
  .new-checkbox-content
    .form-check
    input[type='checkbox']
    + .form-check-label::after {
    width: 15px;
    height: 15px;
  }
  .new-checkbox-content .signup-agree-box + .signup-agree-box {
    margin-top: 15px;
  }
  .new-checkbox-content p {
    font-size: 14px;
  }
  .date-filter-box .dfb-left,
  .date-filter-box .dfb-right {
    width: 100%;
    padding: 0;
  }
  .bottom-align.filter-dropdown .dropdown-menu {
    min-width: 300px;
  }
  .new-filter-heading a {
    font-size: 14px;
  }
  .new-filter-box {
    margin-right: 0;
  }
  .date-filter-box .form-group {
    margin-bottom: 20px;
  }
  .item .t-p.ch-main {
    padding: 15px;
  }
  li.sidebar-content-item {
    width: 100%;
    padding: 0;
  }
  a.action-img > img {
    width: 15px;
  }
  .crf-content-box ul.checkbox-group-list li.checkbox-group-item {
    width: 100%;
  }
  .radio-group-row {
    flex-flow: column;
    align-items: flex-start !important;
  }
  .ig-multiple-column .ig-column {
    width: 100%;
  }
  .tc-btn {
    width: 100%;
  }
  .tc-btn + .tc-btn {
    margin-left: 0 !important;
    margin-top: 10px;
  }
  .tc-form.d-flex.flex-wrap {
    flex-flow: column;
  }
  .fm-form-item {
    width: 100%;
  }
  .fm-box {
    padding: 0;
  }
  .tc-column + .tc-column {
    margin-left: 0;
  }
  .crf-content-box
    ul.checkbox-group-list.new-checkbox-group-list
    li.checkbox-group-item {
    width: 100%;
  }
  .custom-upload .btn.btn-upload {
    min-width: 80px;
  }
  .custom-toggle-button > p {
    font-size: 14px;
  }
  .custom-switch-heading h3 {
    font-size: 16px;
  }
  .plan-group-column h2,
  .crf-content-box h4 {
    font-size: 16px;
  }
  .patients-details-footer a.btn {
    min-width: 100%;
  }
  .pdf-btn {
    max-width: 100%;
    margin: 15px 0;
    justify-content: center;
  }
  .scb-left,
  .scb-right,
  .sib-left,
  .sib-right {
    width: 100%;
  }
  .scb-left {
    padding-right: 0;
  }
  .t-p .slb-icons-group {
    opacity: 1;
    visibility: visible;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .tp-button-group {
    flex-flow: column;
  }
  .tp-button-group .btn2 {
    min-width: 100%;
  }
  .tp-button-group .btn2 + .btn2 {
    margin-top: 15px;
    margin-left: 0;
  }
  .mcbp-right .pdm-inner-box span {
    font-size: 11px;
    line-height: 20px;
  }
  .mcbp-img {
    width: 55px;
  }
  .mcbp-desc h4 {
    font-size: 16px;
  }
  .mcbp-desc .pd-right-content strong {
    font-size: 14px;
    line-height: 26px;
  }
  .mcbp-desc .custom-dropright .custom-dropdown-toggle > i {
    font-size: 13px;
  }
  .mcb-footer-bottom {
    font-size: 16px;
  }
  .fcb-img {
    width: 45px;
    height: 40px;
  }
  .tab-summary-content-box {
    padding: 25px 15px;
  }
  .sdb-left {
    width: 100%;
  }
  .sdb-right {
    width: 100%;
  }
  .sdb-left + .sdb-right {
    margin-top: 10px;
  }
  .sdb-right .sdb-desc h4 {
    font-size: 16px;
  }
  .sbd-text-box.advise-left {
    width: 100%;
  }
  .sbd-text-box.advise-right {
    width: 100%;
  }
  .sbd-text-box.advise-left + .sbd-text-box.advise-right {
    margin-top: 10px;
  }
  .pd-text .btn,
  .pd-right .btn {
    padding: 5px 15px;
    min-width: 70px;
    font-size: 10px;
  }
  .pannel-left .dropdown-toggle img {
    height: 17px;
  }
  .mcbp-img {
    width: 55px;
  }
  .mcbp-desc h4 {
    font-size: 16px;
  }
  .mcbp-desc .pd-right-content strong {
    font-size: 14px;
    line-height: 26px;
  }
  .mcbp-desc .custom-dropright .custom-dropdown-toggle > i {
    font-size: 13px;
  }
  .mcb-footer-bottom {
    font-size: 16px;
  }
  .costom-check-box h3 {
    font-size: 15px;
  }
  .ccb-box-row .ccb-box,
  .imc-row {
    flex-flow: column;
    align-items: flex-start !important;
  }
  .ccb-left {
    width: 100%;
    margin-bottom: 10px;
  }
  .ccb-right {
    width: 100%;
  }
  .ccb-box + .ccb-box {
    margin-top: 15px;
  }
  .imc-right ul.checkbox-group-list li.checkbox-group-item {
    width: 100%;
  }
  .imc-lower .form-group.custom-input {
    margin-top: 15px;
  }
  .fun-box .radio-group-box + .radio-group-box {
    margin-top: 15px;
  }
  .fun-box .input-group-row {
    width: 100%;
    margin-left: 0;
  }
  .fun-box .tp-block {
    flex-flow: column;
  }
  .tp-block .btn {
    min-width: 100%;
  }
  .tp-block .btn + .btn {
    margin-left: 0;
    margin-top: 15px;
  }
  .fun-box .ig-column {
    padding: 0;
  }
  .fun-box .ig-column + .ig-column {
    margin-top: 15px;
  }
  .fun-box .radio-group-box,
  .dc-block-body + .dc-block-body {
    margin-top: 15px;
  }
  .fb-form .custom-radio .form-check-label {
    padding: 9px 8px 9px 27px;
    font-size: 12px;
  }
  .fb-content {
    font-size: 13px;
  }
  .fb-form .form-check + .form-check {
    margin-left: 5px;
  }
  .feedback-box:not(:last-child) {
    margin-bottom: 15px;
  }
  .fb-form .form-check input[type='radio'] + .form-check-label::before {
    width: 15px;
    height: 15px;
  }
  .fb-form
    .custom-radio
    .form-check
    input[type='radio']
    + .form-check-label::after {
    top: 16px;
    left: 13px;
  }
  .new-chatbot-head {
    height: auto;
  }
  .new-chatbot-left {
    flex-flow: column;
    align-items: flex-start !important;
  }
  .new-chatbot-img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    margin-right: 0;
  }
  .new-chatbot-text h6 {
    font-size: 16px;
  }
  .new-chatbot-text {
    font-size: 12px;
    line-height: 1.2;
  }

  .doc-intro-box {
    flex-flow: column;
    padding-bottom: 0;
  }
  ul.stats-list li {
    width: 50%;
    margin-bottom: 0 !important;
    padding: 7.5px 15px;
  }
  ul.stats-list {
    width: calc(100% + 30px);
    margin-left: -15px;
    display: flex;
    flex-wrap: wrap;
  }
  ul.stats-list li h6 {
    font-size: 14px;
  }
  ul.stats-list li p {
    font-size: 32px;
    line-height: 1.3;
  }

  .du-header ul.query-list {
    padding: 7px 7px;
  }
  .du-header ul.query-list li {
    font-size: 13px;
  }

  .schedule-time.form-inline .form-check {
    width: auto;
    margin-left: 0;
  }
  .schedule-time .form-check label {
    padding-left: 0;
  }
  .schedule-time .form-control {
    height: 55px;
    font-size: 14px;
    min-width: 100%;
    width: 100%;
  }
  .schedule-box {
    min-height: auto;
    padding: 15px;
  }
  .schedule-time .form-check {
    padding-left: 28px;
  }
  .schedule-time.form-inline .form-group {
    margin-bottom: 15px;
    width: 100%;
  }
  .schedule-box .add-more-field {
    margin-top: 10px;
  }
}
@media (max-width: 420.98px) {
  .mobile-fullscreen-call {
    height: 90vh !important;
  }
  .custom-toggle-button .custom-switch-outer {
    margin-left: 0px;
    margin-top: 15px;
  }
  .person-details-box {
    flex-flow: column;
    align-items: flex-start !important;
  }
  .person-text-box {
    padding-left: 0;
    margin-top: 10px;
  }
  .person-text-box h3,
  .pd-text,
  .pd-right strong {
    font-size: 15px;
  }
  .pd-right .dropright {
    margin-left: 9px;
  }
  .tp-text-box {
    flex-flow: column;
    align-items: flex-start !important;
  }
  .tp-date-desc {
    margin: 10px 0;
  }
  .du-header ul.query-list li img + span {
    display: none;
  }
  .pdf-model .modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
    height: 100vh !important;
    margin-top: 0;
  }
  .pdf-model .close {
    font-size: 2.5rem !important;
  }
  .modal-open .modal {
    padding: 0 !important;
  }
}

.css-107lb6w-singleValue {
  color: #333 !important;
}

/* doctor profile video style */

.doc-img {
  position: relative;
  display: inline-block;
  width: 100%
}

.play-icon {
  position: absolute;
  bottom: 30px; 
  right: 30px; 
  width: 55px !important;  
  height: 55px !important;
  cursor: pointer;
  border-radius: 50%;
  padding: 5px; 
}

.video-container {
  position: relative;
  display: inline-block;
  width: 100%
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.video-content {
  position: relative;
  width: 80%;
  max-width: 800px;
}

.video-content video {
  width: 100%;
  border-radius: 10px;
}

.close-icon {
  position: absolute;
  top: -25px;  
  right: -25px; 
  width: 25px;  
  height: 25px; 
  cursor: pointer;
  z-index: 1001; 
}

.dr_article h3 {
  color: #432a7f;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1.5rem;
}

.circle-info{
  margin-left: 10px;
}